import React from 'react';
import Button from 'ui/elements/buttons/Button';
import Box from '@mui/material/Box';
import LockIcon from 'ui/elements/icons/LockIcon';

interface Props {
  type: 'regular' | 'embed';
}

export function EmptyCompanyCard({ type: version }: Props) {
  return (
    <Box
      sx={{ boxShadow: 1, backgroundColor: 'white', borderRadius: 1 }}
      style={{ overflow: 'hidden', maxWidth: version === 'embed' ? 504 : 'unset' }}
      className="u-section-padding u-flex-column u-flex-align-center u-flex-center"
    >
      <div className={`u-flex-align-center u-flex--column ${version === 'embed' ? 'u-section-spacing-bottom' : ''}`}>
        <LockIcon color="blue" fontSize="large" className="u-content-spacing-bottom" />
        <p className="text-font-text text-weight-medium text-align-center">Private profile</p>
      </div>
      {version === 'embed' && (
        <div className="u-flex-center">
          <Button kind="primary" onClick={() => {}} className="u-ellipsis u-content-spacing-right">
            Sign up
          </Button>
          <Button kind="tertiary" color="grey" onClick={() => {}} className="u-ellipsis">
            Log in
          </Button>
        </div>
      )}
    </Box>
  );
}
