import useNotify from 'hooks/useNotify';
import { invalidate } from 'hooks/useSWR';
import { FundingStage, Industry, Stage } from 'types';
import { CompanyProfile, UpdateCompanyDTO } from 'types/company';
import useLazyResource from 'util/resource/useLazyResource';
import CompanyAPI from '..';
import { companiesApi } from './companiesApi';
import { companyProfileByIdKey, companyProfileKey } from './useCompanyProfile';
import { pitchKey } from './usePitch';
import { useCompanyLastUpdated } from 'domain/companies/profile/LastUpdated';
import { userDashboardKey } from 'pages/Dashboard/useUserDashboardData';
import { companyDashboardKey } from 'pages/Dashboard/useDashboardData';
import { companyOnboardingKey } from 'apis/OnboardingAPI';

interface LabelsPayload {
  industries?: Industry[];
  productStage?: Stage;
  fundingStage?: FundingStage;
}

// NB! If you use the labels payload, you must also include the existing labels, otherwise they will be removed
export const useUpdateCompany = (
  companyId: number,
  companySlug: string,
  options?: { onSuccess?: () => void; onFailure?: () => void },
) => {
  const notify = useNotify();

  const { setLastUpdated } = useCompanyLastUpdated(companyId);
  return useLazyResource(
    (payload: { company: UpdateCompanyDTO; labels?: LabelsPayload }) =>
      Promise.all([
        companiesApi.update(companyId, payload.company),
        payload.labels?.fundingStage && companiesApi.fundingStage.update(companyId, payload.labels.fundingStage.id),
        payload.labels?.industries && CompanyAPI.industries.update(companyId, payload.labels.industries),
        payload.labels?.productStage &&
          CompanyAPI.stages.update(companyId, payload.labels.productStage ? [payload.labels.productStage] : []),
      ]),
    {
      onSuccess: ([companyProfile], payload) => {
        setLastUpdated(new Date());
        // Since we load industries and stages from the company profile endpoint,
        // but update them using separate endpoints, we have to manually merge the values
        // here to account for the race condition
        const updatedCompanyProfile: CompanyProfile = {
          ...companyProfile,
          industries: payload.labels?.industries ? { values: payload.labels.industries } : companyProfile.industries,
          // if payload is provided, we always update the product stage. So we should always set the value here to the payload value when payload != null
          stages: payload.labels
            ? { values: payload.labels.productStage ? [payload.labels.productStage] : [] }
            : companyProfile.stages,
          fundingStage: payload.labels?.fundingStage ? payload.labels.fundingStage : companyProfile.fundingStage,
        };

        options?.onSuccess && options.onSuccess();
        invalidate(companyProfileKey(companySlug), updatedCompanyProfile);
        invalidate(companyProfileByIdKey(companyId), updatedCompanyProfile);
        invalidate(userDashboardKey);
        invalidate(companyDashboardKey(companyId));
        invalidate(companyOnboardingKey(companySlug));

        // These are the only fields that are contained in the pitch resource
        if (payload.company.valuation || payload.company.revenues) {
          invalidate(pitchKey(companyId));
        }
      },
      onFailure: options?.onFailure
        ? options.onFailure
        : () => notify('error', `Something unexpected happened when trying to update company information.`),
    },
  );
};
