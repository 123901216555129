import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import Button from 'ui/elements/buttons/Button';
import TextField from 'ui/elements/form/TextField';
import i18n from 'util/i18n';
import { PostConversationMessage } from 'apis/ContentAPI/conversationApi';
import Tooltip from 'ui/elements/Tooltip';
import { IconButton, useTheme } from '@mui/material';
import ClipIcon from 'ui/elements/icons/ClipIcon';
import Dropzone from 'domain/shared/Files/Upload/Dropzone';
import { uploadFile } from 'domain/shared/Files/actions';
import styles from './styles.scss';
import { notEmpty } from 'util/arrayUtils';
import UploadedFile from 'domain/shared/Files/File/UploadedFile';
import useFileUpload from 'domain/shared/Files/Upload/useFileUpload';
import useNotify from 'hooks/useNotify';
import ShareCompanyCard from 'domain/conversations/Message/ShareCard/ShareCompanyCard';
import { SharedContent } from '../types';
import ShareEventCard from '../ShareCard/ShareEventCard';
import { bluePlanetTheme } from 'ui/theme';
import ShareUpdateCard from '../ShareCard/ShareUpdateCard';

interface ConversationMessageFieldProps<T> {
  handleSubmit: (message: PostConversationMessage) => Promise<T>;
  onSuccess?: (message: T) => void;
  onCancel?: () => void;
  submitClassName: string;
  autoFocus?: boolean;
  disabled?: boolean;
  placeholder: string;
  minRows?: number;
  className?: string;
  textFieldClassName?: string;
  disableAttachments?: boolean;
  initialMessage?: string;
  padding?: 'default' | 'none';
  sharedContent?: SharedContent;
  characterLimit?: number;
  isConversationPending?: boolean;
}

const ATTACHMENT_FOLDER = 'conversations';

export default function MessageField<T>(props: ConversationMessageFieldProps<T>) {
  const [message, setMessage] = useState(props.initialMessage || '');
  const [isSaving, setIsSaving] = useState(false);
  const notify = useNotify();
  const { onCancel } = props;

  const [attachments, onDrop, removeFile, reset] = useFileUpload(file => uploadFile(ATTACHMENT_FOLDER, file));
  const theme = useTheme();

  useEffect(() => {
    if (props.isConversationPending) setMessage('');
  }, [props.isConversationPending]);

  async function handleSubmit() {
    try {
      setIsSaving(true);
      const result = await props.handleSubmit({
        content: message,
        attachments: attachments
          .map(f => (f.fileId ? { fileId: f.fileId, filename: f.file.name } : undefined))
          .filter(notEmpty),
        sharedContent:
          props.sharedContent?.type === 'company'
            ? { companyId: props.sharedContent.companyId }
            : props.sharedContent?.type === 'event'
              ? { eventId: props.sharedContent.eventId }
              : props.sharedContent?.type === 'update'
                ? { updateId: props.sharedContent.updateId }
                : undefined,
      });
      setMessage('');
      props.onSuccess && props.onSuccess(result);
      reset();
    } catch (e) {
      notify('error', i18n('en').errors.save('message'));
    } finally {
      setIsSaving(false);
    }
  }

  const InputField = (
    <TextField
      name="message"
      inputProps={{
        backgroundColor: props.isConversationPending
          ? theme.bluePlanetPalette.grey.light
          : theme.bluePlanetPalette.white,
        paddingBottom: attachments.length > 0 ? theme.spacing(7) : undefined,
        paddingRight: props.characterLimit ? theme.spacing(10) : theme.spacing(3),
        ...(props.padding === 'none' && { border: 'initial' }),
      }}
      value={message}
      onChange={e => setMessage(e.target.value)}
      minRows={props.minRows || 1}
      maxRows={props.sharedContent ? 3 : 12}
      multiline
      placeholder={props.placeholder}
      fullWidth
      autoFocus={props.autoFocus}
      onKeyDown={e => {
        if (e.key === 'Enter' && e.shiftKey) {
          return;
        }
        if (e.key === 'Enter') {
          e.preventDefault();
          handleSubmit();
          return;
        }
        if (e.key === 'Escape') {
          e.preventDefault();
          onCancel && onCancel();
          return;
        }

        // text fields are used in modals, so we need to stop propagation to any other keydown handlers from reacting to the keypress
        // specifically, if the chat window is opened in communities where you can navigate between profiles with arrow keys
        e.stopPropagation();
      }}
      disabled={props.disabled}
      suffix={
        <>
          {props.characterLimit && !props.disabled && (
            <div className={styles.characterCounter}>
              <span
                style={
                  message.length > props.characterLimit
                    ? { color: bluePlanetTheme.bluePlanetPalette.red.dark }
                    : undefined
                }
              >
                {message.length}
              </span>
              /<span>{props.characterLimit}</span>
            </div>
          )}
          {!props.disableAttachments ? (
            <div className={styles.uploadedAttachments}>
              {attachments.map(doc => (
                <UploadedFile
                  key={doc.tempId}
                  className="u-half-spacing-right"
                  onClose={() => {
                    removeFile(doc.tempId);
                  }}
                  style={{ maxWidth: `${80 / attachments.length - 1}%`, pointerEvents: 'auto' }}
                  name={doc.file.name}
                  status={doc.status}
                />
              ))}
              {!props.disabled && (
                <Tooltip title="Attach files">
                  <div className={styles.attachmentIcon}>
                    <Dropzone onDrop={onDrop} disableOnClick={props.disabled}>
                      <IconButton size="small" disabled={props.disabled}>
                        <ClipIcon color="dark-grey" fontSize="small" />
                      </IconButton>
                    </Dropzone>
                  </div>
                </Tooltip>
              )}
            </div>
          ) : null}
        </>
      }
    />
  );

  return (
    <div className={props.className}>
      <div className="u-flex-align-center">
        <div className="u-flex u-flex--column u-flex-space-between"></div>
        <div
          className={cx(styles.textField, props.textFieldClassName, 'u-flex-grow', {
            [styles.pending]: props.isConversationPending,
          })}
        >
          {props.sharedContent?.type === 'company' && (
            <ShareCompanyCard
              className="u-half-spacing"
              companyId={props.sharedContent.companyId}
              shouldNavigateToCompanyProfile={false}
            />
          )}
          {props.sharedContent?.type === 'event' && (
            <ShareEventCard className="u-half-spacing" eventId={props.sharedContent.eventId} />
          )}
          {props.sharedContent?.type === 'update' && (
            <ShareUpdateCard className="u-half-spacing" updateId={props.sharedContent.updateId} />
          )}
          {!props.disableAttachments ? (
            <Dropzone disableOnClick onDrop={onDrop}>
              <>{InputField}</>
            </Dropzone>
          ) : (
            InputField
          )}
        </div>
        <div className={styles.messageFormButtons}>
          {props.onCancel && (
            <span className="u-half-spacing-right">
              <Tooltip title="Cancel" placement="top">
                <Button tabIndex={1} kind="tertiary" onClick={props.onCancel}>
                  Cancel
                </Button>
              </Tooltip>
            </span>
          )}
          <Button
            size="large"
            tabIndex={0}
            kind="primary"
            isLoading={isSaving}
            disabled={
              (message.length === 0 && attachments.filter(d => d.fileId).length === 0) ||
              (props.characterLimit && message.length > props.characterLimit) ||
              props.disabled
            }
            className={cx(styles.submitButton, props.submitClassName)}
            onClick={handleSubmit}
          >
            Send
          </Button>
        </div>
      </div>
    </div>
  );
}
