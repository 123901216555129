import React from 'react';
import PageFooter from '../PageFooter';
import { lighten } from '@mui/material';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { bluePlanetTheme } from 'ui/theme';

export const Body = styled.div(
  (props: { accountForMobileHeader?: boolean; backgroundColor?: 'white' | 'grey-light' }) => css`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
    background-color: ${props.backgroundColor === 'white' ? bluePlanetTheme.bluePlanetPalette.white : undefined};

    --scrollbar-background: ${bluePlanetTheme.bluePlanetPalette.grey.medium};
    --scrollbar-foreground: ${lighten(bluePlanetTheme.bluePlanetPalette.grey.main, 0.35)};

    scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);

    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-thumb {
      /* Foreground */
      background: var(--scrollbar-foreground);
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${bluePlanetTheme.bluePlanetPalette.grey.main};
    }

    ::-webkit-scrollbar-track {
      /* Background */
      background: var(--scrollbar-background);
    }

    ${props.accountForMobileHeader && bluePlanetTheme.breakpoints.down('md')} {
      padding-top: 60px;
    }
  `,
);

export const BODY_ID = 'scrollable-body';

export default function ScrollableBody({
  children,
  className,
  accountForMobileHeader,
  hideFooter,
  backgroundColor,
}: {
  children: React.ReactNode;
  className?: string;
  accountForMobileHeader: boolean;
  hideFooter?: boolean;
  backgroundColor?: 'white' | 'grey-light';
}) {
  return (
    <Body
      id={BODY_ID}
      className={className}
      accountForMobileHeader={accountForMobileHeader}
      backgroundColor={backgroundColor}
    >
      <div>{children}</div>
      {!hideFooter && <PageFooter />}
    </Body>
  );
}
