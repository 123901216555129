import cx from 'classnames';
import { isSameDay } from 'date-fns';
import { stripContentAttachments } from 'domain/Updates/shared/utils';
import UserConversationDialog from 'domain/conversations/UserConversationDialog';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useDialogHandler from 'hooks/useDialogHandler';
import React from 'react';
import { Link } from 'react-router-dom';
import HtmlContent from 'ui/elements/HtmlContent';
import Avatar from 'ui/elements/avatars/Avatar';
import AvatarGroup from 'ui/elements/avatars/AvatarGroup';
import LinkAsButton from 'ui/elements/buttons/LinkAsButton';
import ChatAddIcon from 'ui/elements/icons/ChatAddIcon';
import CheckMarkCircleIcon from 'ui/elements/icons/CheckmarkCircleIcon';
import LinkIcon from 'ui/elements/icons/LinkIcon';
import ShareIcon from 'ui/elements/icons/ShareIcon';
import PopMenu, { PopMenuItem } from 'ui/modules/PopMenu';
import Card from 'ui/views/cards/Card';
import { communityUrls, eventUrls, organizationUrls } from 'urls';
import { notEmpty } from 'util/arrayUtils';
import { asTime, dateWithDayName } from 'util/dateUtils';
import CalendarDate from './CalendarDate';
import styles from './styles.scss';
import { EventDTO, EventHostDTO } from './types';
import useResource from 'util/resource/useResource';
import { getOrUndefined } from 'util/resource';
import { TinyOrganizationDTO } from 'types/organization';
import OrganizationAPI from 'apis/OrganizationAPI';
import useRoute from 'hooks/useRoute';
import Button from 'ui/elements/buttons/Button';
import styled from '@emotion/styled';
import { useCommunitySummary } from 'apis/CompanyAPI/communities/useCommunitySummary';

function EventHeader({ event }: { event: EventDTO }) {
  return (
    <div className="u-flex u-flex--align-items-start">
      <CalendarDate date={event.startsAt} />
      <div className={styles.eventHeading}>
        <div>
          {dateWithDayName(event.startsAt)}
          <br />{' '}
          {event.endsAt && isSameDay(new Date(event.startsAt), new Date(event.endsAt))
            ? `${asTime(event.startsAt)} - ${asTime(event.endsAt)}`
            : `at ${asTime(event.startsAt)}`}
        </div>
        {event.richLocation && (
          <div>
            Location: <span>{event.richLocation.description}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export function ShareButton({ event }: { event: EventDTO }) {
  const shareEventDialogHandler = useDialogHandler();

  const { copy, showCopiedSuccess } = useCopyToClipboard(
    `${location.origin}${eventUrls.view(event.id)}?utm_source=direct&utm_medium=copy-link&utm_campaign=event`,
  );

  const menuItems: (PopMenuItem | undefined)[] = [
    {
      text: <span>Copy link to event</span>,
      onClick: copy,
      icon: showCopiedSuccess ? <CheckMarkCircleIcon strokeWidth={2} fontSize="small" color="green" /> : <LinkIcon />,
    },
    {
      text: <span>Share in chat</span>,
      onClick: shareEventDialogHandler.open,
      icon: <ChatAddIcon fontSize="small" />,
    },
    navigator.share
      ? {
          text: <span>More options</span>,
          onClick: () =>
            navigator.share({
              title: `Join me at ${event.title}!`,
              url: `${location.origin}${eventUrls.view(event.id)}?utm_source=direct&utm_medium=native-share&utm_campaign=event`,
            }),
          icon: <ShareIcon />,
        }
      : undefined,
  ];

  return (
    <>
      <PopMenu
        renderAnchor={onClick => (
          <Button onClick={onClick} kind="tertiary" color="indigo">
            <ShareIcon className="u-quarter-spacing-right" />
            <span className="u-ellipsis">Share</span>
          </Button>
        )}
        items={menuItems.filter(notEmpty)}
      />
      {shareEventDialogHandler.isOpen && (
        <UserConversationDialog
          sharedContent={{ type: 'event', eventId: event.id }}
          conversation={{ type: 'new' }}
          closeConversation={shareEventDialogHandler.close}
        />
      )}
    </>
  );
}

function HostList({ hosts, eventId }: { hosts: EventHostDTO[]; eventId: string }) {
  const LOGO_SIZE = 32;
  // Maximum number of icons and org names displayed. If the number of orgs exceeds this, then only DISPLAY_LIMIT - 1 are displayed,
  // and instead the last displayed icon and org name will be the +n icon and "n others" text
  const DISPLAY_LIMIT = 3;

  const OrganizationNames = () => {
    return (
      <>
        {hosts.slice(0, hosts.length > DISPLAY_LIMIT ? DISPLAY_LIMIT - 1 : DISPLAY_LIMIT).map((host, idx) => (
          <React.Fragment key={`org-name-${host.slug}`}>
            <Link to={host.href}>
              <span>{host.name}</span>
            </Link>
            {idx < Math.min(hosts.length - 1, DISPLAY_LIMIT - 1) && ', '}
          </React.Fragment>
        ))}
        {hosts.length > DISPLAY_LIMIT ? (
          <Link to={{ pathname: eventUrls.view(eventId), state: { fromPreview: true } }}>
            and {hosts.length - DISPLAY_LIMIT + 1} others
          </Link>
        ) : undefined}
      </>
    );
  };

  return (
    <div className="u-flex u-flex-align-center text-metadata">
      <div className="u-quarter-spacing-right">By</div>
      <AvatarGroup size={LOGO_SIZE} max={DISPLAY_LIMIT}>
        {hosts.map(host => (
          <Avatar
            key={organizationUrls.view(host.slug)}
            imageUrl={host.logoURL}
            userName={host.name}
            borderColor="grey"
          />
        ))}
      </AvatarGroup>
      <div>
        <OrganizationNames />
      </div>
    </div>
  );
}

const mapOrganizationToHost = (organizations?: TinyOrganizationDTO[]) =>
  organizations
    ? organizations.map(org => ({
        id: org.id.toString(),
        name: org.name,
        slug: org.slug,
        logoURL: org.logoURL,
        href: organizationUrls.view(org.slug),
      }))
    : [];

const useEventHostOrganization = (organizationId?: UUID) => {
  const { resource: organizationResource } = useResource<TinyOrganizationDTO>(
    organizationId ? OrganizationAPI.getById(organizationId) : undefined,
  );
  return getOrUndefined(organizationResource);
};

const useEventHostCommunity = (communityId?: number) => {
  const { resource: communityResource } = useCommunitySummary(communityId);
  return getOrUndefined(communityResource)?.details;
};

const ClickableContainer = styled.button`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export default function EventPreview({ event, trackingKey }: { event: EventDTO; trackingKey?: string }) {
  const strippedContent = stripContentAttachments(event.content);

  const organization = useEventHostOrganization(event.organizationId);
  const community = useEventHostCommunity(event.communityId);
  const push = useRoute().push;

  const allHosts = [
    ...mapOrganizationToHost(event.organizations),
    ...(organization
      ? [
          {
            id: organization.id,
            name: organization.name,
            slug: organization.slug,
            logoURL: organization.logoURL,
            href: organizationUrls.view(organization.slug),
          },
        ]
      : []),
    ...(community
      ? [
          {
            id: community.id.toString(),
            name: community.name,
            slug: community.slug,
            logoURL: community.logoURL,
            href: communityUrls.overview(community.slug),
          },
        ]
      : []),
  ];

  return (
    <Card className={styles.eventCard} elevation={1} hover="highlight">
      <ClickableContainer onClick={() => push(eventUrls.view(event.id))}>
        <EventHeader event={event} />
        <div className={styles.eventPreviewContainer}>
          <div className={styles.previewDescription}>{event.title}</div>
          <div className={styles.previewContent}>
            <HtmlContent className="ql-rich-text">{strippedContent}</HtmlContent>
          </div>
          {allHosts && <HostList hosts={allHosts} eventId={event.id} />}
        </div>
      </ClickableContainer>
      <div className="u-half-spacing-top u-flex u-flex-space-between">
        <LinkAsButton
          className={cx('u-ellipsis', trackingKey ? `data-track-${trackingKey}` : undefined)}
          kind="tertiary"
          url={{ pathname: eventUrls.view(event.id) }}
        >
          View details
        </LinkAsButton>
        <ShareButton event={event} />
      </div>
    </Card>
  );
}
