import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import SectionHeading from 'ui/elements/SectionHeading';
import { communityUrls } from 'urls';
import InvestorTile from 'domain/investors/InvestorTile';
import UserProfileDialogRoute, { userDialogUrl } from 'domain/users/UserProfile/UserProfileDialogRoute';
import { pluralize } from 'util/stringUtils';
import Carousel, { SeeMoreCard } from 'ui/elements/Carousel';
import { PaginatedResult } from 'types';
import { InvestorProfile } from 'types/user';
import { communityAPIUrls } from 'apis/CompanyAPI/communities/communitiesApi';
import useSWR from 'hooks/useSWR';
import { useSWRResource } from 'util/resource/useSWRResource';
import { communityBleedAmounts } from 'ui/views/layouts/CommunityPage';
import { hasMorePages } from 'util/paginationUtils';
import { CommunityConfiguration } from 'types/company/community';

interface Props {
  communitySlug: string;
  configuration: CommunityConfiguration;
  investors: PaginatedResult<InvestorProfile>;
}

export function useFeaturedInvestors(communitySlug: string, limit: number) {
  const resource = useSWR<PaginatedResult<InvestorProfile>>(
    communityAPIUrls.investors.list(communitySlug, 'random', {}, { limit }),
  );
  return useSWRResource(resource);
}

export default function FeaturedCommunityInvestors({ communitySlug, configuration, investors }: Props) {
  const match = useRouteMatch();

  return (
    <>
      {configuration.peopleAreVisible && investors.total > 0 && (
        <div className="u-section-spacing-bottom">
          <SectionHeading
            heading={`Explore ${investors.total} ${pluralize(investors.total, 'investor', 'investors')}`}
            actionsAlignment="left"
          >
            <Link to={communityUrls.members.overview(communitySlug, 'investors')} className="text-link-small">
              See all
            </Link>
          </SectionHeading>
          <Carousel options={{ dragFree: 'mobile' }} bleed={communityBleedAmounts}>
            {investors.values.map(investor => (
              <InvestorTile
                key={investor.id}
                viewInvestorHref={userDialogUrl(match.url, investor.cwUserId)}
                investor={investor}
              />
            ))}
            {hasMorePages(investors) && (
              <SeeMoreCard href={communityUrls.members.overview(communitySlug, 'investors')} />
            )}
          </Carousel>
        </div>
      )}
      <UserProfileDialogRoute />
    </>
  );
}
