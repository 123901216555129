import numeral from 'numeral';

export const formatThousands = (value?: number) => numeral(value).format('0,0');
export const formatWithDecimals = (value?: number, numberOfDecimals: number = 1) => {
  const formatString = `0.${'0'.repeat(numberOfDecimals)}`;
  return numeral(value).format(formatString);
};
export function letterify(value: number | string) {
  return value ? numeral(value).format('0.[00]a') : '';
}

export function format(number: number, format: string) {
  return numeral(number).format(format);
}
