import React, { useState } from 'react';
import { Title } from 'ui/views/dialogs/Dialog';
import { logError } from 'util/logging';
import ProgressSteps from './ProgressSteps';

export interface OnboardingStage<T extends string> {
  name: T;
  title: string;
  subtitle?: React.ReactNode;
  hideStageInformation?: boolean;
  content: ({
    onComplete,
    onSkip,
    onBack,
  }: {
    onComplete: () => void;
    onSkip: () => void;
    onBack: () => void;
  }) => JSX.Element;
}

interface Props<T extends string> {
  stages: OnboardingStage<T>[];
  debrief?: OnboardingStage<T>;
  completedStages: T[];
  skipStage?: (value: T) => void;
}

export default function Wizard<T extends string>({ stages, completedStages, debrief, skipStage }: Props<T>) {
  completedStages.forEach(cs => {
    if (!stages.some(stage => stage.name === cs)) {
      logError(`Completed stages contains a value that is not a valid stage: ${cs}`);
    }
  });

  const nextUncompletedStage = stages.reduce((acc, elem) => (completedStages.includes(acc.name) ? elem : acc));

  const [currentStageIndex, setCurrentStageIndex] = useState(
    stages.map((stage, i) => ({ stage, i })).find(({ stage }) => stage.name === nextUncompletedStage.name)?.i ?? 0,
  );

  const currentStage = stages[currentStageIndex] ?? debrief;

  const prevStep = () => setCurrentStageIndex(i => Math.max(i - 1, 0));

  const nextStep = () => {
    const totalNumberOfStages = stages.length + (debrief ? 1 : 0);
    setCurrentStageIndex(i => Math.min(i + 1, totalNumberOfStages - 1));
  };

  if (!currentStage) {
    return null;
  }

  return (
    <>
      <Title subheading={currentStage.subtitle}>
        <div className="u-flex-space-between" style={{ width: '100%' }}>
          {currentStage.hideStageInformation ? (
            <div>
              <h3 className="text-h3 u-quarter-spacing-bottom">{currentStage?.title}</h3>
            </div>
          ) : (
            <>
              <div>
                <h3 className="text-h3 u-quarter-spacing-bottom">{currentStage?.title}</h3>
                <p className="text-metadata">
                  {currentStageIndex < stages.length && (
                    <>
                      Step {currentStageIndex + 1} of {stages.length}
                    </>
                  )}
                </p>
              </div>
              <ProgressSteps
                steps={stages.map((stage, i) => ({
                  name: stage.title,
                  number: i + 1,
                  isActive: currentStageIndex === i,
                }))}
              />
            </>
          )}
        </div>
      </Title>
      {currentStage.content({
        onComplete: () => {
          nextStep();
        },
        onSkip: () => {
          if (!skipStage) {
            return;
          }

          skipStage(currentStage.name);
          nextStep();
        },
        onBack: prevStep,
      })}
    </>
  );
}
