import { ContentAPI } from 'apis/ContentAPI';
import React from 'react';
import Button from 'ui/elements/buttons/Button';
import ImageUpload from 'ui/modules/ImageUpload';
import Placeholder from 'ui/modules/ImageUpload/Placeholder';
import styles from './styles.scss';
import { UploadLogo } from './UploadLogo';
import config from 'config';
import Label from 'ui/elements/form/Label';
import { useCompanies } from 'apis/CompanyAPI/companies/useCompanies';
import useNotify from 'hooks/useNotify';
import {
  companyProfileByIdKey,
  companyProfileKey,
  useCompanyProfile,
} from 'apis/CompanyAPI/companies/useCompanyProfile';
import { invalidate } from 'hooks/useSWR';
import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import Resource from 'util/resource/Resource';
import { useCompanyLastUpdated } from 'domain/companies/profile/LastUpdated';

export const BANNER_IMAGE_SUGGESTED_MIN_WIDTH = 1825; // This is the widest the banner image will be with the current design 10/12/2020
export const BANNER_IMAGE_SUGGESTED_MIN_HEIGHT = 450; // This is a bit more than the max height of the banner image with the currrent design.
// It is better for the user experience that the image is too high, than too wide.
export const BANNER_IMAGE_ASPECT_RATIO = BANNER_IMAGE_SUGGESTED_MIN_WIDTH / (BANNER_IMAGE_SUGGESTED_MIN_HEIGHT - 1); // Subtract one to make sure the rounding error is in the vertical dimension

interface Props {
  companyId: number;
  companySlug: string;
  logoURL?: string;
  imageURL?: string;
  onLogoUploaded?: (logoURL: string) => void;
  onImageUploaded?: (imageURL: string) => void;
}

export default function BrandingForm({
  companyId,
  companySlug,
  logoURL,
  imageURL,
  onLogoUploaded,
  onImageUploaded,
}: Props) {
  const api = ContentAPI(`${config.CONTENT_API_URL}/companies/${companyId}`);

  const companies = useCompanies();
  const notify = useNotify();
  const { setLastUpdated } = useCompanyLastUpdated(companyId);

  const { resource: company } = useCompanyProfile(companySlug);

  return (
    <Resource resource={company}>
      {company => (
        <section>
          <Label>Company logo</Label>
          <div>
            <UploadLogo
              logoURL={logoURL}
              companyId={companyId}
              notify={notify}
              onUploadSuccess={async (companyId, image) => {
                await companiesApi.admin.updateLogo(companyId, image.secure_url);
                setLastUpdated(new Date());
                if (onLogoUploaded) onLogoUploaded(image.secure_url);

                // We use the BrandingForm for both community and company edits, so we need to refetch both when uploading a logo
                const updatedCompany = { ...company, logoURL: image.secure_url };
                invalidate(companyProfileKey(companySlug), updatedCompany);
                invalidate(companyProfileByIdKey(companyId), updatedCompany);

                companies.mutate();
              }}
            />
          </div>
          <div className="u-section-spacing-top">
            <Label>Banner image</Label>
            <ImageUpload
              imageUrl={imageURL}
              aspectRatio={BANNER_IMAGE_ASPECT_RATIO}
              onUploadSuccess={async image => {
                await companiesApi.admin.updateBannerImage(companyId, image.secure_url);
                if (onImageUploaded) onImageUploaded(image.secure_url);
                const updatedCompany = { ...company, imageURL: image.secure_url };
                invalidate(companyProfileKey(companySlug), updatedCompany);
                invalidate(companyProfileByIdKey(companyId), updatedCompany);
              }}
              onUploadFailure={() => {
                notify('error', 'Upload failed. Only jpeg & png images are allowed.');
              }}
              getUploadUrl={filename => api.images.getCompanyUploadUrl(companyId, filename)}
              placeholder={<Placeholder>Drop your banner image here or click to select one</Placeholder>}
            >
              {openDropzone => (
                <div className={styles.bannerActions}>
                  <Button kind="secondary" onClick={openDropzone} className="u-content-spacing-right">
                    <span>Upload image</span>
                  </Button>
                  <p className="text-metadata-regular">
                    For the best result use an image of at least {BANNER_IMAGE_SUGGESTED_MIN_WIDTH}x
                    {BANNER_IMAGE_SUGGESTED_MIN_HEIGHT}px in size
                  </p>
                </div>
              )}
            </ImageUpload>
          </div>
        </section>
      )}
    </Resource>
  );
}
