import EditIcon from 'ui/elements/icons/EditIcon';
import React, { useState } from 'react';
import { Update } from 'types/content';
import Chip from 'ui/elements/Chip';
import ArchiveIcon from 'ui/elements/icons/ArchiveIcon';
import UnarchiveIcon from 'ui/elements/icons/UnarchiveIcon';
import PinIcon from 'ui/elements/icons/PinIcon';
import ConfirmDialog from 'ui/modules/dialogs/ConfirmDialog';
import Button from 'ui/elements/buttons/Button';
import { ICompany } from 'types/company';
import UserIcon from 'ui/elements/icons/UserIcon';
import UserProfileDialog from 'domain/users/UserProfile/UserProfileDialog';
import useBackoffice from 'hooks/useBackoffice';
import GraphIcon from 'ui/elements/icons/GraphIcon';
import UpdateStatsDialog from '../UpdateStatsDialog';
import TrashIcon from 'ui/elements/icons/TrashIcon';
import { Auth0User } from 'types';
import { useAuth0 } from '@auth0/auth0-react';
import { getAdminRights } from 'auth/util';
import { UpdateAPI } from 'apis/ContentAPI/UpdateAPI';
import useNotify from 'hooks/useNotify';
import useLazyResource from 'util/resource/useLazyResource';
import PopMenu from 'ui/modules/PopMenu';
import { bluePlanetTheme } from 'ui/theme';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import CheckMarkCircleIcon from 'ui/elements/icons/CheckmarkCircleIcon';
import CopyLinkIcon from 'ui/elements/icons/CopyLinkIcon';
import UserConversationDialog from 'domain/conversations/UserConversationDialog';
import ShareIcon from 'ui/elements/icons/ShareIcon';

interface ContainerProps {
  update: Update;
  updateUrl: string;
  company: ICompany;
  api: UpdateAPI;
  authorId?: string;
  onEdit?: () => void;
  canEditPost?: boolean;
  onArchive?: (contentId: string) => void;
  onDelete?: () => void;
  setPinned: (isPinned: boolean) => void;
  isPinned: boolean;
  canPinToFeed?: boolean;
}

export default function CommunityUpdateMenu(props: ContainerProps) {
  const { update, isPinned, setPinned } = props;
  const [isDeleting, setDeleting] = useState(false);

  const [isArchived, setArchived] = useState<boolean>(update.archived || false);
  const notify = useNotify();

  const [isPinDialogOpen, setPinDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [updateStatsDialogIsOpen, setUpdateStatsDialogIsOpen] = useState(false);
  const [isShareUpdateDialogOpen, setShareUpdateDialogOpen] = useState(false);

  const { copy: copyUpdateLink, showCopiedSuccess: showUpdateLinkCopiedSuccess } = useCopyToClipboard(props.updateUrl);

  const backoffice = useBackoffice();

  const [togglePin] = useLazyResource((isPinned: boolean) => props.api.togglePin(props.update.id, isPinned), {
    onSuccess: () => {
      setPinned(!isPinned);
      notify('success', `Update has been ${isPinned ? 'pinned' : 'unpinned'} `);
    },
    onFailure: () => notify('error', `Could not ${isPinned ? 'pin' : 'unpin'} update. Please try again later.`),
  });
  const [onArchive] = useLazyResource(
    (isArchived: boolean) => (!isArchived ? props.api.archive(props.update.id) : props.api.unArchive(props.update.id)),
    {
      onSuccess: () => {
        setArchived(!isArchived);
        notify('success', `Update has been ${isArchived ? 'un' : ''}archived`);
      },
      onFailure: () => notify('error', `Could not ${isArchived ? 'un' : ''}archive update. Please try again later.`),
    },
  );

  const onDeleteContent = async () => {
    try {
      setDeleting(true);
      await props.api.delete(props.update.id);
      props.onDelete && props.onDelete();
      notify('success', 'Update deleted');
    } catch {
      setDeleting(false);
      notify('error', 'Failed to delete update');
    } finally {
      setDeleting(false);
      setDeleteDialogOpen(false);
    }
  };

  const [userDialogIsOpen, setUserDialogIsOpen] = useState(false);
  const { user }: { user?: Auth0User } = useAuth0();
  const adminRights = getAdminRights(user);
  const canAccessBackoffice = adminRights.includes('backoffice');

  const shouldDisplayActions = props.authorId || props.canEditPost;
  return shouldDisplayActions ? (
    <>
      {isArchived && <Chip color="orange-light" label="Archived" className="u-quarter-spacing-left" />}
      <PopMenu
        items={[
          {
            isVisible: !!props.authorId,
            text: 'View author profile',
            icon: <UserIcon />,
            onClick: () => setUserDialogIsOpen(true),
          },
          {
            isVisible: true,
            text: 'Copy link to post',
            onClick: copyUpdateLink,
            icon: showUpdateLinkCopiedSuccess ? <CheckMarkCircleIcon color="green" /> : <CopyLinkIcon />,
          },
          {
            isVisible: true,
            text: 'Send link via chat',
            onClick: () => setShareUpdateDialogOpen(true),
            icon: <ShareIcon />,
          },
          {
            isVisible: props.canEditPost && !!props.onEdit,
            text: 'Edit',
            icon: <EditIcon />,
            onClick: props.onEdit,
          },
          {
            isVisible: props.canEditPost && props.canPinToFeed,
            text: isPinned ? 'Unpin' : 'Pin to top',
            icon: <PinIcon />,
            onClick: () => setPinDialogOpen(true),
          },
          {
            isVisible: props.canEditPost,
            text: isArchived ? 'Unarchive' : 'Archive',
            icon: isArchived ? <UnarchiveIcon /> : <ArchiveIcon />,
            onClick: () => onArchive(isArchived),
          },
          {
            isVisible: backoffice.adminRights.includes('read-everything') && backoffice.showBackofficeInfoOnProfiles,
            text: 'View stats',
            icon: <GraphIcon />,
            onClick: () => setUpdateStatsDialogIsOpen(true),
            backgroundColor: bluePlanetTheme.bluePlanetPalette.yellow.medium,
          },
          {
            isVisible: props.onDelete && canAccessBackoffice,
            text: 'Delete',
            icon: <TrashIcon />,
            onClick: () => setDeleteDialogOpen(true),
          },
        ].filter(item => item.isVisible)}
      />
      <ConfirmDialog
        isOpen={isPinDialogOpen}
        onClose={() => setPinDialogOpen(false)}
        title={props.isPinned ? 'Unpin update' : 'Pin to top'}
        confirmButton={
          <Button kind="primary" onClick={() => togglePin(!isPinned)}>
            {props.isPinned ? 'Unpin' : 'Pin'}
          </Button>
        }
      >
        {props.isPinned
          ? 'The update will no longer appear at the top of the feed.'
          : 'The update will appear at the top of the feed. You can unpin it whenever you like.'}
      </ConfirmDialog>
      {props.authorId && (
        <UserProfileDialog
          isOpen={userDialogIsOpen}
          cwUserId={props.authorId}
          onClose={() => setUserDialogIsOpen(false)}
        />
      )}

      {updateStatsDialogIsOpen && (
        <UpdateStatsDialog updateId={props.update.id} onClose={() => setUpdateStatsDialogIsOpen(false)} />
      )}
      <ConfirmDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        title="You are about to delete this post"
        confirmButton={
          <Button color="red" kind="primary" onClick={onDeleteContent} isLoading={isDeleting}>
            Yes, delete
          </Button>
        }
        dismissLabel="No, cancel"
      >
        <p className="u-content-spacing-bottom">It will no longer be visible anywhere on the platform.</p>
        <p>Are you sure?</p>
      </ConfirmDialog>
      {isShareUpdateDialogOpen && (
        <UserConversationDialog
          conversation={{ type: 'new' }}
          closeConversation={() => setShareUpdateDialogOpen(false)}
          sharedContent={{
            type: 'update',
            updateId: props.update.id,
          }}
        />
      )}
    </>
  ) : null;
}
