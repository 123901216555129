import NotFound from 'pages/common/error/NotFound';
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CompanyListItem } from 'types/company';
import NetworkHome from './Network';
import Companies from './Companies';
import PageTitle from 'ui/views/layouts/BaseLayout/PageTitle';
import { sortDates } from 'util/dateUtils';
import Communities from './Communities';
import { scrollToTop } from 'util/scroll';
import { OrganizationListItemDTO } from 'types/organization';
import { useCommunitiesDetailed } from 'apis/CompanyAPI/communities/useCommunities';
import Resource from 'util/resource/Resource';
import Organizations from './Organizations';

interface Props {
  companies: CompanyListItem[];
  organizations: OrganizationListItemDTO[];
}

export default function Network({ companies, organizations }: Props) {
  const myCompanies = [...companies].sort((a, b) => sortDates(b.lastUpdated, a.lastUpdated));

  const { resource: communities } = useCommunitiesDetailed();

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <PageTitle title="Network"></PageTitle>
      <Resource resource={communities}>
        {communities => (
          <Switch>
            <Route
              exact
              path="/network"
              render={() => (
                <NetworkHome communities={communities.values} companies={myCompanies} organizations={organizations} />
              )}
            />
            <Route exact path="/network/companies" render={() => <Companies companies={myCompanies} />} />
            <Route exact path="/network/communities" render={() => <Communities communities={communities.values} />} />
            <Route exact path="/network/organizations" render={() => <Organizations organizations={organizations} />} />
            <Route component={NotFound} />
          </Switch>
        )}
      </Resource>
    </>
  );
}
