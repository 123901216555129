import { fromStatusCode } from 'pages/common/error/helpers';
import { useEffect, useState } from 'react';
import { IResource } from 'util/resource';

export default function useResourceLegacy<T>(
  fetchResource: (() => Promise<T>) | undefined,
  deps: any[],
  onLoad?: (resource: T) => void,
) {
  const [state, setState] = useState<IResource<T>>({ state: 'idle' });
  const reload = async () => {
    if (fetchResource) {
      setState({ state: 'fetching' });
      try {
        if (fetchResource) {
          const resource = await fetchResource();
          if (resource) {
            setState({ resource, state: 'fetched', isValidating: false });
          } else {
            setState({ state: 'fetched-no-content' });
          }
          onLoad && onLoad(resource);
        }
      } catch (e) {
        setState({ state: 'error', errorType: fromStatusCode(e?.status) });
      }
    }
  };

  useEffect(() => {
    reload();
  }, deps);

  const setLoadedResource = (func: (prevState: T) => T) => {
    if (state.state === 'fetched') {
      setState({
        state: 'fetched',
        resource: func(state.resource),
        isValidating: state.isValidating,
      });
    }
  };

  type ReturnType = [typeof state, typeof setLoadedResource, typeof setState, typeof reload];
  const returnable: ReturnType = [state, setLoadedResource, setState, reload];
  return returnable;
}

export function emptyResource<T>(emptyValue: T) {
  return () => Promise.resolve(emptyValue);
}
