import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import EventAPI from 'apis/EventAPI';
import useNotify from 'hooks/useNotify';
import { invalidate } from 'hooks/useSWR';
import React from 'react';
import { TinyCommunity } from 'types/company/community';
import { TinyOrganizationDTO } from 'types/organization';
import { EventDTO, EventUser, getCalendarEvent, TinyEventUserInfo } from 'ui/domain/Event/types';
import Avatar from 'ui/elements/avatars/Avatar';
import AvatarGroup from 'ui/elements/avatars/AvatarGroup';
import Button from 'ui/elements/buttons/Button';
import CalendarButton from 'ui/elements/buttons/CalendarButton';
import CheckMarkCircleIcon from 'ui/elements/icons/CheckmarkCircleIcon';
import { getOrUndefined } from 'util/resource';
import useLazyResource from 'util/resource/useLazyResource';

function InterestedList({ interestedUsers }: { interestedUsers: EventUser[] }) {
  return (
    <div className="u-flex u-flex-align-center text-metadata">
      <div className="u-flex-center" style={{ width: '120px' }}>
        <AvatarGroup size={40} max={3}>
          {interestedUsers.map(user => (
            <Avatar key={user.user.id} imageUrl={user.user.imageUrl} borderColor="grey" />
          ))}
        </AvatarGroup>
      </div>
      <div className="u-quarter-spacing-left">and others are interested in this event</div>
    </div>
  );
}

export function EventInterest({
  event,
  community,
  organization,
}: {
  event: EventDTO;
  community?: TinyCommunity;
  organization?: TinyOrganizationDTO;
}) {
  const notify = useNotify();
  const { resource: userProfileResource } = useSelfUserProfile();
  const userProfile = getOrUndefined(userProfileResource);
  const eventUser = event.users.find(user => user.user.id === userProfile?.id);
  const interestedUsers = event.users.filter(user => user.isInterested).reverse();
  const userIsContactPerson = userProfile?.id === event.contactPerson?.id;

  const [updateInterestedInEvent, isSaving] = useLazyResource(
    (payload: TinyEventUserInfo) => EventAPI.updateEventUserInfo(event.id, payload),
    {
      onSuccess: () => {
        invalidate(EventAPI.get(event.id));
      },
      onFailure: error => notify('error', error || 'Failed to update interest.'),
    },
  );

  const hasShownInterest = eventUser?.isInterested || false;

  return (
    <CalendarButton
      event={getCalendarEvent(event)}
      trackingKey={'event-add-to-calendar'}
      extraOnClick={
        community || organization ? () => updateInterestedInEvent({ hasCalendarActivity: true }) : undefined
      }
    />
  );

  // kill the interest list for now, not sure if we need it

  return (
    <div className="u-flex u-flex--column u-flex--gap-half">
      {interestedUsers && interestedUsers.length > 0 && <InterestedList interestedUsers={interestedUsers} />}
      {!eventUser && <CalendarButton event={getCalendarEvent(event)} trackingKey={'event-add-to-calendar'} />}
      {!userIsContactPerson && (
        <>
          {!hasShownInterest && (community || organization) && (
            <span>
              <Button
                kind="primary"
                color="blue"
                isLoading={isSaving}
                onClick={() => updateInterestedInEvent({ isInterested: true })}
              >
                Show interest
              </Button>
            </span>
          )}
          {hasShownInterest && (community || organization) && (
            <>
              <span>
                <Button
                  kind="primary"
                  color="blueLight"
                  isLoading={isSaving}
                  onClick={() => updateInterestedInEvent({ isInterested: false })}
                >
                  <div className="u-flex-align-center u-flex--gap-quarter">
                    <CheckMarkCircleIcon fontSize="small" />
                    Interested
                  </div>
                </Button>
              </span>
              <CalendarButton
                event={getCalendarEvent(event)}
                trackingKey={'event-add-to-calendar'}
                extraOnClick={
                  community || organization ? () => updateInterestedInEvent({ hasCalendarActivity: true }) : undefined
                }
              />
            </>
          )}
        </>
      )}
    </div>
  );
}
