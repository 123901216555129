import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function TicketStarIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="m15.018 10.175.528 1.065c.072.148.264.29.426.318l.957.16c.612.103.756.55.315.992l-.744.75a.66.66 0 0 0-.156.547l.213.929c.168.735-.219 1.02-.864.635l-.897-.535a.644.644 0 0 0-.594 0l-.896.535c-.642.384-1.032.097-.864-.635l.213-.929a.66.66 0 0 0-.156-.547l-.744-.75c-.438-.442-.297-.89.315-.992l.957-.16a.654.654 0 0 0 .423-.318l.527-1.065c.288-.577.756-.577 1.041 0ZM8 17.738v3.5M8 4.242v3.5" />
      <path d="M22 9.621c-.067-1.542-.255-2.546-.78-3.34a4.658 4.658 0 0 0-1.11-1.174c-1.166-.865-2.811-.865-6.103-.865H9.993c-3.292 0-4.937 0-6.104.865-.431.32-.807.717-1.109 1.174-.525.794-.713 1.798-.78 3.34-.011.263.216.465.465.465 1.386 0 2.51 1.19 2.51 2.656 0 1.467-1.124 2.656-2.51 2.656-.249 0-.476.202-.465.466.067 1.542.255 2.545.78 3.34a4.66 4.66 0 0 0 1.11 1.174c1.166.864 2.811.864 6.103.864h4.014c3.292 0 4.937 0 6.104-.864.431-.32.807-.718 1.109-1.175.525-.794.713-1.798.78-3.34V9.621Z" />
    </SvgIcon>
  );
}
