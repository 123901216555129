import React, { useEffect, useRef, useState } from 'react';
import { FixedSizeList, ListChildComponentProps, ListOnScrollProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import CircularProgress from '@mui/material/CircularProgress';
import { resize } from 'util/cloudinary';
import { useMediaQuery } from '@mui/material';
import { bluePlanetTheme } from 'ui/theme';
import PitchDeckActionSlide from 'domain/companies/Documents/Document/PitchDeck/PitchDeckActionSlide';
import { ICompany } from 'types/company';
import { CompanyViewAs } from 'domain/companies/roleUtils';

interface PitchDeckImagesProps {
  pageNumber: number;
  numPages: number;
  imageUrls: string[];
  company: ICompany;
  viewAs: CompanyViewAs;
  onPageChange: (page: number) => void;
}

function PitchDeckImages({ pageNumber, numPages, imageUrls, company, viewAs, onPageChange }: PitchDeckImagesProps) {
  const listRef = useRef<FixedSizeList>(null);
  const [itemSize, setItemSize] = useState<number>(0);
  const updateItemSize = (index: number, height: number) => {
    setItemSize(height);
  };

  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  const maxWidth = isMobile ? 1125 : undefined;
  const [hasScrolledToPage, setHasScrolledToPage] = useState(false);

  useEffect(() => {
    if (!hasScrolledToPage && itemSize !== 0) {
      listRef.current?.scrollTo(pageNumber * (itemSize + 20));
      setHasScrolledToPage(true);
    }
  }, [itemSize, hasScrolledToPage, pageNumber]);

  // Cache viewAs so that the user is able to both follow and shortlist company
  // and not show share right after following.
  const [viewAsCached] = useState(viewAs);
  const showActionCard = viewAsCached === 'Visitor' || viewAsCached === 'Follower';
  const totalItems = numPages + (showActionCard ? 1 : 0);

  // Use a ref to track the current page without causing re-renders
  const currentPageRef = useRef(pageNumber);

  // Save the container (viewport) height in a ref.
  const containerHeightRef = useRef(0);

  // Handle scroll events to determine the active page
  const handleScroll = ({ scrollOffset: so }: ListOnScrollProps) => {
    if (itemSize === 0 || containerHeightRef.current === 0) return;

    const gap = 20;
    const itemFullHeight = itemSize + gap;
    // Calculate the maximum scroll (in pixels)
    const maxScroll = totalItems * itemFullHeight - containerHeightRef.current;
    // Determine the fraction of scrolling completed (0 at top, 1 at bottom)
    const scrollFraction = maxScroll > 0 ? so / maxScroll : 0;
    // Compute a focus point that moves from 25% of the container height to 75%
    // We need to move the focus point gradually so that when the user hasn't scrolled we assume they are on the first page,
    // while when the user is at the bottom we assume they are viewing the bottom page
    const focusPoint = containerHeightRef.current * (0.25 + 0.5 * scrollFraction);
    const activeIndex = Math.floor((so + focusPoint) / itemFullHeight);
    if (activeIndex !== currentPageRef.current) {
      currentPageRef.current = activeIndex;
      onPageChange(activeIndex);
    }
  };

  return (
    <AutoSizer>
      {({ width, height }) => {
        containerHeightRef.current = height;
        return (
          <>
            <FixedSizeList
              ref={listRef}
              itemCount={totalItems}
              width={width}
              height={height}
              overscanCount={0}
              itemSize={itemSize + 20} // include gap between items
              onScroll={handleScroll}
              style={{
                touchAction: 'pan-y pinch-zoom',
                WebkitOverflowScrolling: 'touch',
              }}
            >
              {({ index, style }: ListChildComponentProps) =>
                index === imageUrls.length && showActionCard ? (
                  <div style={style}>
                    <PitchDeckActionSlide
                      slideHeight={itemSize}
                      slideWidth={width}
                      slideStyle={style}
                      company={company}
                      showInCarousel={false}
                      showFollowAction={viewAsCached !== 'Follower'}
                      isFollowing={viewAs === 'Follower'}
                      hasShortlisted={false}
                    />
                  </div>
                ) : (
                  <div style={style}>
                    <img
                      src={resize(imageUrls[index], { width: maxWidth })}
                      width={width}
                      onLoad={event => {
                        const imgElement = event.currentTarget;
                        // Update the item size only once when the image loads.
                        if (itemSize === 0) {
                          updateItemSize(index, imgElement.offsetHeight);
                        }
                      }}
                      alt={`Page ${index + 1}`}
                    />
                  </div>
                )
              }
            </FixedSizeList>
            {itemSize === 0 && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(255,255,255,0.8)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 10,
                }}
              >
                <CircularProgress />
              </div>
            )}
          </>
        );
      }}
    </AutoSizer>
  );
}

const areEqual = (prevProps: PitchDeckImagesProps, nextProps: PitchDeckImagesProps) => {
  return (
    prevProps.pageNumber === nextProps.pageNumber &&
    prevProps.numPages === nextProps.numPages &&
    prevProps.company.id === nextProps.company.id &&
    prevProps.viewAs === nextProps.viewAs
  );
};

export default React.memo(PitchDeckImages, areEqual);
