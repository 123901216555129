import React from 'react';

import Chip from 'ui/elements/Chip';

export default function StageChip(props: {
  label: string;
  className?: string;
  fontSize?: 'small' | 'large';
  onDelete?: () => void;
  onClick?: () => void;
}) {
  return <Chip color="green" {...props} />;
}
