import { communitiesApi, JoinCommunityAs } from 'apis/CompanyAPI/communities/communitiesApi';
import { invalidateCommunities } from 'apis/CompanyAPI/communities/useCommunities';
import { communityKey } from 'apis/CompanyAPI/communities/useCommunity';
import { communityAccessKey } from 'apis/CompanyAPI/companies/useCommunityAccess';
import { useLoginState } from 'auth/useLoginWithRedirect';
import useNotify from 'hooks/useNotify';
import { invalidate } from 'hooks/useSWR';
import { userDashboardKey } from 'pages/Dashboard/useUserDashboardData';
import { CommunityInviteDetails } from 'types/company/community';
import useLazyResource from 'util/resource/useLazyResource';

export default function useJoinCommunity(invite: CommunityInviteDetails | undefined, onComplete: () => void) {
  const { loginState, reset: resetLoginState } = useLoginState();
  const communitySlug = invite?.community?.slug || loginState.joinCommunity?.slug;

  const notify = useNotify();
  const [requestToJoinCommunityAsUser, isRequesting] = useLazyResource(
    (joinAs: JoinCommunityAs) => {
      if (communitySlug) {
        return communitiesApi.accessRequest
          .requestToJoin(communitySlug, {
            inviteCode: invite?.inviteCode,
            joinAs,
          })
          .then(() => {
            // don't need to store the invite in local storage anymore
            resetLoginState();
          });
      } else {
        return Promise.resolve();
      }
    },
    {
      onSuccess: () => {
        //  we need to refetch these in case the user is auto-accepted into the community
        if (communitySlug) {
          invalidate(communityAccessKey(communitySlug));
          invalidate(communityKey(communitySlug));
        }

        //  we need to refetch these to show correct data on the dashboard
        invalidateCommunities();
        invalidate(userDashboardKey);
        onComplete();
      },
      onFailure: () => notify('error', `Unable to save changes`),
    },
  );
  return { requestToJoinCommunityAsUser, isRequesting };
}
