import React from 'react';
import { communityUrls, userUrls } from 'urls';
import { DashboardMessage as DashboardMessageDTO } from 'apis/DashboardAPI';
import MentorCtaCard from 'domain/mentoring/MentorCta/MentorCtaCard';
import { CardGridWithLimit } from 'ui/views/cards/CardGrid';
import { Link } from 'react-router-dom';
import DashboardMessage from './DashboardMessage';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import Resources from 'util/resource/Resources';
import IconAvatar from 'ui/elements/icons/IconAvatar';
import SearchIcon from 'ui/elements/icons/SearchIcon';
import { CompanyMessages } from 'pages/Dashboard/manage/CompanyMessages';
import styled from '@emotion/styled';
import { contentSpacing, sectionSpacing } from 'ui/theme/themeConstants';
import { bluePlanetTheme } from 'ui/theme';
import featureToggle from 'featureToggle';
import Logo from 'ui/domain/Logo';
import { MobileMargin } from 'ui/views/layouts/BaseLayout';
import Card from 'ui/views/cards/Card';

export const healthArenaMessageKey = `has-dismissed-health-arena-message`;

const MessagesContainer = styled.div`
  /* This isn't great, but with the current way dashboard messages are implemented there is no way of knowing whether we are showing any */
  & > * > * {
    margin-bottom: ${sectionSpacing};
    ${bluePlanetTheme.breakpoints.down('lg')} {
      margin-bottom: ${contentSpacing};
    }
  }
`;

const SebMessage = styled(Card)`
  background-color: #1a1a1a;
  color: ${bluePlanetTheme.bluePlanetPalette.white};
`;

export default function OnYourAgenda({ messages }: { messages: DashboardMessageDTO[] }) {
  const { resource: userResource } = useSelfUserProfile();

  return (
    <Resources resources={[userResource]}>
      {([user]) => (
        <MessagesContainer>
          <MobileMargin>
            <CardGridWithLimit
              mobile={{ visibleCardsCount: 2, columnCount: 1 }}
              tablet={{ visibleCardsCount: 2, columnCount: 2 }}
              desktop={{ visibleCardsCount: 3, columnCount: 3 }}
              xl={{ visibleCardsCount: 3, columnCount: 3 }}
              xxl={{ visibleCardsCount: 3, columnCount: 3 }}
            >
              <CompanyMessages
                messages={messages}
                excludeMessages={['welcome-to-company-profile-as-new-company-admin']}
              />

              {messages.map(message => {
                switch (message.type) {
                  case 'welcome-to-seb-client-communities':
                    return (
                      <SebMessage color="indigo" key={message.type} style={{ gridColumn: 'span 2' }}>
                        <img
                          src="https://res.cloudinary.com/crowdworks/image/upload/f_auto,q_auto/v1/marketing/seb-logo-white-p"
                          height={50}
                          alt="SEB logo"
                        />
                        <p className="u-content-spacing-top text-large">
                          Welcome to the SEB client communities. Get ready to connect with industry experts, passionate
                          specialists and accelerate your personal & financial growth. More communities are opening
                          soon.
                        </p>
                      </SebMessage>
                    );
                  case 'you-are-pending-in-community': {
                    const community = message.community;
                    const isDnbCommunity = featureToggle.communityIsConnectedToDnb(community.id);

                    return (
                      <DashboardMessage
                        key={message.type}
                        color={{
                          background: isDnbCommunity ? '#285358' : bluePlanetTheme.bluePlanetPalette.white,
                          text: isDnbCommunity
                            ? bluePlanetTheme.bluePlanetPalette.white
                            : bluePlanetTheme.bluePlanetPalette.indigo.dark,
                        }}
                        topElement={<Logo size={70} type="community" url={community.logoURL}></Logo>}
                      >
                        <p className="text-weight-medium">You are pending access to {community.name}</p>
                        <p>
                          Please wait for the community manager to review your profile, you will be notified as soon as
                          you are approved.
                        </p>
                        <div style={{ marginTop: 'auto' }}>
                          <Link
                            className={isDnbCommunity ? 'text-link-light' : 'text-link'}
                            to={communityUrls.overview(community.slug)}
                          >
                            View community
                          </Link>
                        </div>
                      </DashboardMessage>
                    );
                  }
                  case 'you-are-new-in-community': {
                    const community = message.community;
                    const isDnbCommunity = featureToggle.communityIsConnectedToDnb(community.id);
                    const joinedAs =
                      message.role === 'company_admin_in_community'
                        ? (message.joinedAsCompany?.name ?? 'Your company') + ' has'
                        : 'You have';

                    return (
                      <DashboardMessage
                        key={message.type}
                        color={{
                          background: isDnbCommunity ? '#285358' : bluePlanetTheme.bluePlanetPalette.white,
                          text: isDnbCommunity
                            ? bluePlanetTheme.bluePlanetPalette.white
                            : bluePlanetTheme.bluePlanetPalette.indigo.dark,
                        }}
                        topElement={<Logo size={70} type="community" url={community.logoURL}></Logo>}
                      >
                        <p>
                          {joinedAs} recently joined{' '}
                          <Link
                            className={isDnbCommunity ? 'text-link-light' : 'text-link'}
                            to={communityUrls.overview(community.slug)}
                          >
                            {community.name}
                          </Link>
                        </p>
                      </DashboardMessage>
                    );
                  }

                  case 'pending-community-invites': {
                    return message.values.length > 0 ? (
                      <DashboardMessage
                        key={message.type}
                        topElement={<Logo size={70} type="community" url={message.values[0].community.logoURL}></Logo>}
                      >
                        You have been invited to join {message.values[0].community.name}
                        <div style={{ marginTop: 'auto' }}>
                          <Link
                            className="text-link"
                            to={communityUrls.join(message.values[0].community.slug, message.values[0].inviteCode)}
                          >
                            View invite
                          </Link>
                        </div>
                      </DashboardMessage>
                    ) : null;
                  }

                  default:
                    return <MentorCtaCard key={message.type} message={message} />;
                }
              })}

              {user.isInvestor && user.canBrowsePlatform && (
                <Link className="u-flex" to={userUrls.discover}>
                  <DashboardMessage
                    key="message-explore-pitches"
                    topElement={
                      <IconAvatar color="blue-light" size={{ width: '60px', height: '60px' }}>
                        <SearchIcon fontSize="large" color="indigo"></SearchIcon>
                      </IconAvatar>
                    }
                  >
                    <p>Explore pitches and create shortlists</p>
                  </DashboardMessage>
                </Link>
              )}
            </CardGridWithLimit>
          </MobileMargin>
        </MessagesContainer>
      )}
    </Resources>
  );
}
