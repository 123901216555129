import FormHelperText from '@mui/material/FormHelperText';
import ConfirmDialog from 'ui/modules/dialogs/ConfirmDialog';
import { FormikProps, withFormik } from 'formik';
import { DialogHandlerProps } from 'hooks/useDialogHandler';
import React from 'react';
import { TrustLevel } from 'types/company/company';
import TrustLevelPicker, { nameOfAudience } from './TrustLevelPicker';
import Button from 'ui/elements/buttons/Button';
import FormikTextField from 'ui/elements/form/formik/FormikTextField';
import { notEmpty, toggleItemInList } from 'util/arrayUtils';
import { getDerivedTrustLevels } from 'util/trustLevelUtils';
import * as yup from 'yup';
import { Info } from './Info';
import EyeIcon from 'ui/elements/icons/EyeIcon';

interface Props {
  dialogHandler: DialogHandlerProps;
  onSubmit: (folderName: string, trustLevels: TrustLevel[]) => void;
  parentAccesses?: { trustLevel: TrustLevel }[];
  existingAccesses?: { trustLevel: TrustLevel }[];
  trustLevels: TrustLevel[];
  folderName?: string;
  loading: boolean;
  type: 'create' | 'edit';
  companyId: number;
  isCommunity: boolean;
}

interface FormValues {
  folderName: string;
  trustlevels: TrustLevel[];
}

function getInitialTrustLevels(allTrustLevels: TrustLevel[], existingAccesses?: TrustLevel[], isCommunity?: boolean) {
  if (existingAccesses === undefined) {
    return allTrustLevels.filter(t => t === 'trusted').filter(notEmpty);
  } else {
    return isCommunity ? [existingAccesses[0]] : existingAccesses;
  }
}

function CreateFolderDialog(props: Props & FormikProps<FormValues>) {
  const selectableTrustLevels = props.trustLevels.filter(a => a !== 'board');
  const parentAccesses = props.parentAccesses ? props.parentAccesses?.map(a => a.trustLevel) : [];
  const shouldDisplayTrustLevelPicker =
    selectableTrustLevels.length > 1 && (!props.isCommunity || parentAccesses.length === 0);

  function getSelectedTrustLevels() {
    if (props.isCommunity) {
      return props.values.trustlevels;
    } else {
      return getDerivedTrustLevels(props.values.trustlevels)
        .concat(props.parentAccesses?.map(a => a.trustLevel) ?? [])
        .concat(props.values.trustlevels);
    }
  }

  function getDisabledTrustLevels() {
    if (props.isCommunity) {
      return [];
    }
    return getDerivedTrustLevels(props.values.trustlevels).concat(props.parentAccesses?.map(a => a.trustLevel) ?? []);
  }

  function handleSetTrustLevels(trustLevel: TrustLevel) {
    if (props.isCommunity) {
      props.setFieldValue('trustlevels', [trustLevel]);
    } else {
      props.setFieldValue('trustlevels', toggleItemInList(props.values.trustlevels, trustLevel));
    }
  }

  return (
    <ConfirmDialog
      title={props.type === 'create' ? 'Create folder' : 'Edit folder'}
      isOpen={props.dialogHandler.isOpen}
      onClose={props.dialogHandler.close}
      maxWidth="sm"
      dismissLabel="Cancel"
      confirmButton={
        <Button kind="primary" onClick={() => props.handleSubmit()} isLoading={props.loading}>
          {props.type === 'create' ? 'Create folder' : 'Save'}
        </Button>
      }
    >
      <div className="u-half-spacing-top">
        <FormikTextField
          formikProps={props}
          autoFocus
          name="folderName"
          placeholder="Folder name"
          label="Name"
          fullWidth
        />
      </div>
      {shouldDisplayTrustLevelPicker ? (
        <TrustLevelPicker
          isCommunity={props.isCommunity}
          companyId={props.companyId}
          trustLevels={selectableTrustLevels}
          selectedTrustLevels={getSelectedTrustLevels()}
          disabledTrustLevels={getDisabledTrustLevels()}
          onChange={handleSetTrustLevels}
        ></TrustLevelPicker>
      ) : parentAccesses.length > 0 ? (
        <div className="text-metadata u-flex u-flex-align-center">
          <EyeIcon className="u-half-spacing-right" />
          {nameOfAudience(parentAccesses[0], props.isCommunity)}
        </div>
      ) : null}

      {props.errors.trustlevels && props.touched.trustlevels && (
        <FormHelperText error>{props.errors.trustlevels}</FormHelperText>
      )}
      {selectableTrustLevels.length > 1 && (!props.isCommunity || parentAccesses.length < 1) && (
        <Info isCommunity={props.isCommunity} />
      )}
    </ConfirmDialog>
  );
}

export default withFormik<Props, FormValues>({
  mapPropsToValues: props => ({
    folderName: props.folderName || '',
    trustlevels: getInitialTrustLevels(
      props.trustLevels,
      props.existingAccesses?.map(a => a.trustLevel),
      props.isCommunity,
    ),
  }),
  handleSubmit: (values, { props }) => {
    props.onSubmit(values.folderName, values.trustlevels);
  },
  validateOnBlur: false,
  validationSchema: yup.object().shape({
    folderName: yup
      .string()
      .trim()
      .required('Folder name cannot be empty')
      .matches(
        /^[a-z0-9 \-_åøæ]{1,255}$/i,
        'Folder names can only contain alphanumerical characters, - (dashes) and _ (underscores)',
      ),
    trustlevels: yup.array().min(1, 'The folder must have at least one audience'),
  }),
})(CreateFolderDialog);
