import React from 'react';
import { SelfUserProfile } from 'types/user';
import { EventDTO } from 'ui/domain/Event/types';
import { bluePlanetTheme } from 'ui/theme';
import { contentSpacing, halfSpacing, sectionSpacing } from 'ui/theme/themeConstants';
import styled from '@emotion/styled';
import { countriesUsing12HourFormat } from 'util/dateUtils';
import { format, isSameDay } from 'date-fns';
import LocationIcon from 'ui/elements/icons/LocationIcon';
import CalendarIcon from 'ui/elements/icons/CalendarIcon';
import { ShareButton } from 'ui/domain/Event';
import * as R from 'ramda';
import { useMediaQuery } from '@mui/material';
import CtaButton from './CtaButton';
import { TinyCommunity } from 'types/company/community';

const InfoIconBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${halfSpacing};
  border: 2px solid ${bluePlanetTheme.bluePlanetPalette.grey.medium};
  border-radius: 8px;
`;

function getAddressParts(richLocation: EventDTO['richLocation']) {
  if (!richLocation) return ['', ''];
  const parts = R.split(',', richLocation.description);
  const firstPart = R.head(parts) || '';
  const restPart = R.tail(parts).join(',');
  return [firstPart, restPart];
}
function getDateParts(event: EventDTO, userProfile?: SelfUserProfile) {
  const uses12HourFormat =
    userProfile?.location?.country && countriesUsing12HourFormat.includes(userProfile.location.country);
  const timeFormat = uses12HourFormat ? 'hh:mm a' : 'HH:mm';
  const startDate = new Date(event.startsAt);
  const startTime = format(startDate, timeFormat);

  if (!event.endsAt) {
    return [format(startDate, 'MMMM do yyyy'), `from ${startTime}`];
  }
  const endDate = new Date(event.endsAt);
  const endTime = format(endDate, timeFormat);
  if (isSameDay(startDate, endDate)) {
    return [format(startDate, 'MMMM do yyyy'), `${startTime} - ${endTime}`];
  }

  return [`${format(startDate, 'MMMM do yyyy')} - ${startTime}`, `${format(endDate, 'MMMM do yyyy')} - ${endTime}`];
}

const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${sectionSpacing};

  ${bluePlanetTheme.breakpoints.down('lg')} {
    flex-direction: column;
    align-items: start;
    gap: ${contentSpacing};
  }
`;

const CtaButtonWrapper = styled.div`
  margin-left: auto;

  ${bluePlanetTheme.breakpoints.down('lg')} {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: ${halfSpacing};
    margin-left: 0;
  }
`;

export default function EventDetails({
  event,
  userProfile,
  adminMenu,
  showShareButton,
  community,
}: {
  event: EventDTO;
  userProfile?: SelfUserProfile;
  adminMenu?: React.ReactNode;
  showShareButton?: boolean;
  community?: TinyCommunity;
}) {
  const [firstAddressPart, secondAdressPart] = getAddressParts(event.richLocation);
  const [firstDatePart, secondDatePart] = getDateParts(event, userProfile);
  const isLgUp = useMediaQuery(bluePlanetTheme.breakpoints.up('lg'));

  return (
    <DetailsContainer>
      <div className="u-flex u-flex-align-center u-flex--gap-half">
        <InfoIconBox>
          <CalendarIcon color="grey" />
        </InfoIconBox>
        <div className="u-flex u-flex--column">
          <span className="text-small text-weight-medium text-grey">{firstDatePart}</span>
          <span className="text-small text-grey">{secondDatePart}</span>
        </div>
      </div>
      {event.richLocation && (
        <div className="u-flex u-flex-align-center u-flex--gap-half">
          <InfoIconBox>
            <LocationIcon color="grey" />
          </InfoIconBox>
          <div className="u-flex u-flex--column">
            <span className="text-small text-weight-medium text-grey">{firstAddressPart}</span>
            <span className="text-small text-grey">{secondAdressPart}</span>
          </div>
        </div>
      )}
      {event.ctaType && event.ctaURL && (
        <CtaButtonWrapper>
          <CtaButton event={event} community={community} />
          {!isLgUp && showShareButton && (
            <div className="u-flex" style={{ justifySelf: 'end' }}>
              <span>
                <ShareButton event={event} />
              </span>
              {adminMenu}
            </div>
          )}
        </CtaButtonWrapper>
      )}
    </DetailsContainer>
  );
}
