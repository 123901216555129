import FormikTextField from 'ui/elements/form/formik/FormikTextField';
import { FormHelperText } from '@mui/material';
import * as Yup from 'yup';
import { halfSpacing } from 'ui/theme/themeConstants';
import bluePlanetTheme from 'ui/theme/themeBluePlanet';
import { useFormik } from 'formik';
import Label from 'ui/elements/form/Label';
import { LabelDTO } from 'types/company';
import React from 'react';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import LabelChip from 'ui/domain/Chips/LabelChip';
import Button from 'ui/elements/buttons/Button';

const colors = [
  bluePlanetTheme.bluePlanetPalette.grey.medium,
  bluePlanetTheme.bluePlanetPalette.green.light,
  bluePlanetTheme.bluePlanetPalette.violet.light,
  bluePlanetTheme.bluePlanetPalette.blue.light,
  bluePlanetTheme.bluePlanetPalette.red.light,
  bluePlanetTheme.bluePlanetPalette.yellow.light,
  bluePlanetTheme.bluePlanetPalette.orange.light,
  bluePlanetTheme.bluePlanetPalette.grey.main,
  bluePlanetTheme.bluePlanetPalette.green.medium,
  bluePlanetTheme.bluePlanetPalette.violet.medium,
  bluePlanetTheme.bluePlanetPalette.blue.medium,
  bluePlanetTheme.bluePlanetPalette.red.medium,
  bluePlanetTheme.bluePlanetPalette.yellow.medium,
  bluePlanetTheme.bluePlanetPalette.orange.medium,
  bluePlanetTheme.bluePlanetPalette.grey.dark,
  bluePlanetTheme.bluePlanetPalette.green.dark,
  bluePlanetTheme.bluePlanetPalette.violet.dark,
  bluePlanetTheme.bluePlanetPalette.blue.dark,
  bluePlanetTheme.bluePlanetPalette.red.dark,
  bluePlanetTheme.bluePlanetPalette.yellow.dark,
  bluePlanetTheme.bluePlanetPalette.orange.dark,
] as string[];

interface LabelFormProps {
  label?: LabelDTO;
  api: {
    create: (name: string, color: string) => Promise<LabelDTO>;
    patch: (label: LabelDTO, name: string, color: string) => Promise<LabelDTO>;
  };
  onSubmitSuccess: () => void;
  onCancel: () => void;
  onDeleteRequest?: () => void;
  notify: (type: string, message: string) => void;
}

export default function LabelForm({ label, api, onSubmitSuccess, onCancel, onDeleteRequest, notify }: LabelFormProps) {
  const isEditMode = !!label;
  const formik = useFormik({
    initialValues: {
      name: label ? label.name : '',
      color: label ? label.color : '',
    },
    onSubmit: async values => {
      try {
        if (isEditMode && label) {
          await api.patch(label, values.name, values.color);
        } else {
          await api.create(values.name, values.color);
        }
        onSubmitSuccess();
      } catch (e) {
        notify('error', isEditMode ? 'Unable to update label' : 'Unable to create label');
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Please provide a name'),
      color: Yup.string().required('Please select a color'),
    }),
    validateOnBlur: false,
  });

  const isSaving = formik.isSubmitting;

  return (
    <>
      <Content>
        <FormikTextField formikProps={formik} autoFocus label="name" name="name" />
        <div className="u-content-spacing-top">
          <Label error={!!formik.errors.color}>Select a color</Label>
        </div>
        <div
          className="u-half-spacing-top"
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(7, 1fr)',
            gap: halfSpacing,
          }}
        >
          {colors.map(c => (
            <LabelChip
              key={c}
              label=""
              color={c}
              outline={c === formik.values.color}
              onClick={() => formik.setFieldValue('color', c)}
            />
          ))}
        </div>
        {formik.submitCount > 0 && formik.errors.color && <FormHelperText error>{formik.errors.color}</FormHelperText>}
      </Content>
      <DialogActions className="u-content-spacing-top">
        <Button isLoading={isSaving} onClick={() => formik.handleSubmit()} kind="primary">
          {isEditMode ? 'Save' : 'Create'}
        </Button>
        <Button onClick={onCancel} kind="tertiary">
          Cancel
        </Button>
        {isEditMode && onDeleteRequest && (
          <div className="u-flex u-flex-space-between u-flex-grow">
            <span />
            <Button isLoading={isSaving} onClick={onDeleteRequest} color="red" kind="secondary">
              Delete
            </Button>
          </div>
        )}
      </DialogActions>
    </>
  );
}
