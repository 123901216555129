import ButtonList from 'ui/elements/buttons/ButtonList';
import Button from 'ui/elements/buttons/Button';
import { FormControl, FormHelperText } from '@mui/material';
import Checkbox from 'ui/elements/form/choice/Checkbox';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import Wizard from 'ui/modules/wizards/Wizard';
import React from 'react';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import { CommunityInviteDetails, TinyCommunity } from 'types/company/community';
import { JoinCommunityAs } from 'apis/CompanyAPI/communities/communitiesApi';
import { IResource } from 'util/resource';

export const MastercardTerms = ({
  community,
  formik,
}: {
  community: TinyCommunity;
  formik: FormikProps<{ acceptedTerms: boolean; acceptedPhotos: boolean }>;
}) => {
  return (
    <>
      <p>
        You&apos;ve been invited to join <strong className="text-weight-bold">{community.name}</strong>. In order to
        join the community, you need to accept their terms and conditions.
      </p>
      <FormControl required error={!!formik.errors.acceptedTerms && !!formik.touched.acceptedTerms}>
        <Checkbox
          name="acceptedTerms"
          onChange={formik.handleChange}
          checked={!!formik.values.acceptedTerms}
          color="primary"
          fontSize="small"
          label={
            <span>
              I agree to Mastercard&apos;s{' '}
              <a
                className="text-link"
                href="https://www.mastercard.com/global/en/vision/who-we-are/terms-of-use.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms of use
              </a>{' '}
              and the&nbsp;
              <a
                href="https://www.mastercard.com/global/en/vision/corp-responsibility/commitment-to-privacy/privacy.html"
                className="text-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                privacy policy
              </a>
            </span>
          }
        />
        {formik.touched.acceptedTerms && formik.errors.acceptedTerms && (
          <FormHelperText>{formik.errors.acceptedTerms}</FormHelperText>
        )}
      </FormControl>
      <p>
        For the purpose of Mastercard Fintech Forum 2025 (“Event”), I agree that Mastercard International Inc. and its
        affiliates (“Mastercard”) may take photos and record videos featuring me as a participant, in accordance with
        the Media Terms. I authorise Mastercard to reproduce, display, publish, broadcast and distribute photographs and
        video recordings featuring me in any internal and external media now known or later developed for the purposes
        described above.
      </p>
      <FormControl required error={!!formik.errors.acceptedPhotos && !!formik.touched.acceptedPhotos}>
        <Checkbox
          name="acceptedPhotos"
          onChange={formik.handleChange}
          checked={!!formik.values.acceptedPhotos}
          color="primary"
          fontSize="small"
          label={<span>I declare to have read and to understand the above</span>}
        />
        {formik.touched.acceptedPhotos && formik.errors.acceptedPhotos && (
          <FormHelperText>{formik.errors.acceptedPhotos}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default function AcceptMastercardInvite({
  invite,
  onClose,
  requestToJoinCommunityAsUser,
  isRequesting,
}: {
  invite: CommunityInviteDetails;
  requestToJoinCommunityAsUser: (joinAs: JoinCommunityAs) => Promise<IResource<void>>;
  isRequesting: boolean;
  onClose: () => void;
}) {
  const formik = useFormik({
    initialValues: {
      acceptedTerms: false,
      acceptedPhotos: false,
    },
    validationSchema: Yup.object().shape({
      acceptedTerms: Yup.boolean().oneOf([true], 'You must accept the terms of use'),
      acceptedPhotos: Yup.boolean().oneOf([true], 'You must accept the photo policy'),
    }),
    onSubmit: () => {
      requestToJoinCommunityAsUser({ type: invite.invitedAs === 'Investor' ? 'Investor' : 'Advisor' }).then(() =>
        onClose(),
      );
    },
  });
  return (
    <Wizard
      completedStages={[]}
      stages={[
        {
          name: 'Join community',
          title: `Welcome to ${invite.community.name}`,
          hideStageInformation: true,
          content: () => (
            <>
              <Content>
                <MastercardTerms community={invite.community} formik={formik} />
              </Content>
              <DialogActions>
                <ButtonList>
                  <Button kind="primary" onClick={formik.submitForm} isLoading={isRequesting}>
                    Join
                  </Button>
                  <Button kind="tertiary" onClick={onClose}>
                    Not now
                  </Button>
                </ButtonList>
              </DialogActions>
            </>
          ),
        },
      ]}
    />
  );
}
