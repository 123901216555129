import cx from 'classnames';
import React from 'react';
import styles from './styles.scss';

interface Props {
  heading: string | JSX.Element;
  className?: string;
  margin?: 'normal' | 'none';
  actionsAlignment?: 'left' | 'right';
  children?: React.ReactNode;
  'data-intercom-target'?: string;
  id?: string;
}

export default function SectionHeadingLarge({
  heading,
  className,
  margin,
  actionsAlignment,
  children,
  ...rest
}: Props) {
  return (
    <h2
      className={cx(styles.wrapper, className, {
        'u-content-spacing-bottom': !margin || margin === 'normal',
      })}
      {...rest}
    >
      <div
        className={cx(styles.heading, 'text-weight-medium text-large text-font-text u-flex-align-center')}
        style={actionsAlignment === 'left' ? { flexGrow: 'unset' } : undefined}
      >
        {heading}
      </div>
      {children && <div className={styles.actions}>{children}</div>}
    </h2>
  );
}
