import { Location } from 'apis/MapboxAPI';
import { CalendarEvent } from 'calendar-link';
import { TinyOrganizationDTO } from 'types/organization';
import { UserProfile } from 'types/user';

export interface CompactEventDTO {
  id: UUID;
  title: string;
  content: string;
  startsAt: string;
  communityId?: number;
  organizationId?: UUID;
  createdAt: Date;
}

export interface EventUser {
  user: UserProfile;
  isInterested: boolean;
  hasCalendarActivity: boolean;
}

export interface TinyEventUserInfo {
  isInterested?: boolean;
  hasCalendarActivity?: boolean;
}

export interface EventHostDTO {
  id: string;
  name: string;
  slug: string;
  logoURL?: string;
  href: string;
}

export interface EventDTO {
  id: UUID;
}

export interface EventDTOWithoutId {
  startsAt: string;
  endsAt?: string;
  title: string;
  content: string;
  calendarContent?: string;
  communityId?: number;
  organizationId?: UUID;
  attachments: string[];
  organizations?: TinyOrganizationDTO[];
  richLocation?: Location;
  users: EventUser[];
  contactPerson?: UserProfile;
  ctaType?: EventCtaType;
  ctaURL?: string;
  isInternetPublic: boolean;
}

export interface EventDTO extends EventDTOWithoutId {
  id: UUID;
}

export const eventCtaTypes = ['LearnMore', 'GetTickets', 'SignUp', 'Register', 'RegisterToJoin', 'ApplyHere'] as const;

export const eventCtaTypeReadableMap: Record<EventCtaType, string> = {
  LearnMore: 'Learn More',
  GetTickets: 'Get Tickets',
  SignUp: 'Sign Up',
  Register: 'Register',
  RegisterToJoin: 'Register to join',
  ApplyHere: 'Apply Here',
};

export type EventCtaType = (typeof eventCtaTypes)[number];

export interface NewEventDTO {
  startsAt: string;
  endsAt?: string;
  title: string;
  content: string;
  calendarContent?: string;
  attachments: string[];
  organizations?: UUID[];
  richLocation?: Location;
  contactPerson: UUID;
  ctaType?: EventCtaType;
  ctaURL?: string;
  isInternetPublic: boolean;
}

export const getCalendarEvent = (event: EventDTO): CalendarEvent => ({
  title: event.title,
  description: event.calendarContent ?? event.content,
  start: event.startsAt,
  end: event.endsAt,
  allDay: !event.endsAt,
  location: event?.richLocation?.description,
});
