import React, { useState } from 'react';
import CardStack from 'ui/views/cards/CardStack';
import ClickableCard from 'ui/views/cards/ClickableCard';
import fuzzysearch from 'fuzzysearch';
import Button from 'ui/elements/buttons/Button';
import Logo from 'ui/domain/Logo';
import { communityUrls, userUrls } from 'urls';
import SectionHeadingLarge from 'ui/elements/SectionHeading/SectionHeadingLarge';
import Indicator from 'ui/elements/Indicator/NumberIndicator';
import { Link } from 'react-router-dom';
import ChevronLeftIcon from 'ui/elements/icons/ChevronLeftIcon';
import TextFilter from 'domain/shared/Filters/TextFilter';
import { useMediaQuery, useTheme } from '@mui/material';
import { MobileMargin } from 'ui/views/layouts/BaseLayout';
import { OrganizationListItemDTO } from 'types/organization';
import { OrganizationRoles } from './common/OrganizationRoles';
import { useBreakpoints } from 'hooks/useBreakpoints';

interface Props {
  organizations: OrganizationListItemDTO[];
}

const Title = React.memo(function Title({ c }: { c: OrganizationListItemDTO }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div
      style={{ width: '100%', flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? undefined : 'center' }}
      className="u-flex u-flex-space-between u-flex--wrap u-flex--gap-half"
    >
      <div>{c.name}</div>
      <OrganizationRoles isAdmin={c.isAdmin} displayAs={c.displayAs} />
    </div>
  );
});

export default function CommunityList({ organizations }: Props) {
  const [search, setSearch] = useState('');

  const filteredCommunities = !search
    ? organizations
    : organizations.filter(c => fuzzysearch(search.toLowerCase(), c.name.toLowerCase()));

  const limit = 12;
  const [showAll, setShowAll] = useState(false);

  const { isTabletDown } = useBreakpoints();

  return (
    <div>
      <div className={isTabletDown ? 'u-content-spacing-top' : ''}>
        <Link to={userUrls.dashboard.network.overview()} className={'text-link'}>
          <ChevronLeftIcon fontSize="small" /> Back
        </Link>
      </div>

      <MobileMargin>
        <SectionHeadingLarge
          className="u-content-spacing-top"
          heading={
            <>
              Your organizations{' '}
              <Indicator className="u-quarter-spacing-left" color="grey" shape="square" value={organizations.length} />
            </>
          }
        >
          <div>
            <TextFilter placeholder="Community name" setValue={setSearch} value={search} />
          </div>
        </SectionHeadingLarge>
      </MobileMargin>
      <CardStack className="u-flex u-flex--column u-flex-grow">
        {filteredCommunities.slice(0, showAll ? undefined : limit).map(c => (
          <ClickableCard
            href={communityUrls.overview(c.slug)}
            key={`organization-${c.id}`}
            avatar={<Logo size={40} url={c.logoURL} type={'community'} />}
            title={<Title c={c} />}
          />
        ))}
      </CardStack>
      {!showAll && filteredCommunities.length > limit && (
        <div className="u-half-spacing-top">
          <Button kind="tertiary" onClick={() => setShowAll(true)}>
            Show {filteredCommunities.length - limit} more
          </Button>
        </div>
      )}
    </div>
  );
}
