import MUIAvatar from '@mui/material/Avatar';
import React from 'react';
import { bluePlanetTheme } from 'ui/theme';
import { staticFileLocations } from 'urls';
import { resize } from 'util/cloudinary';
import { PROFILE_IMAGE_SIZE_SMALL } from 'util/constants';

interface Props {
  imageUrl?: string;
  userName?: string;
  className?: string;
  style?: React.CSSProperties;
  resize?: number;
  size?: number;
  borderColor?: 'white' | 'grey';
}

export default function Avatar(props: Props) {
  const resizeSize = props.resize || PROFILE_IMAGE_SIZE_SMALL;
  const [imageError, setImageError] = React.useState(false);
  const imageUrl = imageError || !props.imageUrl ? staticFileLocations.defaultProfileImage : props.imageUrl;

  return (
    <MUIAvatar
      src={resize(imageUrl, { width: resizeSize, height: resizeSize })}
      imgProps={{
        referrerPolicy: 'no-referrer',
        onError: () => {
          setImageError(true);
        },
      }}
      alt={props.userName}
      className={props.className}
      style={{
        width: props.size,
        height: props.size,
        backgroundColor: 'white',
        borderColor: props.borderColor === 'grey' ? bluePlanetTheme.bluePlanetPalette.grey.main : 'white',
        ...props.style,
      }}
    />
  );
}
