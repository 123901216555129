import { useState, useEffect, RefObject } from 'react';

function useIsElementVisible(
  containerRef: RefObject<HTMLElement>,
  threshold: number = 0.5,
  debounceMs: number = 100,
): boolean {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    const element = containerRef.current;
    if (!element) return;

    let debounceTimer: ReturnType<typeof setTimeout> | null = null;

    const observerCallback: IntersectionObserverCallback = entries => {
      entries.forEach(entry => {
        // Clear any previous timer and debounce the state update by 100ms
        if (debounceTimer) clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
          setIsVisible(entry.intersectionRatio >= threshold);
        }, debounceMs);
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: [threshold],
    });

    observer.observe(element);

    // Cleanup: disconnect the observer and clear the timer
    return () => {
      if (debounceTimer) clearTimeout(debounceTimer);
      observer.disconnect();
    };
  }, [containerRef, threshold]);

  return isVisible;
}

export default useIsElementVisible;
