export const unCapitalize = (str: string) =>
  typeof str === 'string' ? str.charAt(0).toLowerCase() + str.slice(1) : str;
export function capitalize<T>(str: T) {
  return typeof str === 'string' ? str.charAt(0).toUpperCase() + str.slice(1) : str;
}

export const pluralize = (count: number, single: string, plural?: string) =>
  count === 1 ? single : plural || single.concat('s');

export const addProtocolIfMissing = (value: string) => {
  if (!value) return '';
  const result = value.split('://');
  return result.length > 1 ? value : `http://${value}`;
};

export const removeProtocol = (value: string) => {
  if (!value) return '';
  const result = value.split('://');
  return result.length > 1 ? result[1] : value;
};

export const splitFilenameExtension = (value: string) => {
  if (!value) return { filename: undefined, extension: undefined };
  const index = value.lastIndexOf('.');
  if (index < 0) {
    return {
      filename: value,
      extension: undefined,
    };
  }
  const extensionIndex = index + 1;
  return {
    filename: value.substr(0, index),
    extension: extensionIndex < value.length ? value.substr(index + 1) : undefined,
  };
};

export const replaceAll = (target: string, search: string, replacement: string) => {
  if (target == null || target == undefined) {
    return target;
  }
  return target.split(search).join(replacement);
};

export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function emailIsValid(email: string) {
  return emailRegex.test(email);
}

const uuidRegex = '[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}';
export const validateInviteCode = (code: string) => {
  return RegExp(uuidRegex, 'i').test(code);
};

const URL_PATTERN =
  /(?:(?:https?|ftp):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;

export function insertLinksForUrls(text: string, className = '') {
  const urlPattern = new RegExp(URL_PATTERN);
  const result = text.replace(
    urlPattern,
    `<a class="text-link${className ? ` ${className}` : ''}" href="$&" target="_blank">$&</a>`,
  );

  return result.replace(new RegExp(/href="www./, 'g'), 'href="http://www.');
}

// Workaround for URL's in older comments. Newer comments are rich text, so links are already wrapped in <a> tags,
// whereas older comments are plain text so they need to be wrapped in <a> tags.
export function insertLinksForUrlsWithoutAnchorTags(text: string, className = '') {
  if (text.match(/<a[^>]*?href=[^\s]*.*?<\/a>/gim)) {
    return text;
  } else {
    return insertLinksForUrls(text, className);
  }
}

export function isValidUrl(text: string) {
  return !!text.match(new RegExp(URL_PATTERN));
}

const INTERNAL_DOMAINS = ['crowdworks.it', 'app.crowdworks.it', 'www.crowdworks.it', 'help.crowdworks.it'];
export function isInternalUrl(host: string, url: string) {
  const withoutProtocol = url.replace(/^https?:\/\//, '');
  const domain = decodeURIComponent(withoutProtocol).split('/')[0];
  return domain == host || INTERNAL_DOMAINS.includes(domain);
}

export function truncate(input: string | undefined, maxLength: number): string {
  return maxLength > 3 && input && input.length > maxLength ? input.slice(0, maxLength - 3) + '...' : (input ?? '');
}

export function addPossessive(value: string) {
  if (value.endsWith("'s") || value.endsWith("'")) return value;

  return value.endsWith('s') ? `${value}'` : `${value}'s`;
}

export function isReadableText(text?: string) {
  if (!text) return false;
  const wordCount = text.trim().split(/\s+/).length;

  const strictSymbolsPattern = /[@#%$&*^|~<>[\]{}+\/\\=`;`\n\t]/g;
  const lenientPattern = /[^a-zA-Z0-9 .,?!\s]/g;

  const strictSymbolsCount = (text.match(strictSymbolsPattern) || []).length;
  const nonCommonTextCharCount = (text.match(lenientPattern) || []).length;

  const acceptableSpecialCharRatio = nonCommonTextCharCount < wordCount && strictSymbolsCount < Math.sqrt(wordCount);

  return acceptableSpecialCharRatio;
}

export function camelCaseToSentence(value?: string): string {
  if (!value) {
    return '';
  }
  const sentence = value
    // Insert a space before each uppercase letter (for camelCase)
    .replace(/([A-Z])/g, ' $1')
    // Insert a space between a letter and a following digit
    .replace(/([a-zA-Z])(\d+)/g, '$1 $2')
    // Insert a space between a digit and a following letter
    .replace(/(\d+)([a-zA-Z])/g, '$1 $2')
    .toLowerCase()
    .trim();

  // Capitalize the first letter of the resulting sentence
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
}
