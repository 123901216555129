import React, { useEffect } from 'react';
import Resource from 'util/resource/Resource';
import ShortlistAccordion from './ShortlistAccordion';
import useDialogHandler from 'hooks/useDialogHandler';
import { ShortlistCreateDialog } from './ShortlistDialog';
import { useShortlists } from 'apis/CompanyAPI/users/useShortlists';
import useLazyResource from 'util/resource/useLazyResource';
import { usersApi } from 'apis/CompanyAPI/users/usersApi';
import useNotify from 'hooks/useNotify';
import { getOrElse, getOrUndefined } from 'util/resource';
import PageTitle from 'ui/views/layouts/BaseLayout/PageTitle';
import Pulse from 'pages/Dashboard/sections/pulse/Pulse';
import MaxWidth from 'ui/views/containers/MaxWidth';
import BookmarkIcon from 'ui/elements/icons/BookmarkIcon';
import InfoMessage from 'ui/views/messages/InfoMessage';
import Button from 'ui/elements/buttons/Button';
import { scrollToTop } from 'util/scroll';

const DEFAULT_SHORTLIST_NAME = 'My watchlist';

export default function Shortlists() {
  const { resource: shortlistResource, mutate: setShortlistResource } = useShortlists();
  const createListDialogHandler = useDialogHandler(false);
  const notify = useNotify();

  const [createList, isCreatingList] = useLazyResource(
    (payload: { listName?: string; description?: string }) =>
      usersApi.shortlists.create(payload.listName, payload.description),
    {
      onSuccess: newShortlist => {
        setShortlistResource(
          { values: [newShortlist, ...getOrElse(shortlistResource, { values: [] }).values] },
          { revalidate: false },
        );
        createListDialogHandler.close();
        notify('success', 'List created');
      },
      onFailure: message => notify('error', message ?? 'Could not save the list'),
    },
  );

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <PageTitle title="Collections" />
      <MaxWidth width="md">
        <Resource resource={shortlistResource} renderLoading={() => <></>}>
          {shortlists =>
            // If the user has created a shortlist already, or has renamed their default one, then don't display the message
            shortlists.values.length === 1 && shortlists.values[0].name === DEFAULT_SHORTLIST_NAME ? (
              <InfoMessage
                className="u-content-spacing-bottom"
                icon={<BookmarkIcon />}
                confirmButton={
                  <Button kind="primary" onClick={() => createListDialogHandler.open()}>
                    Create a collection
                  </Button>
                }
                dismissKey="create-collection"
                dismissButton={
                  <Button kind="tertiary" color="grey">
                    Dismiss
                  </Button>
                }
                dismissOnConfirm
                color="blue-light"
                layout="column"
              >
                Create your own shortlists and collections. Save companies you find interesting in order to keep tabs on
                their progress, to easily discuss and share them with co-workers, and contact them in the future.
              </InfoMessage>
            ) : null
          }
        </Resource>
        <div className="u-align-right u-half-spacing-bottom">
          <Button kind="tertiary" onClick={() => createListDialogHandler.open()}>
            <BookmarkIcon fontSize="small" className="u-quarter-spacing-right" />
            Create new list
          </Button>
        </div>
      </MaxWidth>
      <Resource resource={shortlistResource}>
        {shortlists =>
          shortlists.values.map(sl => (
            <ShortlistAccordion
              key={sl.id}
              shortlist={sl}
              setShortlists={setShortlistResource}
              isExpanded={shortlists.values.length === 1}
            />
          ))
        }
      </Resource>
      {createListDialogHandler.isOpen && (
        <ShortlistCreateDialog
          dialogHandler={createListDialogHandler}
          onSubmit={(listName, description) => createList({ listName, description })}
          isSaving={isCreatingList}
        />
      )}
      {(getOrUndefined(shortlistResource)?.values ?? []).length > 0 && (
        <div className="u-section-spacing-top" style={{ maxWidth: '800px' }}>
          <Pulse activityTypes={['From my collections']} />
        </div>
      )}
    </>
  );
}
