import React, { useState } from 'react';
import { LabelDTO } from 'types/company';
import { communitiesApi } from 'apis/CompanyAPI/communities/communitiesApi';
import LabelsDialog from 'domain/shared/LabelsDialog';
import { useCommunityLabels } from 'apis/CompanyAPI/communities/useCommunityLabels';
import { getOrUndefined } from 'util/resource';
import { notEmpty } from 'util/arrayUtils';
import { pluralize } from 'util/stringUtils';
import useLazyResource from 'util/resource/useLazyResource';
import { put } from 'apis/ApiBase';
import useNotify from 'hooks/useNotify';

interface Props {
  labels?: LabelDTO[];
  companies: number[];
  companyName?: string;
  onClose: () => void;
  communitySlug: string;
  communityId: number;
  refresh: (labels: LabelDTO[]) => void;
}

export default function CommunityLabelsDialog(props: Props) {
  const { companies, companyName, labels, refresh, onClose, communityId } = props;

  const notify = useNotify();

  const { resource: labelsResource, mutate: mutateLabels } = useCommunityLabels(props.communitySlug);
  const allLabels = getOrUndefined(labelsResource)?.values ?? [];
  const [activeLabels, setActiveLabels] = useState(props.labels ?? []);

  const [save] = useLazyResource(
    ({
      companies,
      selectedLabels,
      deselectedLabels,
    }: {
      companies: number[];
      selectedLabels: UUID[];
      deselectedLabels: UUID[];
    }) =>
      put(communitiesApi.communityMemberLabels.update(communityId), {
        companies,
        selectedLabels,
        deselectedLabels,
      }),
    {
      onSuccess: (_, { selectedLabels, deselectedLabels }) => {
        setActiveLabels(
          [
            ...activeLabels.filter(label => !deselectedLabels.includes(label.id)),
            ...selectedLabels.map(id => allLabels.find(label => label.id === id)).filter(notEmpty),
          ].sort((a, b) => a.name.localeCompare(b.name)),
        );
        notify('success', 'Labels updated');
        refresh(activeLabels);
        onClose();
      },
      onFailure: () => notify('error', `Unable to update group assignments`),
    },
  );

  return (
    <LabelsDialog
      labelsResource={labelsResource}
      initialLabels={labels}
      mutateLabels={mutateLabels}
      title="Manage labels"
      onClose={props.onClose}
      api={{
        create: (name: string, color: string) =>
          communitiesApi.communityLabels.add(props.communitySlug, { name, color }),
        patch: (label: LabelDTO, name: string, color: string) =>
          communitiesApi.communityLabels.update(props.communitySlug, label.id, { name, color }),
        delete: (label: LabelDTO) => communitiesApi.communityLabels.remove(props.communitySlug, label.id, true),
      }}
      entities={companies}
      entityName={companyName ?? pluralize(companies.length, 'company', 'companies')}
      update={(companies: number[], selectedLabels, deselectedLabels) =>
        save({ companies, selectedLabels, deselectedLabels })
      }
    />
  );
}
