import styled from '@emotion/styled';
import classNames from 'classnames';
import useWindowWidth from 'hooks/useWindowWidth';
import React, { useRef, useState } from 'react';
import IndustryChip from 'ui/domain/Chips/IndustryChip';
import StageChip from 'ui/domain/Chips/StageChip';
import HorizontalDictionary from 'ui/elements/dictionary/HorizontalDictionary';
import { bluePlanetTheme } from 'ui/theme';
import Card from 'ui/views/cards/Card';
import { addProtocolIfMissing, removeProtocol } from 'util/stringUtils';
import { formatShortLocation } from 'util/locationUtils';
import { CompanyProfile } from 'types/company';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { validWebsiteRegex } from 'util/validation';
import { MISSION_MAX_LENGTH } from 'util/constants';
import { useUpdateCompany } from 'apis/CompanyAPI/companies/useUpdateCompany';
import InlineEditableField from 'ui/elements/form/InlineEditableField';
import TextField from 'ui/elements/form/TextField';
import i18n from 'util/i18n';
import LocationInput from 'domain/shared/Location/LocationInput';
import Select from 'ui/elements/form/Select/Select';
import { useFundingStages } from 'apis/CompanyAPI/labels/useFundingStages';
import { getOrUndefined } from 'util/resource';
import IndustryPicker from 'domain/labels/industries/IndustryPicker';
import StagePicker from 'domain/labels/industries/StagePicker';
import ChipList from 'ui/elements/Chip/ChipList';
import TruncatedList from 'util/components/TruncatedList';
import ClampedText from 'ui/elements/text/ClampedText';
import LinkButton from 'ui/elements/buttons/LinkButton';
import EditAction from 'ui/elements/actions/EditAction';
import DetailsUpdateDialog from './DetailsUpdateDialog';
import { scrollToTop } from 'util/scroll';
import AiMenu from 'ui/domain/AiMenu/AiMenu';
import { useAiOnboardingSessionStorage } from 'domain/onboarding/company/Wizard/AIOnboarding';
import AIChip from './AiChip';

type Props = {
  companyProfile: CompanyProfile;
  onEdit: () => void;
  canEditCompany: boolean;
};

export const LogoHeader = styled.h1`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
`;

export default function CompanyDetails({ companyProfile: company, onEdit, canEditCompany }: Props) {
  const width = useWindowWidth();
  const isSmallScreen = width < bluePlanetTheme.breakpoints.values.md;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const closeDialog = () => {
    setIsDialogOpen(false);
    scrollToTop();
  };

  const [updateCompany] = useUpdateCompany(company.id, company.slug, {
    onSuccess: onEdit,
  });

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      website: company.website,
    },
    validationSchema: Yup.object().shape({
      website: Yup.string()
        .notRequired()
        .matches(validWebsiteRegex, 'Website should be a valid url, e.g. https://example.com')
        .nullable(),
    }),
    onSubmit: values => {
      updateCompany({
        company: values,
      });
    },
  });

  const dictInputWidth = width < bluePlanetTheme.breakpoints.values.md ? '100%' : '260px';
  const { resource: fundingStageOptionsResource } = useFundingStages();
  const fundingStageOptions =
    getOrUndefined(fundingStageOptionsResource)?.values?.map(s => ({ ...s, value: s.id })) ?? [];

  const cardRef = useRef<HTMLDivElement>(null);
  // We want to make sure that the industries picker is not wider than 500px and not wider than the card
  const industriesMinWidth = Math.min(cardRef.current ? cardRef.current.clientWidth - 40 : 240, 500);
  const aiRef = useRef<HTMLDivElement>(null);

  const [isAiOnboarding] = useAiOnboardingSessionStorage();

  return (
    <div>
      {isAiOnboarding && <AIChip className="u-half-spacing-bottom" onClick={() => setIsDialogOpen(true)} />}
      <Card data-intercom-target="company-overview-header">
        <div className="u-flex u-flex-space-between">
          <ChipList>
            {canEditCompany ? (
              <div style={{ display: 'inline-block' }} className="u-quarter-spacing-right">
                <InlineEditableField
                  buttonStyle={{ minWidth: '140px' }}
                  label="Add industries"
                  defaultValue={company.industries.values}
                  onConfirm={value =>
                    updateCompany({
                      company: {},
                      labels: {
                        industries: value,
                        productStage: company.stages.values[0],
                        fundingStage: company.fundingStage,
                      },
                    })
                  }
                  renderInputComponent={(onChange, value) => (
                    <IndustryPicker
                      autoFocus
                      style={{ minWidth: industriesMinWidth }}
                      selectedIndustries={value}
                      setIndustries={onChange}
                    />
                  )}
                  valueComponent={
                    <ChipList>
                      <TruncatedList
                        values={company.industries.values}
                        limit={3}
                        renderItem={label => <IndustryChip label={label.name} />}
                        renderExpandButton={(label, onClick) => <IndustryChip onClick={onClick} label={label.name} />}
                      />
                    </ChipList>
                  }
                />
              </div>
            ) : (
              <ChipList>
                <TruncatedList
                  values={company.industries.values}
                  limit={3}
                  renderItem={label => <IndustryChip label={label.name} />}
                  renderExpandButton={(label, onClick) => <IndustryChip onClick={onClick} label={label.name} />}
                />
              </ChipList>
            )}
            {canEditCompany ? (
              <div style={{ display: 'inline-block', minWidth: '140px' }}>
                <InlineEditableField
                  label="Add stage"
                  defaultValue={company.stages.values}
                  onConfirm={value =>
                    updateCompany({
                      company: {},
                      labels: {
                        industries: company.industries.values,
                        productStage: value[0],
                        fundingStage: company.fundingStage,
                      },
                    })
                  }
                  renderInputComponent={(onChange, value) => (
                    <StagePicker
                      autoFocus
                      productStage={value[0]}
                      style={{ minWidth: '200px' }}
                      industries={company.industries.values}
                      onChange={selectedValue => onChange(selectedValue ? [selectedValue] : [])}
                    />
                  )}
                  valueComponent={
                    <>
                      {company.stages.values.map(stage => (
                        <StageChip
                          key={stage.name}
                          label={stage.name}
                          className={classNames('u-quarter-spacing-right', 'u-quarter-spacing-bottom')}
                        />
                      ))}
                    </>
                  }
                />
              </div>
            ) : (
              company.stages.values.map(stage => (
                <StageChip
                  key={stage.name}
                  label={stage.name}
                  className={classNames('u-quarter-spacing-right', 'u-quarter-spacing-bottom')}
                />
              ))
            )}
          </ChipList>
          {canEditCompany && (
            <LinkButton onClick={() => setIsDialogOpen(true)} aria-label="Edit details">
              <EditAction tooltip="Edit details" />
            </LinkButton>
          )}
        </div>
        {canEditCompany ? (
          <div className="u-half-spacing-y">
            <InlineEditableField
              label="Add your mission statement"
              explanation="This is the first impression investors get of your company. Communicate your vision clearly - be concise, compelling, and inspiring"
              defaultValue={company.mission}
              buttonStyle={{ height: '100px' }}
              onConfirm={value => updateCompany({ company: { mission: value } })}
              disableEnterKey
              renderInputComponent={(onChange, value) => (
                <div className="u-flex u-flex--column u-flex-grow">
                  <TextField
                    inputProps={{ style: { lineHeight: '25px' } }}
                    autoFocus
                    maxLength={MISSION_MAX_LENGTH}
                    multiline
                    minRows={4}
                    maxRows={20}
                    suffixPosition="start-bottom"
                    value={value}
                    suffix={
                      <AiMenu
                        companyId={company.id}
                        organizationId={undefined}
                        input={value || ''}
                        maxLength={700}
                        onReplace={v => {
                          onChange(v);
                        }}
                        aiRef={aiRef}
                      />
                    }
                    defaultValue={company.mission}
                    onChange={e => {
                      onChange(e.target.value);
                    }}
                  />
                  <div ref={aiRef} />
                </div>
              )}
              valueComponent={
                <ClampedText
                  numLines={5}
                  style={{ minHeight: company.mission ? undefined : '100px' }}
                  className="u-half-spacing-y text-body"
                >
                  {company.mission ?? ''}
                </ClampedText>
              }
            />
          </div>
        ) : (
          <ClampedText numLines={5} className="u-half-spacing-y text-body">
            {company.mission ?? ''}
          </ClampedText>
        )}
        {canEditCompany ? (
          <div>
            <InlineEditableField
              label="Add website"
              buttonStyle={{ width: '220px' }}
              error={formik.errors.website}
              defaultValue={company.website ?? ''}
              onConfirm={value => {
                formik.setFieldValue('website', value);
                return formik.validateField('website').then(() => {
                  formik.submitForm();
                });
              }}
              renderInputComponent={(onChange, value) => (
                <TextField
                  autoFocus
                  value={value}
                  name="website"
                  style={{ minWidth: '320px', maxWidth: '500px' }}
                  error={!!formik.errors.website}
                  placeholder={i18n('en').placeholders.website}
                  onBlur={formik.handleBlur}
                  onChange={e => {
                    formik.setFieldValue('website', e.target.value, false);
                    onChange(e.target.value);
                  }}
                />
              )}
              valueComponent={company.website && <span className="text-link ">{removeProtocol(company.website)}</span>}
            />
          </div>
        ) : (
          company.website && (
            <a
              href={addProtocolIfMissing(company.website)}
              target="_blank"
              rel="noreferrer noopener"
              className="text-link"
            >
              {removeProtocol(company.website)}
            </a>
          )
        )}
        <HorizontalDictionary columnCount={isSmallScreen ? 2 : 4} className="u-content-spacing-top">
          {[
            {
              key: 'Established',
              value: canEditCompany ? (
                <InlineEditableField
                  label="Add year"
                  defaultValue={company.established ?? ''}
                  onConfirm={value =>
                    updateCompany({
                      company: { established: value },
                    })
                  }
                  renderInputComponent={(onChange, value) => (
                    <TextField
                      autoFocus
                      placeholder='e.g. "2015"'
                      type="number"
                      value={value}
                      onChange={e => {
                        onChange(e.target.value);
                      }}
                    />
                  )}
                  valueComponent={<>{company.established || '-'}</>}
                />
              ) : (
                company.established || '-'
              ),
            },
            {
              key: 'Location',

              value: canEditCompany ? (
                <InlineEditableField<CompanyProfile['location']>
                  label="Add location"
                  defaultValue={company.location}
                  onConfirm={value =>
                    updateCompany({
                      company: { location: value },
                    })
                  }
                  inputWrapperStyle={{ width: dictInputWidth }}
                  renderInputComponent={onChange => (
                    <LocationInput
                      style={{ width: '100%' }}
                      name="location"
                      searchArea="places"
                      placeholder="Search for location"
                      autoComplete="address-level2"
                      autoFocus
                      onChange={v => onChange(v || undefined)}
                    />
                  )}
                  valueComponent={<>{company.location && formatShortLocation(company.location)}</>}
                />
              ) : (
                <>{company.location && formatShortLocation(company.location)}</>
              ),
            },
            {
              key: 'Funding stage',
              value: canEditCompany ? (
                <InlineEditableField
                  label="Add funding stage"
                  defaultValue={company.fundingStage}
                  onConfirm={value =>
                    updateCompany({
                      company: {},
                      labels: {
                        productStage: company.stages.values[0],
                        fundingStage: value,
                        industries: company.industries.values,
                      },
                    })
                  }
                  renderInputComponent={(onChange, value) => (
                    <Select
                      style={{ width: '100%' }}
                      autoFocus
                      name="fundingStage"
                      value={value}
                      placeholder="Select current funding stage"
                      getOptionLabel={i => i.name}
                      options={fundingStageOptions}
                      onChange={onChange}
                    />
                  )}
                  valueComponent={<>{company.fundingStage?.name ?? '-'}</>}
                />
              ) : (
                (company.fundingStage?.name ?? '-')
              ),
            },
            {
              key: 'Employees',
              value: canEditCompany ? (
                <InlineEditableField
                  label="Add employees"
                  defaultValue={company.employees ? `${company.employees}` : ''}
                  onConfirm={value => {
                    const employees = parseInt(value, 10);
                    if (!isNaN(employees)) {
                      return updateCompany({ company: { employees } });
                    } else {
                      return Promise.resolve();
                    }
                  }}
                  renderInputComponent={(onChange, value) => (
                    <TextField
                      autoFocus
                      type="number"
                      value={value}
                      onChange={e => {
                        onChange(e.target.value);
                      }}
                    />
                  )}
                  valueComponent={<>{company.employees ?? '-'}</>}
                />
              ) : (
                <>{company.employees ?? '-'}</>
              ),
            },
          ]}
        </HorizontalDictionary>
      </Card>
      {isDialogOpen && (
        <DetailsUpdateDialog
          company={company}
          industries={company.industries}
          productStages={company.stages}
          onEdit={onEdit}
          onClose={closeDialog}
        />
      )}
    </div>
  );
}
