import useSWR, { invalidate } from 'hooks/useSWR';
import { useSWRResource } from 'util/resource/useSWRResource';
import { communityAPIUrls } from './communitiesApi';
import { CommunityListItem, CommunityLisItemDetailed as CommunityListItemDetailed } from 'types/company/community';

export const invalidateCommunities = () => {
  invalidate(communityAPIUrls.list());
  invalidate(communityAPIUrls.listDetailed());
};

export const useCommunities = () => {
  const swrResult = useSWR<{ values: CommunityListItem[] }>(communityAPIUrls.list());
  return useSWRResource(swrResult);
};

export const useCommunitiesDetailed = () => {
  const swrResult = useSWR<{ values: CommunityListItemDetailed[] }>(communityAPIUrls.listDetailed());
  return useSWRResource(swrResult);
};
