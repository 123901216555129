import { useShortlists } from 'apis/CompanyAPI/users/useShortlists';
import CompanyShortlistPicker from 'domain/Shortlists/CompanyShortlistPicker';
import ShareCompany from 'domain/companies/profile/ShareCompany';
import CompanyConversationDialogAsUser from 'domain/conversations/CompanyConversationDialogAsUser';
import { trackConversationOpened } from 'domain/conversations/actions';
import useDialogHandler from 'hooks/useDialogHandler';
import useJourneyLogger from 'hooks/useJourneyLogger';
import useWindowWidth from 'hooks/useWindowWidth';
import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { CompanyProfile, FundingRound } from 'types/company';
import Calendly from 'ui/domain/Calendly';
import { BottomMobileNavigation, NavigationItem, NavigationRow } from 'ui/elements/BottomNavigation';
import DrawerBottom, { DrawerItem } from 'ui/elements/DrawerBottom';
import AddIcon from 'ui/elements/icons/AddIcon';
import BookmarkActiveIcon from 'ui/elements/icons/BookmarkActiveIcon';
import BookmarkIcon from 'ui/elements/icons/BookmarkIcon';
import CalendarIcon from 'ui/elements/icons/CalendarIcon';
import ChatBubblesIcon from 'ui/elements/icons/ChatBubblesIcon';
import CloseIcon from 'ui/elements/icons/CloseIcon';
import EllipsisIcon from 'ui/elements/icons/Ellipsis';
import EyeIcon from 'ui/elements/icons/EyeIcon';
import InviteUserIcon from 'ui/elements/icons/InviteUserIcon';
import SettingsIcon from 'ui/elements/icons/SettingsIcon';
import TrendingUpIcon from 'ui/elements/icons/TrendingUpIcon';
import { companyUrls } from 'urls';
import { IResource, getOrElse, getOrUndefined } from 'util/resource';
import Resource from 'util/resource/Resource';
import { CompanyViewAs, companyViewAsOptions } from 'domain/companies/roleUtils';
import { unCapitalize } from 'util/stringUtils';
import CalendarSetupDialog from 'pages/Company/Overview/sidebar/CalendarSetupDialog';
import EditFundingRound from 'domain/companies/FundingRoundWidget/EditFundingRound';
import PitchVisibility from 'domain/companies/profile/PitchVisibility';

export default function CompanyMobileNavigation({
  viewAs,
  companyProfile,
  fundingRoundResource,
  setViewAs,
  reloadFundingRound,
  canEditCompany,
}: {
  viewAs: CompanyViewAs;
  companyProfile: CompanyProfile;
  fundingRoundResource: IResource<FundingRound>;
  setViewAs: (viewAs: CompanyViewAs) => void;
  reloadFundingRound: () => void;
  canEditCompany: boolean;
}) {
  const [log] = useJourneyLogger();
  const match = useRouteMatch<{ communitySlug?: string }>();
  const [isCompanyConversationOpen, setIsCompanyConversationOpen] = useState(false);

  const { resource: shortlistResource, mutate: setShortlistResource } = useShortlists();
  const shortlists = getOrElse(shortlistResource, { values: [] });
  const shortlistsWithCompany = shortlists.values.filter(sl => sl.companies.map(c => c.id).includes(companyProfile.id));
  const history = useHistory();

  const params = new URLSearchParams(history.location.search);
  const editFundingRoundDialogHandler = useDialogHandler(params.has('boost-funding-round'));
  const [isCalendarDialogOpen, setIsCalendarDialogOpen] = useState(false);
  const pitchVisibilityDialogHandler = useDialogHandler();
  const fundingRound = getOrUndefined(fundingRoundResource);

  const width = useWindowWidth();

  const [showViewAs, setShowViewAs] = useState(false);
  const [showMoreTools, setShowMoreTools] = useState(false);

  const onOpenChat = () => {
    log(viewAs === 'Visitor' ? 'opened contact company' : 'open give feedback to company');
    setIsCompanyConversationOpen(true);
    trackConversationOpened({
      withCompanyId: companyProfile.id,
      communitySlug: match.params.communitySlug,
      key: 'company-profile-give-feedback',
      path: match.path,
    });
  };

  if (viewAs === 'Admin') {
    return (
      <>
        <BottomMobileNavigation>
          <NavigationRow>
            <ShareCompany company={companyProfile} canEditCompany={true} />
            <NavigationItem
              onClick={() => {
                history.push(companyUrls.overview(companyProfile.slug, 'updates', { isPostingUpdate: true }));
              }}
            >
              <AddIcon fontSize="small" />
              <span>Post</span>
            </NavigationItem>
            <NavigationItem
              onClick={() => {
                history.push(companyUrls.settings.inviteUser(companyProfile.slug));
              }}
            >
              <InviteUserIcon fontSize="small" />
              Invite
            </NavigationItem>
            <NavigationItem onClick={() => setShowViewAs(true)}>
              <EyeIcon />
              View as
            </NavigationItem>
            <NavigationItem onClick={() => setShowMoreTools(true)}>
              <EllipsisIcon />
            </NavigationItem>
          </NavigationRow>
        </BottomMobileNavigation>
        <DrawerBottom onClose={() => setShowViewAs(false)} isOpen={showViewAs}>
          {companyViewAsOptions.map(viewAsOption => (
            <DrawerItem
              suffix={viewAs === viewAsOption ? <EyeIcon /> : undefined}
              key={viewAsOption}
              onClick={() => {
                setViewAs(viewAsOption);
                setShowViewAs(false);
              }}
            >
              {viewAsOption}
            </DrawerItem>
          ))}
        </DrawerBottom>
        <DrawerBottom onClose={() => setShowMoreTools(false)} isOpen={showMoreTools}>
          <DrawerItem
            prefix={<CalendarIcon />}
            onClick={() => {
              setShowMoreTools(false);
              setIsCalendarDialogOpen(true);
            }}
          >
            {companyProfile.calendlyUrl ? 'Manage calendar setup' : 'Set up your calendar'}
          </DrawerItem>
          <DrawerItem
            prefix={<TrendingUpIcon />}
            onClick={() => {
              setShowMoreTools(false);
              editFundingRoundDialogHandler.open();
            }}
          >
            {fundingRound ? 'Edit funding round' : 'Start funding round'}
          </DrawerItem>
          <DrawerItem
            prefix={<SettingsIcon />}
            onClick={() => {
              setShowMoreTools(false);
              pitchVisibilityDialogHandler.open();
            }}
          >
            {companyProfile.hasPublicPitch ? 'Manage company visibility' : 'Make company visible'}
          </DrawerItem>
        </DrawerBottom>
        <CalendarSetupDialog
          companyId={companyProfile.id}
          companySlug={companyProfile.slug}
          isOpen={isCalendarDialogOpen}
          onClose={() => setIsCalendarDialogOpen(false)}
          calendlyUrl={companyProfile.calendlyUrl}
        />
        {editFundingRoundDialogHandler.isOpen && (
          <EditFundingRound
            companyId={companyProfile.id}
            companySlug={companyProfile.slug}
            onClose={editFundingRoundDialogHandler.close}
            fundingStage={companyProfile.fundingStage}
            fundingRound={fundingRound?.visibility === 'full' ? fundingRound : undefined}
            onSave={reloadFundingRound}
            userWantsToBoostFundingRound={params.has('boost-funding-round')}
          />
        )}
        <PitchVisibility
          dialogHandler={pitchVisibilityDialogHandler}
          companyId={companyProfile.id}
          companySlug={companyProfile.slug}
          behaviourOnEditPitch="closeDialog"
          hasPublicPitch={companyProfile.hasPublicPitch}
        />
      </>
    );
  }

  return (
    <BottomMobileNavigation>
      <>
        {canEditCompany && (
          <div className="u-flex u-flex-space-between" style={{ backgroundColor: '#0f1f42', color: 'white' }}>
            <NavigationItem color="indigo" onClick={() => setShowViewAs(true)} style={{ flexGrow: 1 }}>
              Viewing as {unCapitalize(viewAs)}
            </NavigationItem>
            <NavigationItem color="indigo" onClick={() => setViewAs('Admin')} style={{ width: '100px' }}>
              <CloseIcon color="white" />
            </NavigationItem>
          </div>
        )}
        <NavigationRow>
          {companyProfile.calendlyUrl && (
            <Calendly calendlyUrl={companyProfile.calendlyUrl}>
              {onOpen => (
                <NavigationItem onClick={onOpen}>
                  <CalendarIcon color="indigo" />
                  Meet
                </NavigationItem>
              )}
            </Calendly>
          )}
          <ShareCompany company={companyProfile} canEditCompany={false} />
          <Resource
            resource={shortlistResource}
            renderLoading={() => (
              <NavigationItem>
                <BookmarkIcon />
              </NavigationItem>
            )}
          >
            {shortlists => (
              <CompanyShortlistPicker
                company={companyProfile}
                shortlists={shortlists.values}
                dropdownWidth={`${width}px`}
                setShortlists={setShortlistResource}
                dropdownButton={onClick => (
                  <NavigationItem onClick={onClick}>
                    {shortlistsWithCompany.length > 0 ? (
                      <BookmarkActiveIcon
                        color={shortlistsWithCompany.length > 0 ? 'blue' : 'indigo'}
                        fillColor="blue"
                      />
                    ) : (
                      <BookmarkIcon />
                    )}
                    Save
                  </NavigationItem>
                )}
              />
            )}
          </Resource>
          <NavigationItem onClick={onOpenChat}>
            <ChatBubblesIcon color="indigo" />
            Contact
          </NavigationItem>
        </NavigationRow>
        {isCompanyConversationOpen && (
          <CompanyConversationDialogAsUser
            isOpen={isCompanyConversationOpen}
            onClose={() => setIsCompanyConversationOpen(false)}
            company={companyProfile}
          />
        )}
      </>
    </BottomMobileNavigation>
  );
}
