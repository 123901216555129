import React, { useState } from 'react';
import { List } from 'types/api';
import { LabelDTO } from 'types/company';
import { IResource } from 'util/resource';
import Resources from 'util/resource/Resources';
import useNotify from 'hooks/useNotify';
import Dialog, { Content, DialogActions, Title } from 'ui/views/dialogs/Dialog';
import Button from 'ui/elements/buttons/Button';
import Checkbox from 'ui/elements/form/choice/Checkbox';
import LabelChip from 'ui/domain/Chips/LabelChip';
import IconButton from 'ui/elements/icons/IconButton';
import EditIcon from 'ui/elements/icons/EditIcon';
import useLazyResource from 'util/resource/useLazyResource';
import LabelForm from './LabelsDialog/LabelsForm';

interface LabelDeleteProps {
  label: LabelDTO;
  entityName: string;
  api: {
    delete: (label: LabelDTO) => Promise<void>;
  };
  onDeleteSuccess: () => void;
  onCancel: () => void;
  notify: (type: string, message: string) => void;
}

function LabelDelete({ label, entityName, api, onDeleteSuccess, onCancel, notify }: LabelDeleteProps) {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await api.delete(label);
      onDeleteSuccess();
    } catch (e) {
      notify('error', 'Failed to remove label');
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <>
      <Content>
        <p>This label will be removed from all {entityName}. There is no undo.</p>
      </Content>
      <DialogActions>
        <Button isLoading={isDeleting} kind="primary" color="red" onClick={handleDelete}>
          Delete
        </Button>
        <Button onClick={onCancel} kind="tertiary">
          Cancel
        </Button>
      </DialogActions>
    </>
  );
}

interface LabelsDialogProps {
  labelsResource: IResource<List<LabelDTO>>;
  initialLabels?: LabelDTO[];
  mutateLabels: () => void;
  title: string;
  onClose: () => void;
  api: {
    create: (name: string, color: string) => Promise<LabelDTO>;
    patch: (label: LabelDTO, name: string, color: string) => Promise<LabelDTO>;
    delete: (label: LabelDTO) => Promise<void>;
  };
  entityName: string;
  message?: React.ReactNode;
  onSave?: (selectedLabels: string[], deselectedLabels: string[]) => void;
  entities: (number | string)[];
  update?: (entities: (number | string)[], selectedLabels: string[], deselectedLabels: string[]) => Promise<any>;
}

// We use a mode state so we can switch between the bulk editing view and the form / delete views.
type Mode = { type: 'bulk' } | { type: 'form'; label?: LabelDTO } | { type: 'delete'; label: LabelDTO };

export default function LabelsDialog({
  labelsResource,
  initialLabels,
  mutateLabels,
  title,
  onClose,
  api,
  entityName,
  onSave,
  entities,
  update,
  message,
}: LabelsDialogProps) {
  const notify = useNotify();
  const isInAssignMode = !!update && entities.length !== 0;

  const [mode, setMode] = useState<Mode>({ type: 'bulk' });
  const [selectedLabels, setSelectedLabels] = useState<string[]>(initialLabels?.map(l => l.id) ?? []);
  const [deselectedLabels, setDeselectedLabels] = useState<string[]>([]);

  const handleEdit = (label: LabelDTO) => setMode({ type: 'form', label });
  const handleCreate = () => setMode({ type: 'form' });
  const handleCancel = () => setMode({ type: 'bulk' });
  const handleFormSubmitSuccess = () => {
    mutateLabels();
    setMode({ type: 'bulk' });
  };
  const handleDeleteRequest = (label: LabelDTO) => setMode({ type: 'delete', label });
  const handleDeleteSuccess = () => {
    mutateLabels();
    setMode({ type: 'bulk' });
  };

  // Toggle the state of a label. For bulk editing we cycle between:
  // - default (unchanged) → selected (to add) → deselected (to remove) → back to default.
  const toggleLabel = (labelId: UUID) => {
    const isSelected = selectedLabels.includes(labelId);
    const isDeselected = deselectedLabels.includes(labelId);

    // When bulk editing we have three states, selected, deselected and indeterminate
    // For only one company we can just toggle between selected and deselected
    if (isSelected) {
      setSelectedLabels(selectedLabels.filter(id => id !== labelId));
      setDeselectedLabels([...deselectedLabels, labelId]);
    } else if (isDeselected) {
      setDeselectedLabels(deselectedLabels.filter(id => id !== labelId));

      // If we only have one entity (initialLabels are set), we don't need the indeterminate state
      if (initialLabels) {
        setSelectedLabels([...selectedLabels, labelId]);
      }
    } else {
      setSelectedLabels([...selectedLabels, labelId]);
    }
  };

  const [handleUpdate, isSaving] = useLazyResource(
    ({
      entities,
      selectedLabels,
      deselectedLabels,
    }: {
      entities: (number | UUID)[];
      selectedLabels: UUID[];
      deselectedLabels: UUID[];
    }) =>
      update?.(
        entities,
        // In single edit mode, we don't update the labels that were already assigned
        // It doesn't make a difference for a single user/tab, but could avoid unintentional overwrites for simultaneous edits
        initialLabels ? selectedLabels.filter(id => !initialLabels.map(l => l.id).includes(id)) : selectedLabels,
        deselectedLabels,
      ) ?? Promise.resolve(),
    {
      onSuccess: (_, { selectedLabels, deselectedLabels }) => {
        onSave?.(selectedLabels, deselectedLabels);
        notify('success', 'Updated label assignments');
        onClose();
      },
      onFailure: () => notify('error', `Unable to update label assignments`),
    },
  );

  // Allow the dialog to be closed directly only in bulk mode.
  const dialogClose = mode.type === 'bulk' ? onClose : () => setMode({ type: 'bulk' });

  return (
    <Dialog open onClose={dialogClose} mobileLayout="drawer">
      <Title onClose={dialogClose}>{title}</Title>
      <Resources resources={[labelsResource]}>
        {([labels]: [List<LabelDTO>]) => {
          switch (mode.type) {
            case 'bulk':
              return (
                <>
                  <Content>
                    {isInAssignMode && (
                      <p className="u-content-spacing-bottom">
                        {`Select labels to assign or remove from ${
                          entities.length === 1
                            ? entityName
                            : `${entities.length} ${entityName}${entityName.endsWith('s') ? '' : 's'}`
                        }`}
                      </p>
                    )}
                    <div className="u-flex u-flex--column">
                      {labels.values.map(label => (
                        <div key={label.id} className="u-flex u-flex-space-between">
                          {isInAssignMode ? (
                            <Checkbox
                              label={<LabelChip label={label.name} color={label.color} />}
                              checked={selectedLabels.includes(label.id)}
                              indeterminate={!initialLabels && deselectedLabels.includes(label.id)}
                              color={deselectedLabels.includes(label.id) ? 'secondary' : 'primary'}
                              onChange={() => toggleLabel(label.id)}
                            />
                          ) : (
                            <LabelChip label={label.name} color={label.color} />
                          )}
                          <IconButton color="indigo" onClick={() => handleEdit(label)}>
                            <EditIcon />
                          </IconButton>
                        </div>
                      ))}
                    </div>
                    {message}
                    <Button kind="tertiary" onClick={handleCreate} className="u-content-spacing-top">
                      Create new label
                    </Button>
                  </Content>
                  <DialogActions>
                    {update ? (
                      <>
                        <Button
                          isLoading={isSaving}
                          onClick={() => handleUpdate({ entities, selectedLabels, deselectedLabels })}
                          kind="primary"
                          disabled={entities.length === 0}
                        >
                          Update
                        </Button>
                        <Button onClick={onClose} kind="tertiary">
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <Button onClick={onClose} kind="secondary">
                        Close
                      </Button>
                    )}
                  </DialogActions>
                </>
              );
            case 'form':
              return (
                <LabelForm
                  label={mode.label}
                  api={api}
                  onSubmitSuccess={handleFormSubmitSuccess}
                  onCancel={handleCancel}
                  onDeleteRequest={mode.label ? () => handleDeleteRequest(mode.label!) : undefined}
                  notify={notify}
                />
              );
            case 'delete':
              return (
                <LabelDelete
                  label={mode.label}
                  entityName={entityName}
                  api={api}
                  onDeleteSuccess={handleDeleteSuccess}
                  onCancel={handleCancel}
                  notify={notify}
                />
              );
            default:
              return null;
          }
        }}
      </Resources>
    </Dialog>
  );
}
