import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import SectionHeading from 'ui/elements/SectionHeading';
import { communityUrls } from 'urls';
import { pluralize } from 'util/stringUtils';
import Carousel, { SeeMoreCard } from 'ui/elements/Carousel';
import { PaginatedResult } from 'types';
import { communityBleedAmounts } from 'ui/views/layouts/CommunityPage';
import { hasMorePages } from 'util/paginationUtils';
import { UserFilter } from 'types/company/companyAPI';
import { UserProfile } from 'types/user';
import InvestorTile from 'domain/investors/InvestorTile';
import { userDialogUrl } from 'domain/users/UserProfile/UserProfileDialogRoute';
import { useSWRResource } from 'util/resource/useSWRResource';
import useSWR from 'hooks/useSWR';
import { communityAPIUrls } from 'apis/CompanyAPI/communities/communitiesApi';
import { CommunityConfiguration } from 'types/company/community';

interface Props {
  communitySlug: string;

  configuration: CommunityConfiguration;
  users: PaginatedResult<UserProfile>;
  filters?: UserFilter;
}

export function useFeaturedCommunityUsers(communitySlug: string, opts: { limit: number; shouldFetch?: boolean }) {
  return useSWRResource(
    useSWR<PaginatedResult<UserProfile>>(
      opts.shouldFetch ? communityAPIUrls.users.list(communitySlug, { limit: opts.limit }) : undefined,
    ),
  );
}

export default function FeaturedCommunityUsers({ communitySlug, configuration, filters, users }: Props) {
  const match = useRouteMatch();

  return (
    <>
      {configuration.peopleAreVisible && users.total > 0 && (
        <div className="u-section-spacing-bottom">
          <SectionHeading
            heading={`Explore ${users.total} ${pluralize(users.total, 'member', 'members')}`}
            actionsAlignment="left"
          >
            <Link to={communityUrls.members.overview(communitySlug, 'people', filters)} className="text-link-small">
              See all
            </Link>
          </SectionHeading>
          <Carousel options={{ dragFree: 'mobile' }} bleed={communityBleedAmounts}>
            {users.values.map(user => (
              <InvestorTile viewInvestorHref={userDialogUrl(match.url, user.cwUserId)} key={user.id} investor={user} />
            ))}
            {hasMorePages(users) && (
              <SeeMoreCard href={communityUrls.members.overview(communitySlug, 'companies', filters)} />
            )}
          </Carousel>
        </div>
      )}
    </>
  );
}
