import { Paper } from '@mui/material';
import cx from 'classnames';
import React, { CSSProperties, ForwardedRef, forwardRef } from 'react';
import { bluePlanetTheme } from 'ui/theme';
import styles from './cards.scss';

export type PaddingSizes = 'default' | 'half' | 'double' | 'none' | 'quarter';

interface Props {
  id?: string;
  className?: string;
  color?: BackgroundColor;
  children: React.ReactNode;
  'data-intercom-target'?: string;
  padding?: PaddingSizes;
  style?: CSSProperties;
  elevation?: number;
  type?: 'dashed' | 'default' | 'solid';
  onClick?: (e?: any) => void;
  hover?: 'highlight' | 'drop-shadow';
}
export type BackgroundColor =
  | 'white'
  | 'indigo'
  | 'blue'
  | 'light-grey'
  | 'grey-medium'
  | 'blue-very-light'
  | 'blue-dark'
  | 'green-very-light'
  | 'orange'
  | 'transparent';

function getBackgroundColor(color?: BackgroundColor) {
  if (color === 'light-grey') return bluePlanetTheme.bluePlanetPalette.grey.light;
  else if (color === 'blue-very-light') return bluePlanetTheme.bluePlanetPalette.blue.veryLight;
  else if (color === 'grey-medium') return bluePlanetTheme.bluePlanetPalette.grey.medium;
  else if (color === 'indigo') return bluePlanetTheme.bluePlanetPalette.indigo.main;
  else if (color === 'blue') return bluePlanetTheme.bluePlanetPalette.blue.main;
  else if (color === 'blue-dark') return bluePlanetTheme.bluePlanetPalette.indigo.dark;
  else if (color === 'orange') return bluePlanetTheme.bluePlanetPalette.orange.main;
  else if (color === 'green-very-light') return bluePlanetTheme.bluePlanetPalette.green.veryLight;
  else if (color === 'transparent') return 'transparent';
  return bluePlanetTheme.bluePlanetPalette.white;
}

export function getTextColor(color?: BackgroundColor) {
  return ['indigo', 'blue', 'blue-dark', 'orange'].includes(color ?? '')
    ? bluePlanetTheme.bluePlanetPalette.white
    : bluePlanetTheme.bluePlanetPalette.indigo.main;
}

function getHoverColor(color?: BackgroundColor) {
  if (color === 'light-grey') return bluePlanetTheme.bluePlanetPalette.grey.medium;
  else if (color === 'blue-very-light') return bluePlanetTheme.bluePlanetPalette.blue.light;
  else if (color === 'indigo') return bluePlanetTheme.bluePlanetPalette.indigo.dark;
  else if (color === 'blue') return bluePlanetTheme.bluePlanetPalette.blue.dark;
  else return bluePlanetTheme.bluePlanetPalette.grey.veryLight;
}

export default forwardRef<HTMLDivElement, Props>(function Card(
  { color, padding = 'default', type, elevation, onClick, className, children, hover, ...rest }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <Paper
      ref={ref}
      sx={{
        backgroundColor: getBackgroundColor(color),
        color: getTextColor(color),
        '&:hover': {
          backgroundColor: hover === 'highlight' ? getHoverColor(color) : undefined,
        },
        border:
          type === 'dashed'
            ? `1px dashed ${bluePlanetTheme.bluePlanetPalette.blue.main}`
            : type === 'solid'
              ? `1px solid ${bluePlanetTheme.bluePlanetPalette.disabled}`
              : 'inherit',
      }}
      onClick={onClick}
      elevation={elevation ?? 0}
      className={cx(className, styles.card, {
        'u-background--dark': color === 'indigo',
        'u-content-padding': padding === 'default',
        'u-content-padding-x u-half-padding-y': padding === 'half',
        'u-quarter-padding': padding === 'quarter',
        'u-section-padding': padding === 'double',
        [styles.clickable]: !!onClick,
        [styles.hoverDropShadow]: hover === 'drop-shadow',
      })}
      {...rest}
    >
      {children}
    </Paper>
  );
});
