import config from 'config';
import { useState, useEffect } from 'react';

export function useIframeHeights(contentRef: React.RefObject<HTMLParagraphElement>) {
  const [iframeHeights, setIframeHeights] = useState<{ [key: string]: number }>({});
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin === config.PORTAL_URL) {
        if (event.data.type === 'iframeHeight' && event.data.href) {
          setIframeHeights(prevHeights => ({
            ...prevHeights,
            [event.data.href]: event.data.height,
          }));
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    // set iframe heights based on the heights received from the iframe
    if (contentRef.current) {
      const iframes = contentRef.current.querySelectorAll('iframe');

      iframes.forEach(iframe => {
        const src = iframe.src;
        if (iframeHeights[src]) {
          iframe.style.setProperty('height', `${iframeHeights[src] + 5}px`);
        }
      });
    }
  }, [iframeHeights]);
  return iframeHeights;
}
