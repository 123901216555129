import { fromStatusCode, HttpErrorType } from 'pages/common/error/helpers';

export type IResource<T> = Fetched<T> | FetchedEmpty | Fetching | Idle | FetchError;

export type LoadingStates = 'idle' | 'fetching' | 'fetched' | 'fetched-no-content' | 'error';

export type Fetched<T> = {
  state: 'fetched';
  resource: T;
  isValidating: boolean;
};

type Fetching = {
  state: 'fetching';
};

type Idle = {
  state: 'idle';
};

type FetchedEmpty = {
  state: 'fetched-no-content';
};

type FetchError = {
  state: 'error';
  errorType: HttpErrorType;
};

export const idle: Idle = {
  state: 'idle',
};

export const fetchedEmpty: FetchedEmpty = {
  state: 'fetched-no-content',
};

export function fetchError(errorStatus: number): FetchError {
  return { state: 'error', errorType: fromStatusCode(errorStatus) };
}

export function fetched<T>(resource: T, isValidating = false): Fetched<T> {
  return {
    state: 'fetched',
    resource,
    isValidating,
  };
}

export function isFetching(): Fetching {
  return {
    state: 'fetching',
  };
}

export function getOrElse<T>(resource: IResource<T>, orElse: T): T {
  return resource.state === 'fetched' ? resource.resource : orElse;
}

export function getOrUndefined<T>(resource?: IResource<T>): T | undefined {
  return resource && resource.state === 'fetched' ? resource.resource : undefined;
}

export function map<T, U>(resource: IResource<T>, fn: (r: T) => U): IResource<U> {
  if (resource.state === 'fetched') {
    return { state: 'fetched', resource: fn(resource.resource), isValidating: resource.isValidating };
  }
  if (resource.state === 'fetching') {
    return {
      state: 'fetching',
    };
  }
  return resource;
}

export function isValidating<T>(resource: IResource<T>): boolean {
  return resource.state === 'fetching' || (resource.state === 'fetched' && resource.isValidating);
}
