import { useCommunities } from 'apis/CompanyAPI/communities/useCommunities';
import React from 'react';
import { TinyCompanyDTO } from 'types/company';
import { eventCtaTypeReadableMap, EventDTO } from 'ui/domain/Event/types';
import AnchorAsButton from 'ui/elements/buttons/AnchorAsButton';
import LinkAsButton from 'ui/elements/buttons/LinkAsButton';
import CheckmarkIcon from 'ui/elements/icons/CheckmarkIcon';
import ExternalLinkIcon from 'ui/elements/icons/ExternalLink';
import { communityUrls } from 'urls';
import { getOrUndefined } from 'util/resource';
import { extractInternalPath, isInternalLink } from 'util/urlUtils';
import styled from '@emotion/styled';
import featureToggle from 'featureToggle';
import { ButtonProps } from 'ui/elements/buttons/Button';
export const mcOrange = '#CF4500';

const primaryStyleMc = `
  background-color: ${mcOrange} !important;
  &:focus, &:hover {
    background-color: #b03b00 !important;
  }
`;
const secondaryStyleMc = `
  color: ${mcOrange} !important;
  border-color: ${mcOrange} !important;

  &:focus, &:hover {
    background-color: #b03b00 !important;
    color: white !important;
  }
`;

const McAnchorAsButton = styled(AnchorAsButton)(
  ({ isMastercard, kind }: { isMastercard: boolean; kind: ButtonProps['kind'] }) =>
    isMastercard && kind === 'primary' ? primaryStyleMc : isMastercard && kind === 'secondary' ? secondaryStyleMc : ``,
);

const McLinkAsButton = styled(LinkAsButton)(
  ({ isMastercard, kind }: { isMastercard: boolean; kind: ButtonProps['kind'] }) =>
    isMastercard && kind === 'primary' ? primaryStyleMc : isMastercard && kind === 'secondary' ? secondaryStyleMc : ``,
);

export default function CtaButton({ event, community }: { event: EventDTO; community: TinyCompanyDTO | undefined }) {
  const { resource: communities } = useCommunities();

  const hasCompletedCta =
    // Check if the user is already pending or accepted into the community
    community &&
    event.ctaURL?.includes(communityUrls.baseUrl(community.slug)) &&
    getOrUndefined(communities)?.values.some(c => c.id === event.communityId);

  if (!event.ctaType || !event.ctaURL) {
    return null;
  }

  const text = hasCompletedCta ? (
    <>
      <CheckmarkIcon className="u-quarter-spacing-right"></CheckmarkIcon>You are registered
    </>
  ) : (
    eventCtaTypeReadableMap[event.ctaType]
  );

  const kind = hasCompletedCta ? 'secondary' : 'primary';

  const isMastercard = featureToggle.mastercardEventToggle(event.id) !== undefined;

  return isInternalLink(event.ctaURL) ? (
    <McLinkAsButton kind={kind} url={extractInternalPath(event.ctaURL)} isMastercard={isMastercard}>
      {text}
    </McLinkAsButton>
  ) : (
    <McAnchorAsButton isMastercard={isMastercard} kind={kind} href={event.ctaURL} target="_blank">
      {text}
      <ExternalLinkIcon className="u-quarter-spacing-left" fontSize="small" />
    </McAnchorAsButton>
  );
}
