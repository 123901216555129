import React from 'react';

export function userRole(user: { position?: string; employer?: string }) {
  return user.position ? (
    <>
      {user.position}
      {user.employer ? (
        <>
          {' '}
          at <span>{user.employer}</span>
        </>
      ) : null}
    </>
  ) : null;
}
