import { CalendarEvent, google, ics, office365, outlook, yahoo } from 'calendar-link';
import React from 'react';
import PopMenu, { PopMenuItem } from 'ui/modules/PopMenu';
import Button from './Button';
import GmailIcon from '../icons/GmailIcon';
import OutlookIcon from '../icons/OutlookIcon';
import Office365Icon from '../icons/Office365Icon';
import YahooIcon from '../icons/YahooIcon';
import DownloadIcon from '../icons/DownloadIcon';

interface Props {
  event: CalendarEvent;
  className?: string;
  trackingKey?: string;
  extraOnClick?: () => void;
}

export default function CalendarButton({ event, className, trackingKey, extraOnClick }: Props) {
  const menuItems: PopMenuItem[] = [
    {
      icon: <GmailIcon color="indigo" strokeWidth={3} />,
      text: 'Google',
      onClick: () => window.open(google(event), '_blank'),
    },
    {
      icon: <OutlookIcon strokeWidth={3} />,
      text: 'Outlook',
      onClick: () => window.open(outlook(event), '_blank'),
    },
    {
      icon: <Office365Icon strokeWidth={3} />,
      text: 'Office 365',
      onClick: () => window.open(office365(event), '_blank'),
    },
    {
      icon: <YahooIcon strokeWidth={3} />,
      text: 'Yahoo',
      onClick: () => window.open(yahoo(event), '_blank'),
    },
    {
      icon: <DownloadIcon strokeWidth={1} />,
      text: 'iCal',
      onClick: () => window.open(ics(event), '_blank'),
    },
  ];

  return (
    <PopMenu
      items={menuItems}
      renderAnchor={onClick => (
        <div className={className}>
          <span>
            <Button
              kind="secondary"
              className={trackingKey ? `data-track-${trackingKey}` : undefined}
              onClick={e => {
                e.preventDefault();
                onClick(e);
                if (extraOnClick) extraOnClick();
              }}
            >
              Add to calendar
            </Button>
          </span>
        </div>
      )}
    />
  );
}
