import React from 'react';
import { UserProfile } from 'types/user';
import { AuthorImage, asUserAuthor } from 'domain/shared/author';
import Timestamp from 'ui/elements/Timestamp';
import styles from './styles.scss';
import { Message } from 'apis/ContentAPI/conversationApi';
import DownloadableFile from 'domain/shared/Files/File/DownloadableFile';
import cx from 'classnames';
import { insertLinksForUrls } from 'util/stringUtils';
import ShareCompanyCard from 'domain/conversations/Message/ShareCard/ShareCompanyCard';
import ShareEventCard from 'domain/conversations/Message/ShareCard/ShareEventCard';
import HtmlContent from 'ui/elements/HtmlContent';
import ShareUpdateCard from 'domain/conversations/Message/ShareCard/ShareUpdateCard';

interface Props {
  author?: UserProfile;
  onAuthorClick: (authorId: string) => void;
  onAttachmentClick: (attachmentId: string) => Promise<void>;
  messages: (Message & { align: 'left' | 'right' })[];
  padding?: 'end' | 'none';
  actions?: React.ReactNode;
  children?: React.ReactNode;
  isResponse?: boolean;
  isDeleted?: boolean;
  isSelfAuthored?: boolean;
  handleScrollToBottom?: () => void;
  onClose: () => void;
  onNavigate?: () => void;
}
export default function MessageCard(props: Props) {
  const { messages, author } = props;
  const userAuthor = asUserAuthor(author);

  return (
    <div
      className={cx(styles.messageCard, {
        [styles.indent]: props.isResponse,
        [styles.cardRight]: messages[0].align === 'right',
      })}
    >
      <div className={styles.authorContainer}>
        <button
          onClick={author?.cwUserId ? () => props.onAuthorClick(author?.cwUserId) : undefined}
          title={author?.name}
        >
          <span className={styles.author}>{userAuthor.name}</span>
        </button>
      </div>
      <span className={styles.authorImage}>
        <AuthorImage
          onClick={author?.cwUserId ? () => props.onAuthorClick(author?.cwUserId) : undefined}
          author={userAuthor}
        />
      </span>
      {messages.map(message => {
        return (
          <div key={message.id} className={styles.messageBubble}>
            {message.sharedContent?.companyId && (
              <div
                className={cx('u-flex', {
                  ['u-flex-end']: props.isSelfAuthored,
                })}
              >
                <ShareCompanyCard
                  className="u-half-spacing-bottom u-quarter-spacing-top"
                  companyId={message.sharedContent.companyId}
                  shouldNavigateToCompanyProfile={true}
                  onNavigate={() => {
                    props.onClose();
                    props.onNavigate && props.onNavigate();
                  }}
                  onContentLoaded={props.handleScrollToBottom}
                />
              </div>
            )}
            {message.sharedContent?.eventId && (
              <div
                className={cx('u-flex', {
                  ['u-flex-end']: props.isSelfAuthored,
                })}
              >
                <ShareEventCard
                  className="u-half-spacing-bottom u-quarter-spacing-top"
                  eventId={message.sharedContent.eventId}
                  onContentLoaded={props.handleScrollToBottom}
                />
              </div>
            )}
            {message.sharedContent?.updateId && (
              <div
                className={cx('u-flex', {
                  ['u-flex-end']: props.isSelfAuthored,
                })}
              >
                <ShareUpdateCard
                  className="u-half-spacing-bottom u-quarter-spacing-top"
                  updateId={message.sharedContent.updateId}
                  onContentLoaded={props.handleScrollToBottom}
                />
              </div>
            )}
            <div className="u-flex">
              <div
                className={cx(styles.message, 'u-quarter-spacing-bottom', {
                  [styles.ownMessage]: props.isSelfAuthored,
                  [styles.othersMessage]: !props.isSelfAuthored,
                })}
              >
                <HtmlContent style={{ fontStyle: props.isDeleted ? 'italic' : 'normal' }}>
                  {insertLinksForUrls(message.content)}
                </HtmlContent>
                {message.attachments?.length > 0 && (
                  <div className={styles.attachmentList}>
                    {message.attachments.map(a => (
                      <DownloadableFile
                        key={a.fileId}
                        onDownload={() => props.onAttachmentClick(a.fileId)}
                        name={a.filename}
                        backgroundColor="white"
                        hasBorder
                      ></DownloadableFile>
                    ))}
                  </div>
                )}
              </div>
              {props.actions}
            </div>
          </div>
        );
      })}
      {
        <div
          className={cx(styles.byline, 'u-half-spacing-bottom', {
            [styles.timestampSuffixLeft]: messages[messages.length - 1].align === 'left',
            [styles.timestampSuffixRight]: messages[messages.length - 1].align === 'right',
          })}
        >
          <Timestamp timestamp={messages[messages.length - 1].createdAt} />
        </div>
      }
      {props.children}
    </div>
  );
}
