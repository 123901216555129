import React from 'react';
import CompanyLogo from 'ui/elements/CompanyLogo';
import { communityUrls, companyUrls, organizationUrls, userUrls } from 'urls';
import { CompanyListItem } from 'types/company';
import SectionHeadingLarge from 'ui/elements/SectionHeading/SectionHeadingLarge';
import Indicator from 'ui/elements/Indicator/NumberIndicator';
import { Link } from 'react-router-dom';
import Card from 'ui/views/cards/Card';
import { CardGridWithLimit } from 'ui/views/cards/CardGrid';
import LastUpdated from 'domain/companies/profile/LastUpdated';
import { CompanyRoles } from './common/CompanyRoles';
import { CommunityLisItemDetailed } from 'types/company/community';
import { bluePlanetTheme } from 'ui/theme';
import { useMediaQuery } from '@mui/material';
import DashboardMessage from '../sections/messages/DashboardMessage';
import IconAvatar from 'ui/elements/icons/IconAvatar';
import NetworkIcon from 'ui/elements/icons/NetworkIcon';
import Pulse from '../sections/pulse/Pulse';
import { MobileMargin } from 'ui/views/layouts/BaseLayout';
import Carousel from 'ui/elements/Carousel';
import { OrganizationListItemDTO } from 'types/organization';
import Logo from 'ui/domain/Logo';
import { OrganizationRoles } from 'pages/Dashboard/network/common/OrganizationRoles';
import { defaultBleedAmounts } from 'ui/views/layouts/BaseLayout';
import { MyCommunityMembershipStatus } from './common/MyCommunityMembershipStatus';

interface Props {
  companies: CompanyListItem[];
  communities: CommunityLisItemDetailed[];
  organizations: OrganizationListItemDTO[];
}

const MAX_VISIBLE_ENTRIES = 12;

function minMax(min: number, max: number, count: number) {
  return count <= min ? min : max;
}

function getNumberOfVisibleColumns(isMobile: boolean, isDesktop: boolean, isXl: boolean) {
  if (isMobile) return 2;
  if (isDesktop) return 3;
  if (isXl) return 10;
  return MAX_VISIBLE_ENTRIES;
}

function Entries({ count, children }: { count: number; children: React.ReactNode[] }) {
  const isLgDown = useMediaQuery(bluePlanetTheme.breakpoints.down('lg'));
  return isLgDown ? (
    <Carousel slidesWidth={{ xs: '60%' }} options={{ dragFree: true }} bleed={defaultBleedAmounts}>
      {children}
    </Carousel>
  ) : (
    <CardGridWithLimit
      mobile={{ visibleCardsCount: 2, columnCount: 2 }}
      tablet={{ visibleCardsCount: 3, columnCount: minMax(2, 3, count) }}
      desktop={{ visibleCardsCount: 3, columnCount: minMax(3, 3, count) }}
      xl={{ visibleCardsCount: 10, columnCount: minMax(4, 5, count) }}
      xxl={{ visibleCardsCount: MAX_VISIBLE_ENTRIES, columnCount: minMax(5, 6, count) }}
    >
      {children}
    </CardGridWithLimit>
  );
}

export default function Network({ companies, communities, organizations }: Props) {
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery(bluePlanetTheme.breakpoints.down('lg'));
  const isXl = useMediaQuery(bluePlanetTheme.breakpoints.down('xl'));
  const numberOfVisibleColumns = getNumberOfVisibleColumns(isMobile, isDesktop, isXl);

  const isEmpty = companies.length + communities.length < 1;

  return isEmpty ? (
    <DashboardMessage
      topElement={
        <IconAvatar color="blue-light" size={{ width: '60px', height: '60px' }}>
          <NetworkIcon style={{ fontSize: '30px' }} color="indigo"></NetworkIcon>
        </IconAvatar>
      }
    >
      <p>Communities and companies you are connected to will be listed here.</p>
    </DashboardMessage>
  ) : (
    <>
      <MobileMargin>
        {communities.length > 0 && (
          <>
            <SectionHeadingLarge
              className="u-section-spacing-top"
              heading={
                <>
                  Your communities{' '}
                  <Indicator
                    className="u-quarter-spacing-left"
                    color="grey"
                    shape="square"
                    value={communities.length}
                  />
                  {numberOfVisibleColumns < communities.length && (
                    <Link className="u-half-spacing-left text-link-small" to={userUrls.dashboard.network.communities()}>
                      View all
                    </Link>
                  )}
                </>
              }
            ></SectionHeadingLarge>
            <Entries count={communities.length}>
              {communities.slice(0, MAX_VISIBLE_ENTRIES).map(c => (
                <Link to={communityUrls.overview(c.details.slug)} key={c.details.id}>
                  <Card
                    key={c.details.id}
                    style={{ minHeight: '14rem' }}
                    className="u-flex u-flex--column u-flex-space-between u-flex-align-center"
                  >
                    <div className="u-flex u-flex--column u-flex-align-center u-content-spacing-bottom">
                      <CompanyLogo className="u-content-spacing-bottom" company={c.details}></CompanyLogo>
                      <div className="text-medium text-weight-medium text-align-center u-quarter-spacing-bottom">
                        {c.details.name}
                      </div>
                      <div className="text-metadata text-align-center">{c.membersCount} members</div>
                    </div>
                    <div>
                      <MyCommunityMembershipStatus
                        style={{
                          flexDirection: 'column',
                        }}
                        community={c}
                      />
                    </div>
                  </Card>
                </Link>
              ))}
            </Entries>
          </>
        )}
        {companies.length > 0 && (
          <>
            <SectionHeadingLarge
              className="u-section-spacing-top"
              heading={
                <>
                  Companies you are connected with{' '}
                  <Indicator className="u-quarter-spacing-left" color="grey" shape="square" value={companies.length} />
                  {numberOfVisibleColumns < companies.length && (
                    <Link className="u-half-spacing-left text-link-small" to={userUrls.dashboard.network.companies()}>
                      View all
                    </Link>
                  )}
                </>
              }
            ></SectionHeadingLarge>
            <Entries count={companies.length}>
              {companies.slice(0, MAX_VISIBLE_ENTRIES).map(c => (
                <Link to={companyUrls.overview(c.slug, 'updates')} key={c.id}>
                  <Card
                    key={c.id}
                    style={{ minHeight: '14rem' }}
                    className="u-flex u-flex--column u-flex-space-between u-flex-align-center"
                  >
                    <div className="u-flex u-flex--column u-flex-align-center u-content-spacing-bottom">
                      <CompanyLogo className="u-content-spacing-bottom" company={c}></CompanyLogo>
                      <span className="text-medium text-weight-medium u-quarter-spacing-bottom text-align-center">
                        {c.name}
                      </span>
                      <LastUpdated companyId={c.id} lastUpdated={c.lastUpdated}></LastUpdated>
                    </div>
                    <CompanyRoles roles={c.roles} />
                  </Card>
                </Link>
              ))}
            </Entries>
          </>
        )}
        {organizations.length > 0 && (
          <>
            <SectionHeadingLarge
              className="u-section-spacing-top"
              heading={
                <>
                  Organizations you are connected with{' '}
                  <Indicator
                    className="u-quarter-spacing-left"
                    color="grey"
                    shape="square"
                    value={organizations.length}
                  />
                </>
              }
            >
              {numberOfVisibleColumns < organizations.length && (
                <Link className="u-half-spacing-left text-link-small" to={userUrls.dashboard.network.organizations()}>
                  View all
                </Link>
              )}
            </SectionHeadingLarge>
            <Entries count={companies.length}>
              {organizations.slice(0, MAX_VISIBLE_ENTRIES).map(o => (
                <Link to={organizationUrls.view(o.slug)} key={o.id}>
                  <Card key={o.id} className="u-flex-space-between u-flex--column u-flex-align-center u-flex--gap-half">
                    <div className="u-flex u-flex--column u-flex-align-center u-content-spacing-bottom">
                      <Logo url={o.logoURL} type="company" size={50} className={'u-content-spacing-bottom'} />
                      <span className="text-medium text-weight-medium u-quarter-spacing-bottom text-align-center">
                        {o.name}
                      </span>
                    </div>
                    <OrganizationRoles isAdmin={o.isAdmin} displayAs={o.displayAs} />
                  </Card>
                </Link>
              ))}
            </Entries>
          </>
        )}
      </MobileMargin>
      {companies.length + communities.length > 0 && (
        <div className="u-section-spacing-top" style={{ maxWidth: '800px' }}>
          <Pulse activityTypes={['From my network']} />
        </div>
      )}
    </>
  );
}
