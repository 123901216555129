import { createTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import { BluePlanetPalette } from '@mui/material/styles/createTheme';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { staticFileLocations } from 'urls';
import { INTERCOM_CHAT_BUBBLE_OFFSET } from 'util/constants';
import { default as FontRedHat, default as RedHatFonts } from './FontRedHat';
import { contentSpacing, halfSpacing, quarterSpacing, sectionSpacing } from './themeConstants';

interface BluePlanetColorshades {
  medium: string;
  light: string;
  veryLight?: string;
  main: string;
  dark?: string;
  contrastText?: string;
}

declare module '@mui/material/styles/createTheme' {
  interface BluePlanetPalette {
    grey: BluePlanetColorshades;
    orange: BluePlanetColorshades;
    red: BluePlanetColorshades;
    blue: BluePlanetColorshades;
    purple: BluePlanetColorshades;
    violet: BluePlanetColorshades;
    yellow: BluePlanetColorshades;
    green: BluePlanetColorshades;
    lime: BluePlanetColorshades;
    offWhite: NonNullable<React.CSSProperties['color']>;
    white: NonNullable<React.CSSProperties['color']>;
    black: NonNullable<React.CSSProperties['color']>;
    indigo: BluePlanetColorshades;
    disabled: NonNullable<React.CSSProperties['color']>;
    inputBorder: NonNullable<React.CSSProperties['color']>;
  }

  interface Theme {
    bluePlanetPalette: BluePlanetPalette;
  }

  interface ThemeOptions {
    bluePlanetPalette?: BluePlanetPalette;
  }
}

export const shape = {
  borderRadius: 4,
};

const spacingUnit = 10;
const spacing = (multiplier: number) => `${spacingUnit * multiplier}px`;

export const spacingUnits = {
  content: '1.25rem',
  section: '2.5rem',
  half: '0.625rem',
  quarter: '0.3125rem',
};

const bluePlanetPalette: BluePlanetPalette = {
  grey: {
    main: '#7e8a96',
    light: '#f6f7fb',
    medium: '#eaecee',
    dark: '#606276',
    veryLight: '#fbfbfb',
  },
  orange: {
    main: '#ff6a36',
    light: '#ffe1d7',
    medium: '#FA8F68',
    dark: '#cc552b',
  },
  red: {
    main: '#FF5454',
    light: '#fce6e8',
    medium: '#fca2a7',
    dark: '#f50004',
  },
  blue: {
    veryLight: '#d6e1ff',
    light: '#acb9ff',
    main: '#346aff',
    medium: '#85a6ff',
    dark: '#00268f',
  },
  purple: {
    main: '#ac4df7',
    light: '#f1dcff',
    medium: '#d697ff',
  },
  violet: {
    main: '#855cf8',
    light: '#D4BDFF',
    veryLight: '#CDCBFF',
    medium: '#b085ff',
    dark: '#503795',
  },
  yellow: {
    main: '#fcd24c',
    light: '#fff8dc',
    medium: '#ffe997',
    dark: '#ad8400',
  },
  green: {
    main: '#4fe88d',
    veryLight: '#E5F4E9',
    light: '#b5dbd0',
    medium: '#84C89B',
    dark: '#388E55',
  },
  lime: {
    main: '#b9e541',
    light: '#f6ffe0',
    medium: '#d6f291',
  },
  indigo: {
    main: '#0f1f42',
    veryLight: '#36425c',
    light: '#273554',
    medium: '#3F3356',
    dark: '#0b1936',
  },
  offWhite: '#fbfbfb',
  white: '#ffffff',
  black: '#141516',
  disabled: '#d6dade',
  inputBorder: alpha('#0f1f42', 0.1),
};

const palette = {
  primary: {
    main: bluePlanetPalette.blue.main,
    dark: bluePlanetPalette.indigo.main,
    light: bluePlanetPalette.blue.light,
  },

  secondary: {
    main: bluePlanetPalette.red.main,
    contrastText: bluePlanetPalette.offWhite,
    dark: bluePlanetPalette.red.dark!,
  },
  divider: bluePlanetPalette.grey.medium,
  error: {
    main: bluePlanetPalette.red.main,
    light: bluePlanetPalette.red.light,
    dark: bluePlanetPalette.red.dark!,
    contrastText: bluePlanetPalette.red.contrastText!,
  },
  text: { primary: bluePlanetPalette.indigo.main, secondary: bluePlanetPalette.grey.main },
  action: {
    active: bluePlanetPalette.indigo.main,
    disabled: bluePlanetPalette.disabled,
    disabledBackground: bluePlanetPalette.grey.light,
  },
};

// const fontSizeMicro = '0.625rem';
export const fontSizeTiny = '0.75rem';
export const fontSizeSmall = '0.8125rem';
export const fontSizeMedium = '0.9375rem';
export const fontSizeLarge = '1.0625rem';
export const fontSizeH1 = '4.5rem';
export const fontSizeH2 = '2.1875rem';
export const fontSizeH3 = '1.375rem';
export const fontSizeH4 = '1.2rem';

export const typography: TypographyOptions = {
  fontFamily: RedHatFonts.text,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  button: {
    fontSize: fontSizeSmall,
    lineHeight: 1.38,
    letterSpacing: 'normal',
    fontWeight: 500,
    textTransform: 'none',
  },
  h1: { color: bluePlanetPalette.indigo.main, fontSize: fontSizeH1 },
  h2: { color: bluePlanetPalette.indigo.main, fontSize: fontSizeH2 },
  h3: { color: bluePlanetPalette.indigo.main, fontSize: fontSizeH3 },
  h4: { color: bluePlanetPalette.indigo.main, fontSize: fontSizeH4 },
  h5: { color: bluePlanetPalette.indigo.main, fontSize: fontSizeLarge },
  h6: { color: bluePlanetPalette.indigo.main, fontSize: fontSizeLarge, fontFamily: RedHatFonts.display },
  body1: { color: bluePlanetPalette.indigo.main, fontSize: fontSizeMedium },
};

const bluePlanetTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0, // don't mess with this, else <Hidden> components will break
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: typography,
  shape: shape,
  spacing: spacingUnit,
  bluePlanetPalette: bluePlanetPalette,
  palette: palette,
});

bluePlanetTheme.shadows[1] = `0 0 ${quarterSpacing} rgba(0,0,0,0.07)`;

export type BackdropColor = 'transparent' | 'light' | 'medium' | 'white';
export function getBackdropColor(backdropColor: BackdropColor = 'light') {
  switch (backdropColor) {
    case 'transparent':
      return 'transparent';
    case 'light':
      return alpha(bluePlanetPalette.indigo.main, 0.5);
    case 'medium':
      return alpha(bluePlanetPalette.indigo.main, 0.7);
    case 'white':
      return bluePlanetPalette.white;
  }
}

bluePlanetTheme.components = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        '&.Mui-focusVisible': {
          backgroundColor: 'hsla(0, 50%, 90%, 0.3)',
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        padding: spacing(0.8),
      },
      sizeSmall: {
        padding: spacing(0.2),
      },
    },
  },
  MuiBadge: {
    styleOverrides: {
      badge: {
        fontSize: '0.75rem',
        top: spacing(0.25),
        right: spacing(0.25),
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      primary: {
        backgroundColor: bluePlanetPalette.blue.main,
        '&:hover': {
          backgroundColor: bluePlanetPalette.blue.dark,
        },
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        borderRadius: '0',
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        minHeight: 0,
      },
      indicator: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        backgroundColor: bluePlanetPalette.blue.veryLight,
        borderRadius: 50,
        top: 0,
      },

      scrollButtons: {
        color: bluePlanetPalette.grey.main,
        width: spacing(1),
        marginBottom: '-2px', // To align better with text
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        minHeight: 40,
        fontFamily: FontRedHat.text,
        fontWeight: 500,
        padding: `${halfSpacing} ${spacing(2)}`,
        zIndex: 2,
        [bluePlanetTheme.breakpoints.down('sm')]: {
          minWidth: 0,
        },
      },
      iconWrapper: {
        marginBottom: quarterSpacing,
      },
      fullWidth: {
        marginRight: 0,
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        height: 2,
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        height: 0, // This prevents the adornment from increasing the height of the input
        paddingBottom: 0,
      },
      positionStart: {
        marginRight: spacing(0.5),
        zIndex: 2,
      },
    },
  },
  MuiSnackbarContent: {
    styleOverrides: {
      root: {
        padding: `${spacing(1)} ${spacing(2)}`,
        flexWrap: 'nowrap',
      },
      message: {
        padding: 0,
      },
      action: {
        paddingLeft: halfSpacing,
        marginRight: -12,
      },
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        height: '0.625rem',
        borderRadius: shape.borderRadius,
      },
      bar: {
        borderRadius: shape.borderRadius,
      },
      colorPrimary: {
        backgroundColor: bluePlanetPalette.grey.light,
      },
      barColorPrimary: {
        backgroundColor: bluePlanetPalette.blue.main,
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },

  MuiChip: {
    styleOverrides: {
      root: {
        fontFamily: FontRedHat.text,
        fontWeight: 500,
        lineHeight: 1,
        letterSpacing: '0.004em',
        height: '1.5rem',
        fontSize: '0.6875rem',
        borderRadius: shape.borderRadius,
        padding: 0,
        cursor: 'inherit',
      },
      colorPrimary: {
        backgroundColor: bluePlanetTheme.palette.primary.main,
        color: bluePlanetPalette.offWhite,
      },
      label: {
        textTransform: 'uppercase',
      },
      avatar: {
        width: '1.375rem',
        height: '1.375rem',
        margin: '-0.3125rem -0.5rem -0.25rem 0',
        marginRight: '-0.5rem',
        backgroundColor: 'transparent',
      },
      avatarColorPrimary: {
        backgroundColor: 'transparent',
        color: bluePlanetPalette.offWhite,
      },
      avatarColorSecondary: {
        backgroundColor: 'transparent',
      },
    },
  },

  MuiButton: {
    styleOverrides: {
      root: {
        minWidth: '67px',
        padding: '10px 16px',
        transition: bluePlanetTheme.transitions.create(['background-color', 'border', 'color', 'opacity'], {
          duration: bluePlanetTheme.transitions.duration.short,
        }),
        boxShadow: 'none !important',
      },
      sizeLarge: {
        padding: '13px 15px',
        minWidth: '100px',
        fontSize: bluePlanetTheme.typography.pxToRem(17),
      },
      outlined: {
        padding: '8px 14px',
        borderWidth: '2px',
        '&:disabled': {
          borderWidth: '2px',
        },
        '&.Mui-focusVisible::after': {
          content: '""',
          position: 'absolute',
          top: '-6px',
          bottom: '-6px',
          right: '-6px',
          left: '-6px',
          borderRadius: shape.borderRadius + '4px',
          borderWidth: '2px',
          borderStyle: 'solid',
          borderColor: bluePlanetPalette.grey.main,
        },
      },
      contained: {
        '&.Mui-focusVisible::after': {
          content: '""',
          position: 'absolute',
          top: '-4px',
          bottom: '-4px',
          right: '-4px',
          left: '-4px',
          borderRadius: shape.borderRadius + '4px',
          borderWidth: '2px',
          borderStyle: 'solid',
          borderColor: bluePlanetPalette.grey.main,
        },
      },
    },
  },
  MuiSnackbar: {
    styleOverrides: {
      anchorOriginBottomRight: {
        top: 0,
        bottom: 'auto',
        alignItems: 'flex-end',
        [bluePlanetTheme.breakpoints.up('sm')]: {
          top: 'auto',
          bottom: 20,
          right: INTERCOM_CHAT_BUBBLE_OFFSET + 20,
        },
        [bluePlanetTheme.breakpoints.down('sm')]: {
          top: '6px',
        },
      },
    },
  },

  MuiInput: {
    styleOverrides: {
      root: {
        fontFamily: RedHatFonts.text,
        fontSize: fontSizeMedium,
        fontWeight: 400,
        padding: spacing(1),
        color: bluePlanetPalette.indigo.main,
        backgroundColor: bluePlanetPalette.white,
        opacity: 1,
        border: `1px solid ${bluePlanetPalette.inputBorder}`,
        borderRadius: shape.borderRadius,
        transition: bluePlanetTheme.transitions.create('border-color', {
          duration: bluePlanetTheme.transitions.duration.standard,
          easing: bluePlanetTheme.transitions.easing.easeOut,
        }),

        '&.Mui-focused': {
          color: bluePlanetPalette.indigo.main,
          borderColor: bluePlanetPalette.grey.main,
        },
        '&.Mui-error': {
          borderColor: bluePlanetPalette.red.main,
          color: bluePlanetPalette.red.main,
        },
        '&.Mui-focused&.Mui-error': {
          borderColor: bluePlanetPalette.indigo.light,
          color: bluePlanetPalette.indigo.main,
        },
        '&.Mui-disabled': {
          color: bluePlanetPalette.grey.main,
        },
      },
      input: {
        '&::-moz-placeholder': {
          opacity: '1',
          fontWeight: 'normal',
          color: bluePlanetTheme.bluePlanetPalette.grey.main,
        }, // Firefox 19+
        '&::-webkit-input-placeholder': {
          opacity: '1',
          fontWeight: 'normal',
          color: bluePlanetTheme.bluePlanetPalette.grey.main,
        },
        padding: 0,
      },
      underline: {
        '&:before': {
          content: 'none',
        },
        '&:after': {
          content: 'none',
        },
      },
      focused: {},
      formControl: {
        'label + &': {
          marginTop: 0,
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      inputRoot: {
        padding: spacing(1),
        gap: quarterSpacing,
      },
      paper: {
        boxShadow: bluePlanetTheme.shadows[5],
      },
      endAdornment: {
        marginRight: spacing(1),
      },
      tag: {
        margin: 0,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        marginLeft: spacing(1),
        marginBottom: spacing(1),
        whiteSpace: 'unset',
      },
      formControl: {
        position: 'static',
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        marginLeft: 0,
        paddingLeft: 0,
        fontWeight: 600,
        textTransform: 'uppercase',
        fontFamily: FontRedHat.display,
        letterSpacing: '0.02em',
        fontSize: fontSizeTiny,
        color: bluePlanetPalette.grey.dark,
        '&.Mui-focused': {
          color: bluePlanetPalette.indigo.main,
        },
        '&.Mui-error': {
          color: bluePlanetPalette.indigo.medium,
        },
        '&.Mui-focused&.Mui-error': {
          color: bluePlanetPalette.indigo.main,
        },
        '&.Mui-disabled': {
          color: bluePlanetPalette.grey.dark,
        },
        '&.MuiInputLabel-root': {
          marginLeft: 0,
        },
      },
      error: {},
      focused: {},
      disabled: {},
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        margin: '0 0 0 0',
        [bluePlanetTheme.breakpoints.down('sm')]: {
          width: 'calc(100%)',
          maxWidth: `calc(100% - ${contentSpacing})`,
        },
        '&.MuiDialog-paperScrollBody': {
          [bluePlanetTheme.breakpoints.down('sm')]: {
            width: 'calc(100%)',
            maxWidth: `calc(100% - ${contentSpacing})`,
          },
        },
        '&.MuiDialog-paperFullScreen': {
          maxWidth: '100%',
        },
      },
    },
  },
  MuiBackdrop: {
    styleOverrides: {
      root: {
        backgroundColor: getBackdropColor('light'),
        '&.MuiBackdrop-invisible': {
          backgroundColor: getBackdropColor('transparent'),
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        transition:
          'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 300ms cubic-bezier(0.4, 0, 0.2, 1)',
      },
      elevation1: { boxShadow: bluePlanetTheme.shadows[1] },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        padding: sectionSpacing,
        fontSize: bluePlanetTheme.typography.h5.fontSize,
        position: 'relative',
        fontWeight: bluePlanetTheme.typography.fontWeightMedium,
        [bluePlanetTheme.breakpoints.down('sm')]: {
          padding: contentSpacing,
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: `0 ${sectionSpacing}`,
        fontFamily: FontRedHat.text,
        fontWeight: bluePlanetTheme.typography.fontWeightRegular,
        fontSize: fontSizeMedium,
        lineHeight: 1.67,
        overflowY: 'initial',
        [bluePlanetTheme.breakpoints.down('sm')]: {
          padding: `0 ${contentSpacing}`,
        },
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: bluePlanetPalette.grey.main,
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: bluePlanetPalette.grey.dark,
        },
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        borderRadius: '0 0 8px 8px',
        padding: sectionSpacing,
        [bluePlanetTheme.breakpoints.down('sm')]: {
          padding: contentSpacing,
        },
        '> :not(:first-of-type)': {
          marginLeft: spacing(0.5),
        },
      },
    },
  },

  MuiMenu: {
    styleOverrides: {
      root: {
        padding: contentSpacing,
      },
      list: {
        padding: `${halfSpacing} 0`,
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      primary: {
        fontWeight: '400',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        padding: `${halfSpacing} ${contentSpacing}`,
        fontWeight: 400,
        '&:hover': {
          backgroundColor: bluePlanetPalette.grey.light,
        },
        '&:focus-visible': {
          backgroundColor: bluePlanetPalette.grey.light,
        },
        '&.Mui-selected': {
          fontWeight: typography.fontWeightMedium,
          backgroundColor: 'transparent',
        },
        '&.Mui-selected:hover': {
          backgroundColor: 'transparent',
          cursor: 'default',
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        paddingLeft: spacing(1),
        marginLeft: 0,
        marginRight: 0,
        transition: bluePlanetTheme.transitions.create('color', {
          duration: bluePlanetTheme.transitions.duration.shorter,
        }),
        '&.Mui-disabled': {
          color: 'inherit',
        },
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        '.MuiAvatar-fallback': {
          background: `url(${staticFileLocations.defaultProfileImage}) center no-repeat`,
          backgroundSize: '100% 100%',
          width: '100%',
          height: '100%',
          path: {
            display: 'none',
          },
        },
      },
    },
  },
  MuiAvatarGroup: {
    styleOverrides: {
      avatar: {
        backgroundColor: bluePlanetPalette.indigo.medium,
        fontWeight: typography.fontWeightBold,
        fontSize: fontSizeSmall,
        borderColor: bluePlanetTheme.bluePlanetPalette.grey.medium,
        borderWidth: 2,
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        '&:before': {
          opacity: 0,
        },
        '&.Mui-expanded': {
          margin: 0,
        },
      },
      rounded: {
        borderRadius: '4px',
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        borderRadius: '4px',
        fontSize: bluePlanetTheme.typography.body1.fontSize,
        backgroundColor: 'transparent',
        lineHeight: 1.67,
        padding: contentSpacing,
        '&.Mui-expanded': {
          outline: 'none',
        },
      },
      content: {
        fontWeight: 500,
        alignItems: 'center',
        margin: 0,
        '&.Mui-expanded': {
          margin: 0,
        },
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        borderTop: `2px solid transparent`,
        padding: contentSpacing,
        marginTop: '2px', // border top + margin top should equal spacing.quarter
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      colorPrimary: {
        '&.Mui-disabled': {
          color: bluePlanetPalette.blue.medium,
        },
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        margin: 0,
        '& .MuiCheckbox-root': {
          marginLeft: '-' + spacing(1),
        },
      },
      label: {
        fontWeight: bluePlanetTheme.typography.fontWeightMedium,
        fontSize: bluePlanetTheme.typography.body1.fontSize,
        '&.Mui-disabled': {
          color: alpha(bluePlanetPalette.indigo.main, 0.85),
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: bluePlanetPalette.indigo.main,
        fontFamily: FontRedHat.text,
        fontSize: fontSizeSmall,
        fontWeight: typography.fontWeightRegular,
        color: bluePlanetPalette.white,
        padding: halfSpacing,
        borderRadius: '4px',
      },
      arrow: {
        '&:before': { backgroundColor: bluePlanetPalette.indigo.main },
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: { marginLeft: 0, marginRight: 0 },
      switchBase: {
        color: bluePlanetPalette.grey.main,
        '&.Mui-checked': {
          '& + .MuiSwitch-track': {
            opacity: 0.3,
          },
        },
        '&.Mui-disabled': {
          color: bluePlanetPalette.disabled,
          '& + .MuiSwitch-track': {
            opacity: 0.1,
          },
        },
      },
      thumb: {
        height: '21px',
        width: '21px',
      },
      track: {
        marginTop: '3px',
        marginLeft: '3px',
        backgroundColor: bluePlanetPalette.grey.main,
        borderRadius: '9px',
        opacity: 0.2,
        height: '9px',
        width: '30px',
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        '&.Mui-selected': { backgroundColor: alpha(bluePlanetPalette.blue.veryLight ?? '', 0.5) },
      },
    },
  },
};

export default bluePlanetTheme;
