import React from 'react';
import ContactMeButton from 'domain/users/ContactMeButton';

export default function InvestorMatching({
  companyId,
  userWantsToBeContacted,
}: {
  companyId: number;
  userWantsToBeContacted?: boolean;
}) {
  return (
    <div className="u-flex u-flex--column u-flex--gap-half">
      <h4 className="text-h4">Investor matching</h4>
      <span>
        We will distribute your company pitch to investors in our network looking for companies just like yours. Based
        on the data you have provided, we can match you with a relevant selection of investors who will review your case
        and follow you if they are interested. Prices from €2500.
      </span>
      <ContactMeButton
        userWantsToBeContacted={userWantsToBeContacted}
        tag={`wants-investor-matching-${companyId}`}
        feedback="InterestedInInvestorMatchingFundingRound"
        companyId={companyId}
      />
    </div>
  );
}
