import React, { useEffect, useState } from 'react';
import Button from 'ui/elements/buttons/Button';
import { Content, DialogActions } from 'ui/views/dialogs/Dialog';
import useLazyResource from 'util/resource/useLazyResource';
import useNotify from 'hooks/useNotify';
import ButtonList from 'ui/elements/buttons/ButtonList';
import { USER_WIZARD_CONTENT_MIN_HEIGHT } from './UserOnboardingWizard';
import CompanyRegistrationForm from 'pages/Registration/common/CompanyRegistrationForm';
import { NewEntityDTO } from 'pages/Registration/common/actions';
import { companiesApi } from 'apis/CompanyAPI/companies/companiesApi';
import { invalidate } from 'hooks/useSWR';
import { companiesKey } from 'apis/CompanyAPI/companies/useCompanies';
import { userDashboardKey } from 'pages/Dashboard/useUserDashboardData';
import { companyProfileKey } from 'apis/CompanyAPI/companies/useCompanyProfile';
import { CompanyProfile } from 'types/company';
import { JoinCommunityAs } from 'apis/CompanyAPI/communities/communitiesApi';
import { communityAccessKey } from 'apis/CompanyAPI/companies/useCommunityAccess';
import { communityKey } from 'apis/CompanyAPI/communities/useCommunity';
import { invalidateCommunities } from 'apis/CompanyAPI/communities/useCommunities';
import ToggleButton, { ToggleButtonGroup } from 'ui/elements/form/choice/ToggleButton';
import { CommunityInviteDetails } from 'types/company/community';
import { useLoginState } from 'auth/useLoginWithRedirect';
import { isInnovasjonNorge } from 'domain/communities/InnovasjonNorge/utils';
import OnboardingWalkthrough from 'pages/Registration/Company/OnboardingWalkthrough';
import useRoute from 'hooks/useRoute';
import featureToggle from 'featureToggle';
import { communityUrls, companyUrls } from 'urls';
import { onboardingApiUrls, OnboardingWizardStatus } from 'apis/OnboardingAPI';
import { onboardinWizardStatusKey } from 'pages/Onboarding/InitialLoadWrapper';
import OrganizationAPI from 'apis/OrganizationAPI';
import { organizationKey } from 'apis/OrganizationAPI/organizations/useOrganizations';
import { WizardType } from 'pages/Dashboard/Dashboard';
import { IResource } from 'util/resource';
import { saveUser } from 'actions/userProfiles';
interface Props {
  onStart?: () => void;
  requestToJoinCommunityAsUser: (joinAs: JoinCommunityAs) => Promise<IResource<void>>;
  isRequesting: boolean;
  invite?: CommunityInviteDetails;
  onShowWizard: (wizard: WizardType) => void;
}
type Role = 'company' | 'investor' | 'mentor-or-advisor' | 'organization';

export default function ChooseYourRole({
  requestToJoinCommunityAsUser,
  isRequesting,
  onStart,
  onShowWizard,
  invite,
}: Props) {
  const notify = useNotify();

  const canChooseRole = !invite || invite.invitedAs === 'NotSpecified';

  const [showError, setShowError] = useState(false);
  const [role, setRole] = useState<Role | null>(
    invite?.invitedAs === 'Company'
      ? 'company'
      : invite?.invitedAs === 'Advisor' || invite?.invitedAs === 'Mentor'
        ? 'mentor-or-advisor'
        : invite?.invitedAs === 'Investor'
          ? 'investor'
          : invite?.invitedAs === 'Organization'
            ? 'organization'
            : null,
  );

  const onRoleChange = (role: 'company' | 'investor' | 'mentor-or-advisor' | 'organization') => {
    setRole(role);
    setShowError(false);
  };

  const { loginState, reset: resetLoginState } = useLoginState();
  const { push } = useRoute();

  const joinAs: JoinCommunityAs | undefined =
    role === 'investor' ? { type: 'Investor' } : role === 'mentor-or-advisor' ? { type: 'Advisor' } : undefined;
  const communitySlug = invite?.community.slug || loginState.joinCommunity?.slug;

  const [onRegisterCompany, isRegisteringCompany] = useLazyResource(
    (newCompany: NewEntityDTO) =>
      companiesApi.registration.registerCompany(
        undefined,
        {
          company: {
            companyName: newCompany.name,
            isCommunity: false,
          },
          originUrl: window.location.toString(),
        },
        {
          communityCode: invite?.inviteCode,
          communitySlug,
        },
      ),
    {
      onSuccess: result => {
        resetLoginState();
        // preload company profile. This prevents  a new loading screen when changing to the company wizard
        invalidate<CompanyProfile>(companyProfileKey(result.company.slug), {
          ...result.company,
          hasPublicPitch: true,
          industries: { values: [] },
          stages: { values: [] },
          connectionCount: 0,
          lastUpdated: new Date().toISOString(),
        });
        invalidate(userDashboardKey);
        invalidate(companiesKey);
        if (communitySlug) {
          invalidate(communityAccessKey(communitySlug));
          invalidate(communityKey(communitySlug));
        }

        //  we need to refetch these to show correct data on the dashboard
        invalidateCommunities();
        if (invite && featureToggle.isMentorCommunity(invite?.community.id)) {
          invalidate<OnboardingWizardStatus>(onboardinWizardStatusKey, { status: 'Completed' });
          push(companyUrls.innovasjonNorge.mentee.overview(result.company.slug, invite?.community.id));
        } else if (invite?.hasApplication) {
          invalidate<OnboardingWizardStatus>(onboardinWizardStatusKey, { status: 'Completed' });
          push(communityUrls.overview(invite.community.slug));
        }
        onShowWizard({ type: 'company', company: result.company });
      },
      onFailure: error => notify('error', error || `Failed to register company.`),
    },
  );

  const [onRegisterOrganization, isRegisteringOrganization] = useLazyResource(
    (newOrganization: NewEntityDTO) =>
      OrganizationAPI.registerOrganization(
        undefined,
        {
          organization: {
            organizationName: newOrganization.name,
          },
          originUrl: window.location.toString(),
        },
        {
          communityCode: invite?.inviteCode,
          communitySlug,
        },
      ),
    {
      onSuccess: result => {
        resetLoginState();
        invalidate(organizationKey);
        invalidate(userDashboardKey);
        invalidate(onboardingApiUrls.getUserOnboarding);
        if (communitySlug) {
          invalidate(communityAccessKey(communitySlug));
          invalidate(communityKey(communitySlug));
        }

        //  we need to refetch these to show correct data on the dashboard
        invalidateCommunities();
        invalidate<OnboardingWizardStatus>(onboardinWizardStatusKey, {
          company: undefined,
          organization: { ...result.organization },
          status: 'InProgress',
        });
        onShowWizard({ type: 'organization', organization: result.organization });
      },
      onFailure: error => notify('error', error || `Failed to register organization.`),
    },
  );

  useEffect(() => {
    onStart?.();
  }, [onStart]);

  const canSelectCompany = invite ? invite.companyMembersIsEnabled : true;

  return (
    <>
      <Content style={{ minHeight: USER_WIZARD_CONTENT_MIN_HEIGHT }}>
        {invite && isInnovasjonNorge(invite?.community.communityType) && (
          <OnboardingWalkthrough communityType={invite.community.communityType} />
        )}
        {canChooseRole && (
          <ToggleButtonGroup>
            <ToggleButton
              isSelected={role === 'investor'}
              title="Investor"
              description="I am an investor or scouting for an investment firm"
              onClick={() => onRoleChange('investor')}
            />
            <ToggleButton
              title="Corporate or Organization"
              description="I represent an established company or organization that works with- or invests in startups"
              isSelected={role === 'organization'}
              onClick={() => onRoleChange('organization')}
            />
            {canSelectCompany && (
              <ToggleButton
                title="Start-up or Scale-up"
                description="I am the founder or represent a company that is looking to network & secure funding"
                isSelected={role === 'company'}
                onClick={() => onRoleChange('company')}
              />
            )}
            <ToggleButton
              title="Advisor or mentor"
              description="I am mentoring or act as an advisor for early phase companies"
              isSelected={role === 'mentor-or-advisor'}
              onClick={() => onRoleChange('mentor-or-advisor')}
            />
          </ToggleButtonGroup>
        )}

        {role === 'company' && (
          <div className="u-content-spacing-top u-section-spacing-bottom">
            <CompanyRegistrationForm
              registerAs="company"
              isSaving={isRegisteringCompany}
              submitButtonText="Next"
              onRegisterNew={onRegisterCompany}
              autoFocus
            />
          </div>
        )}

        {role === 'organization' && (
          <div className="u-content-spacing-top u-section-spacing-bottom">
            <CompanyRegistrationForm
              registerAs="organization"
              isSaving={isRegisteringOrganization}
              submitButtonText="Next"
              onRegisterNew={onRegisterOrganization}
              autoFocus
            />
          </div>
        )}
        {showError && <p className="u-half-spacing-top text-error">Please choose your role</p>}
      </Content>
      {role !== 'company' && role !== 'organization' && (
        <DialogActions align="space-between">
          <ButtonList>
            <Button
              onClick={() => {
                if (!role) {
                  setShowError(true);
                } else {
                  saveUser({
                    isInvestor: role === 'investor',
                  }).then(() => {
                    if (joinAs) {
                      requestToJoinCommunityAsUser(joinAs);
                    }
                  });
                }
              }}
              kind="primary"
              isLoading={isRequesting}
              className="track-investor-onboarding-role-continue track-wizard-step-continue"
            >
              Next
            </Button>
          </ButtonList>
        </DialogActions>
      )}
    </>
  );
}
