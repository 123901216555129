import { Author, asCompanyAuthor, asUserAuthor } from 'domain/shared/author';
import React, { useState } from 'react';
import { CommunityUpdate } from 'types/content';
import { UserProfile } from 'types/user';
import EditUpdateDialog from './EditUpdateDialog';
import { ICompany } from 'types/company';

import { getOrUndefined } from 'util/resource';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import ShowUpdate, { ShownIn, ViewType } from 'domain/Updates/shared/Show';
import FollowButton from 'domain/companies/Follow/FollowButton';
import { Hidden } from '@mui/material';
import { communityUpdateAPI } from 'apis/ContentAPI/UpdateAPI';
import UserUpdateMenu from 'domain/Updates/shared/Show/Menu/UserUpdateMenu';
import CompanyUpdateMenu from 'domain/Updates/shared/Show/Menu/CompanyUpdateMenu';
import { TinyCommunity } from 'types/company/community';
import CommunityUpdateMenu from 'domain/Updates/shared/Show/Menu/CommunityUpdateMenu';
import { useCommunitySummary } from 'apis/CompanyAPI/communities/useCommunitySummary';
import { communityUrls } from 'urls';

interface Props {
  className?: string;
  update: CommunityUpdate;
  creator?: UserProfile;
  author?: Author;
  community: TinyCommunity;
  postedBy: PostedBy;
  shownIn: ShownIn;
  onArchive: (contentId: string) => void;
  onDelete?: () => void;
  canEditPost: boolean;
  onEdit: () => void;
  canPinToFeed?: boolean;
  viewType?: ViewType;
  headingActions?: JSX.Element;
}

export type PostedBy =
  | { type: 'community' }
  | { type: 'user'; userProfile?: UserProfile }
  | { type: 'company'; company?: ICompany; viewCompanyHref?: string };

export default function Update(props: Props) {
  const { update, creator, community, postedBy } = props;
  const [editUpdate, setEditUpdate] = useState(false);
  const [isPinned, setPinned] = useState<boolean>(update.isPinned || false);
  const { resource: userProfileResource } = useSelfUserProfile();
  const userProfile = getOrUndefined(userProfileResource);
  const [viewType, setViewType] = useState<ViewType>(props.viewType || 'preview');

  const { resource: communitySummary } = useCommunitySummary(community.slug);

  const isTopicsEnabled = getOrUndefined(communitySummary)?.configuration.updateTopicsIsEnabled;

  const updateLink = `${location.origin}${communityUrls.update.view(props.community.slug, props.update.update.id)}`;

  const updateIsPinned = (isPinned: boolean) => {
    setPinned(isPinned);
    props.onEdit();
  };

  const author = () => {
    if (postedBy.type === 'company') {
      return asCompanyAuthor(creator, postedBy.company, postedBy.viewCompanyHref);
    } else if (postedBy.type === 'user') {
      return asUserAuthor(postedBy.userProfile);
    }
    return asCompanyAuthor(creator, community);
  };

  const api = communityUpdateAPI(community.id);

  return (
    <>
      <ShowUpdate
        className={props.className}
        api={api}
        update={props.update.update}
        author={props.author ?? author()}
        creator={props.creator}
        updateContext={{
          type: 'community',
          community: community,
          visibility: update.visibility,
          topic: isTopicsEnabled ? update.topic : undefined,
        }}
        shownIn={props.shownIn}
        viewType={viewType}
        canEditUpdate={props.canEditPost}
        showIsPinned={isPinned}
        actions={
          <>
            {postedBy.type === 'company' && postedBy.company && (
              <Hidden smDown>
                {/* Users can still follow trough the menu on the update */}
                <FollowButton
                  mode="compact"
                  company={postedBy.company}
                  kind="tertiary"
                  color="indigo"
                  trackingKey="community-update-request-to-follow"
                />
              </Hidden>
            )}
            {postedBy.type === 'user' && (
              <UserUpdateMenu
                api={api}
                update={update.update}
                updateUrl={updateLink}
                onEdit={() => setEditUpdate(true)}
                canEditPost={props.canEditPost}
                onArchive={props.onArchive}
                onDelete={props.onDelete}
              />
            )}
            {postedBy.type === 'community' && (
              <CommunityUpdateMenu
                api={api}
                update={update.update}
                updateUrl={updateLink}
                company={community}
                authorId={userProfile?.cwUserId !== creator?.cwUserId ? creator?.cwUserId : undefined}
                onEdit={() => setEditUpdate(true)}
                canEditPost={props.canEditPost}
                onArchive={props.onArchive}
                onDelete={props.onDelete}
                setPinned={updateIsPinned}
                isPinned={isPinned}
                canPinToFeed={props.canPinToFeed}
              />
            )}
            {postedBy.type === 'company' && postedBy.company && (
              <CompanyUpdateMenu
                canViewProfile
                api={api}
                update={update.update}
                updateUrl={updateLink}
                company={postedBy.company}
                authorId={userProfile?.cwUserId !== creator?.cwUserId ? creator?.cwUserId : undefined}
                onEdit={() => setEditUpdate(true)}
                canEditPost={props.canEditPost}
                onArchive={props.onArchive}
                onDelete={props.onDelete}
                setPinned={updateIsPinned}
                isPinned={isPinned}
                canPinToFeed={props.canPinToFeed}
                trackingKey="community-update-request-to-follow"
              />
            )}

            {props.headingActions}
          </>
        }
      />
      {editUpdate && (
        <EditUpdateDialog
          community={community}
          setActive={setEditUpdate}
          update={props.update}
          user={userProfile}
          postedBy={postedBy}
          onComplete={() => {
            props.onEdit();
            setViewType('read more');
            setEditUpdate(false);
          }}
        />
      )}
    </>
  );
}
