import { FormControl, FormHelperText } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import Button from 'ui/elements/buttons/Button';
import Checkbox from 'ui/elements/form/choice/Checkbox';
import ControlledTextField from 'ui/elements/form/formik/FormikTextField';
import FormRow from 'ui/elements/form/FormRow';
import { staticFileLocations } from 'urls';
import { capitalize } from 'util/stringUtils';
import * as yup from 'yup';
import { NewEntityDTO } from './actions';

export default function CompanyRegistrationForm({
  onRegisterNew,
  registerAs,
  isSaving,
  submitButtonText,
  autoFocus,
}: {
  onRegisterNew: (values: NewEntityDTO) => void;
  registerAs: 'company' | 'community' | 'organization';
  isSaving: boolean;
  submitButtonText?: string;
  autoFocus: boolean;
}) {
  const newCompanySchema = yup.object().shape({
    name: yup.string().required(`Enter the name of your ${registerAs}.`),
    acceptedTerms: yup
      .boolean()
      .oneOf(
        [true],
        `In order to create a${registerAs === 'organization' ? 'n' : ''} ${registerAs} profile you must accept our data processing agreement.`,
      ),
  });
  const formik = useFormik({
    initialValues: {
      name: '',
      acceptedTerms: false,
    },
    onSubmit: onRegisterNew,
    validationSchema: newCompanySchema,
    validateOnChange: true,
    validateOnBlur: false,
  });

  return (
    <>
      <FormRow>
        <ControlledTextField
          name="name"
          label={`${capitalize(registerAs)} name`}
          formikProps={formik}
          placeholder="Kramerica industries"
          autoFocus={autoFocus}
          autoComplete="organization"
        />
      </FormRow>
      <FormRow>
        <FormControl required error={!!formik.errors.acceptedTerms && !!formik.touched.acceptedTerms}>
          <Checkbox
            name="acceptedTerms"
            onChange={formik.handleChange}
            checked={!!formik.values.acceptedTerms}
            color="primary"
            fontSize="small"
            label={
              <span>
                I agree to the{' '}
                <a
                  href={staticFileLocations.dataProcessingAgreement}
                  className="text-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  data processing agreement
                </a>
              </span>
            }
          />
          <FormHelperText>{(formik.touched.acceptedTerms && formik.errors.acceptedTerms) ?? ' '}</FormHelperText>
        </FormControl>
      </FormRow>
      <div className="u-content-spacing-top">
        <Button kind="primary" isLoading={isSaving} onClick={formik.submitForm}>
          {submitButtonText || `Create ${registerAs}`}
        </Button>
      </div>
    </>
  );
}
