import * as yup from 'yup';

// this is the value the rich text editor gets if a user types something
// then removes everything.
export const emptyQuillContent = '<p><br></p>';

export const updateValidationSchema = yup.object().shape({
  content: yup
    .string()
    .required('Did you forget to write a story?')
    .notOneOf(['<p><br></p>', '<p><br/></p>', '<p><br /></p>'], 'Did you forget to write a story?'),
  structuredContent: yup.object().shape({ ops: yup.array() }).required('Did you forget to write a story?'),
});

function getTotalTextLength(node: Node): number {
  let length = 0;
  if (node.nodeType === Node.TEXT_NODE && node.textContent) {
    length += node.textContent.length;
  } else {
    node.childNodes.forEach(child => {
      length += getTotalTextLength(child);
    });
  }
  return length;
}

// Truncate text content to a certain character limit
function truncateTextContent(node: Node, limit: number) {
  if (node.nodeType === Node.TEXT_NODE && node.textContent) {
    if (node.textContent.length > limit) {
      const truncatedText = node.textContent.slice(0, limit) + ' ... ';

      const readMoreSpan = document.createElement('span');
      readMoreSpan.className = 'text-link';
      readMoreSpan.textContent = 'Read more';

      const fragment = document.createDocumentFragment();
      fragment.appendChild(document.createTextNode(truncatedText));
      fragment.appendChild(readMoreSpan);
      if (node.parentNode) {
        if (node.parentNode.nodeName === 'A') {
          // If the text node is a child of an <a> element, wrap the <a> element and the "Read more" link in a <span>
          // This way the read more text will not be a part of the link
          readMoreSpan.textContent = ' ... Read more';
          const wrapperSpan = document.createElement('span');
          node.parentNode.parentNode?.replaceChild(wrapperSpan, node.parentNode);
          wrapperSpan.appendChild(node.parentNode);
          wrapperSpan.appendChild(readMoreSpan);
        } else {
          node.parentNode.replaceChild(fragment, node);
        }
      }
    }
    limit -= node.textContent.length;
  } else {
    const childNodes = Array.from(node.childNodes);
    for (const child of childNodes) {
      if (limit <= 0) {
        node.removeChild(child);
      } else {
        limit = truncateTextContent(child, limit);
      }
    }
  }
  return limit;
}

function findLastTextNode(node: Node): Node | null {
  if (node.nodeType === Node.TEXT_NODE && node.textContent) {
    return node;
  } else {
    for (let i = node.childNodes.length - 1; i >= 0; i--) {
      const child = node.childNodes[i];
      const textNode = findLastTextNode(child);
      if (textNode) {
        return textNode;
      }
    }
  }
  return null;
}
export const richTextContentPreviewParser = (content: string, charLimit: number, attachmentCount: number) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');

  // Capture all <img> elements
  const imgElements = doc.querySelectorAll('img');
  const imgUrls: string[] = [];

  imgElements.forEach(img => {
    imgUrls.push(img.src);
    if (img.parentNode) {
      img.parentNode.removeChild(img);
    }
  });

  // Convert <blockquote> elements to <div> elements and add quotation marks
  const blockquoteElements = doc.querySelectorAll('blockquote');
  blockquoteElements.forEach(blockquote => {
    const quotedText = `"${blockquote.innerHTML.trim()}"`;
    const div = document.createElement('div');
    div.className = 'text-weight-medium';
    div.innerHTML = quotedText;
    if (blockquote.parentNode) {
      blockquote.parentNode.replaceChild(div, blockquote);
    }
  });

  // Remove all <div> elements with class "iframely" and save the first one
  const iframelyElements = doc.querySelectorAll('div.iframely');

  let firstEmbed: string | undefined;
  iframelyElements.forEach((iframely, index) => {
    if (index === 0) {
      firstEmbed = iframely.outerHTML;
    }
    if (iframely.parentNode) {
      iframely.parentNode.removeChild(iframely);
    }
  });

  const totalTextLength = getTotalTextLength(doc.body);

  const hasCompanyEmbed = doc.querySelector('.ql-company-embed') !== null;

  if (totalTextLength > charLimit) {
    truncateTextContent(doc.body, charLimit);
  } else if (
    blockquoteElements.length > 0 ||
    iframelyElements.length > 1 ||
    imgElements.length > 0 ||
    attachmentCount > 0
  ) {
    // If the text content is shorter than the character limit (there is no read more button),
    // then add a "See full post" button conditionally.
    // This is to ensure that the original content can be expanded and viewed when it is different than the preview content.

    let lastTextNode = findLastTextNode(doc.body);
    if (!lastTextNode) {
      lastTextNode = doc.body.appendChild(document.createTextNode(''));
    }

    if (lastTextNode && lastTextNode.nodeType === Node.TEXT_NODE) {
      const readMoreSpan = document.createElement('span');
      readMoreSpan.className = 'text-link u-half-spacing-left';
      readMoreSpan.textContent = 'See full post';

      if (lastTextNode.parentNode) {
        if (lastTextNode.parentNode.nodeName === 'A') {
          const wrapperSpan = document.createElement('span');
          lastTextNode.parentNode.parentNode?.replaceChild(wrapperSpan, lastTextNode.parentNode);
          wrapperSpan.appendChild(lastTextNode.parentNode);
          wrapperSpan.appendChild(readMoreSpan);
        } else {
          lastTextNode.parentNode.appendChild(readMoreSpan);
        }
      }
    }
  }

  const previewHtmlString = doc.body.innerHTML;

  return { previewHtmlString, imgUrls, firstEmbed, hasCompanyEmbed };
};

export const stripContentAttachments = (content: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');

  doc.querySelectorAll('img').forEach(img => {
    if (img.parentNode) {
      img.parentNode.removeChild(img);
    }
  });

  doc.querySelectorAll('div.iframely').forEach(iframely => {
    if (iframely.parentNode) {
      iframely.parentNode.removeChild(iframely);
    }
  });

  return doc.body.innerHTML;
};
