import LoadableResource from 'util/resource/Resource';
import { daysAgo, norwegianDateWithTimestamp } from 'util/dateUtils';
import React, { useMemo, useState } from 'react';
import Card from 'ui/views/cards/Card';
import SectionHeading from 'ui/elements/SectionHeading';
import bluePlanetTheme from 'ui/theme/themeBluePlanet';
import DictionaryItem from 'ui/elements/dictionary/DictionaryItem';
import Dictionary from 'ui/elements/dictionary/Dictionary';
import Table from 'ui/elements/tables/Table/Table';
import useTableWithSort from 'ui/elements/tables/Table/useSorting';
import { BackofficeUserDetailsRoleDTO } from 'types/backoffice';
import { communityUrls, companyUrls } from 'urls';
import { Link } from 'react-router-dom';
import { ICompany } from 'types/company';
import useBackoffice from 'hooks/useBackoffice';
import EnvelopeIcon from 'ui/elements/icons/EnvelopeIcon';
import PopMenu from 'ui/modules/PopMenu';
import useDialogHandler from 'hooks/useDialogHandler';
import EmailEvents from './BackofficeUserEmails';
import BackofficeAPI from 'apis/BackofficeAPI';
import { isBefore } from 'date-fns';
import useResource from 'util/resource/useResource';
import { BackofficeUserDetailsDTO } from 'types/backoffice';
import UserIcon from 'ui/elements/icons/UserIcon';
import UserProfileDialog from 'domain/users/UserProfile/UserProfileDialog';
import TrashIcon from 'ui/elements/icons/TrashIcon';
import useNotify from 'hooks/useNotify';
import useLazyResource from 'util/resource/useLazyResource';
import { invalidate } from 'hooks/useSWR';

const analyticsTrackingStartDate = '2023-12-07';

interface Props {
  userId: number;
  cwUserId: UUID;
  className?: string;
}
export function BackofficeUserDetails({ cwUserId, userId, className }: Props) {
  const backoffice = useBackoffice();

  return backoffice.showBackofficeInfoOnProfiles ? (
    <Inner userId={userId} className={className} cwUserId={cwUserId} />
  ) : null;
}

function Inner({ userId, className, cwUserId }: Props) {
  const { resource: detailsResource } = useResource<BackofficeUserDetailsDTO>(BackofficeAPI.users.get(userId));
  const mailDialog = useDialogHandler(false);
  const [profileDialogIsOpen, setProfileDialogIsOpen] = useState(false);
  const notify = useNotify();

  const [requestDeletion, isRequestingDeletion] = useLazyResource(
    (userId: UUID) => BackofficeAPI.users.deletionRequests.post(userId),
    {
      onSuccess: () => {
        invalidate(BackofficeAPI.users.deletionRequests.get);
        notify('success', 'Request sent');
      },
      onFailure: () => notify('error', 'Could not request deletion'),
    },
  );

  return (
    <>
      <Card
        className={className ?? 'u-section-spacing-top'}
        style={{ backgroundColor: bluePlanetTheme.bluePlanetPalette.yellow.medium }}
      >
        <SectionHeading heading="Backoffice">
          <PopMenu
            items={[
              {
                icon: <EnvelopeIcon />,
                text: <>Emails</>,
                onClick: mailDialog.open,
              },
              {
                icon: <UserIcon />,
                text: <>View profile</>,
                onClick: () => setProfileDialogIsOpen(true),
              },
              {
                icon: <TrashIcon />,
                text: <>Request deletion</>,
                onClick: () => (!isRequestingDeletion ? requestDeletion(cwUserId) : undefined),
              },
            ]}
          />
        </SectionHeading>
        <LoadableResource resource={detailsResource}>
          {details => (
            <div>
              <Dictionary>
                <DictionaryItem label="Last login">
                  {daysAgo(details.lastLogin)}
                  {details.lastLogin && <> - {norwegianDateWithTimestamp(details.lastLogin)}</>}
                </DictionaryItem>
                <DictionaryItem label="Last visit">
                  {details.email.endsWith('@crowdworks.it') ? (
                    <>No visitor tracking for internal users</>
                  ) : (
                    <>
                      {details.lastLogin &&
                      !details.lastVisit &&
                      isBefore(new Date(details.lastLogin), new Date(analyticsTrackingStartDate)) ? (
                        <i>No visitor tracking before {analyticsTrackingStartDate}.</i>
                      ) : (
                        <>
                          {daysAgo(details.lastVisit)}
                          {details.lastVisit && <> - {norwegianDateWithTimestamp(details.lastVisit)}</>}
                        </>
                      )}
                    </>
                  )}
                </DictionaryItem>
                <DictionaryItem label="Signup URL">{details.signupUrl ?? 'No data'}</DictionaryItem>
                <DictionaryItem label="Epost">{details.email} </DictionaryItem>
              </Dictionary>

              <SectionHeading heading="Company Roles" className="u-content-spacing-top" />
              <RoleListing roles={details.companyRoles} href={entity => companyUrls.overview(entity.slug, 'profile')} />

              <SectionHeading heading="Community Roles" className="u-content-spacing-top" />
              <RoleListing roles={details.communityRoles} href={entity => communityUrls.overview(entity.slug)} />
            </div>
          )}
        </LoadableResource>
        {mailDialog.isOpen && <EmailEvents userId={userId} onClose={mailDialog.close} />}
      </Card>
      <UserProfileDialog
        isOpen={profileDialogIsOpen}
        onClose={() => setProfileDialogIsOpen(false)}
        cwUserId={cwUserId}
      ></UserProfileDialog>
    </>
  );
}

function RoleListing({ roles, href }: { roles: BackofficeUserDetailsRoleDTO[]; href: (entity: ICompany) => string }) {
  const { sortOptions, onSort } = useTableWithSort(undefined);
  const sortFn: {
    [key: string]: (a: BackofficeUserDetailsRoleDTO, b: BackofficeUserDetailsRoleDTO) => number;
  } = {
    entity: (a, b) => a.entity.name.localeCompare(b.entity.name),
    role: (a, b) => a.role.localeCompare(b.role),
    givenAt: (a, b) => a.roleGivenAt.localeCompare(b.roleGivenAt),
  };
  const sortedRoles = useMemo(() => {
    return sortOptions
      ? roles.sort((a, b) => {
          const result = sortFn[sortOptions.sortBy](a, b);
          return sortOptions.sortOrder === 'asc' ? result : result * -1;
        })
      : roles;
  }, [roles, sortOptions]);
  return (
    <Table isLoading={false} infoMessages={{ noResults: 'None' }} onSort={onSort} sortOptions={sortOptions}>
      <Table.Header>
        <Table.HeaderCell isSortable={true} sortBy="entity" style={{ width: '60%' }}>
          Name
        </Table.HeaderCell>
        <Table.HeaderCell isSortable={true} sortBy="role">
          Role
        </Table.HeaderCell>
        <Table.HeaderCell isSortable={true} sortBy="givenAt">
          Given at
        </Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {sortedRoles.map(role => (
          <Table.Row key={`${role.role}-${role.entity.id}`}>
            <Table.DataCell>
              <Link to={href(role.entity)} target="_blank">
                {role.entity.name}
              </Link>
            </Table.DataCell>
            <Table.DataCell>{role.role}</Table.DataCell>
            <Table.DataCell>
              <div className="u-align-left">{norwegianDateWithTimestamp(role.roleGivenAt)}</div>
            </Table.DataCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
