import { useMediaQuery, useTheme } from '@mui/material';
import { usersApi } from 'apis/CompanyAPI/users/usersApi';
import FollowButton from 'domain/companies/Follow/FollowButton';
import useDialogHandler from 'hooks/useDialogHandler';
import useNotify from 'hooks/useNotify';
import React, { useState } from 'react';
import { KeyedMutator } from 'swr';
import { List } from 'types/api';
import { Shortlist } from 'types/user';
import Button from 'ui/elements/buttons/Button';
import CompanyLogo from 'ui/elements/CompanyLogo';
import EyeIcon from 'ui/elements/icons/EyeIcon';
import IconButton from 'ui/elements/icons/IconButton';
import RemoveIcon from 'ui/elements/icons/RemoveIcon';
import AccordionWithButtons from 'ui/modules/Accordion/AccordionWithButtons';
import CardStack from 'ui/views/cards/CardStack';
import ClickableCard from 'ui/views/cards/ClickableCard';
import MaxWidth from 'ui/views/containers/MaxWidth';
import useLazyResource from 'util/resource/useLazyResource';
import { ShortlistDeleteDialog, ShortlistSettingsDialog, ShortlistShareDialog } from './ShortlistDialog';
import styles from './styles.scss';
import LastUpdated from 'domain/companies/profile/LastUpdated';
import { companyUrls, userUrls } from 'urls';
import { useHistory } from 'react-router';
import SettingsIcon from 'ui/elements/icons/SettingsIcon';
import ShareIcon from 'ui/elements/icons/ShareIcon';
import { useUpdateShortlist } from './utils';
import { updateShortlistInList } from 'apis/CompanyAPI/users/useShortlists';
import Chip from 'ui/elements/Chip';
import { notEmpty } from 'util/arrayUtils';
import GridIcon from 'ui/elements/icons/GridIcon';
import PopMenu from 'ui/modules/PopMenu';
import LinkAsButton from 'ui/elements/buttons/LinkAsButton';
import SendIcon from 'ui/elements/icons/SendIcon';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import CheckMarkCircleIcon from 'ui/elements/icons/CheckmarkCircleIcon';
import UserConversationDialog from 'domain/conversations/UserConversationDialog';
import Indicator from 'ui/elements/Indicator/NumberIndicator';
import Card from 'ui/views/cards/Card';
import { Link } from 'react-router-dom';
import useRoute from 'hooks/useRoute';
import LinkIcon from 'ui/elements/icons/LinkIcon';

interface Props {
  shortlist: Shortlist;
  setShortlists: KeyedMutator<List<Shortlist>>;
  isExpanded?: boolean;
}

export default function ShortlistAccordion({ shortlist, setShortlists, isExpanded }: Props) {
  const shortlistSettingsDialogHandler = useDialogHandler(false);
  const shortlistShareDialogHandler = useDialogHandler(false);
  const deleteShortlistDialogHandler = useDialogHandler(false);
  const removeCompanyDialogHandler = useDialogHandler(false);
  const shortlistChatDialogHandler = useDialogHandler();
  const [deleteId, setDeleteId] = useState<number>();
  const notify = useNotify();

  const { copy, showCopiedSuccess } = useCopyToClipboard(
    `${location.origin}${userUrls.collections.view(shortlist.id)}?utm_source=direct&utm_medium=copy-link&utm_campaign=collection`,
  );

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [updateList, isUpdatingList] = useUpdateShortlist({
    onSuccess: updatedShortlist => {
      setShortlists((allShortlists: List<Shortlist>) => updateShortlistInList(allShortlists, updatedShortlist), {
        revalidate: false,
      });
      notify('success', 'List updated');
      shortlistSettingsDialogHandler.close();
    },
    onFailure: error => {
      notify('error', error ?? 'Could not update the list');
    },
  });

  const [setShortlistPublic, isSettingShortlistPublic] = useUpdateShortlist({
    onSuccess: updatedShortlist => {
      setShortlists((allShortlists: List<Shortlist>) => updateShortlistInList(allShortlists, updatedShortlist), {
        revalidate: false,
      });
      if (updatedShortlist.isPublic) {
        navigator.clipboard.writeText(`${location.origin}${userUrls.collections.view(updatedShortlist.id)}`);
        notify('success', 'Collection link copied to clipboard');
      } else {
        notify('success', 'Collection has been set to private');
      }
      shortlistShareDialogHandler.close();
    },
    onFailure: () => {
      notify('error', 'Failed to make the list public');
    },
  });
  const { push } = useRoute();

  const [deleteList, isDeletingList] = useLazyResource((listId: UUID) => usersApi.shortlists.delete(listId), {
    onSuccess: deletedShortlistId => {
      setShortlists(
        (allShortlists: List<Shortlist>) =>
          allShortlists ? { values: allShortlists.values.filter(v => v.id !== deletedShortlistId) } : { values: [] },
        { revalidate: false },
      );
      deleteShortlistDialogHandler.close();
      notify('success', 'List deleted');
    },
    onFailure: message => notify('error', message || 'Unable to delete the list'),
  });

  const [updateListCompanies, isUpdatingListCompanies] = useLazyResource(
    ({ listId, companyIds }: { listId: UUID; companyIds: number[] }) =>
      usersApi.shortlists.updateCompanies(
        listId,
        companyIds.map(c => c.toString()),
      ),
    {
      onSuccess: updatedShortlist => {
        setShortlists((allShortlists: List<Shortlist>) => updateShortlistInList(allShortlists, updatedShortlist), {
          revalidate: false,
        });
        removeCompanyDialogHandler.close();
      },
      onFailure: message => notify('error', message || 'Could not update company list'),
    },
  );

  const shareDropdownItems = [
    { icon: <SendIcon />, text: <span>Share in chat</span>, onClick: () => shortlistChatDialogHandler.open() },
    {
      icon: showCopiedSuccess ? <CheckMarkCircleIcon color="green" /> : <LinkIcon />,
      text: <span>Copy link</span>,
      onClick: () => {
        copy();
        notify('success', 'Collection link copied to clipboard');
      },
    },
  ];

  const mobileDropdownItems = [
    {
      icon: <GridIcon />,
      text: <span className="u-ellipsis">Grid view</span>,
      onClick: () => push(userUrls.collections.view(shortlist.id)),
    },
    shortlist.isPublic
      ? { icon: <SendIcon />, text: <span>Share in chat</span>, onClick: () => shortlistChatDialogHandler.open() }
      : undefined,
    shortlist.isPublic
      ? {
          icon: showCopiedSuccess ? <CheckMarkCircleIcon color="green" /> : <LinkIcon />,
          text: <span>Copy link</span>,
          onClick: () => {
            copy();
            notify('success', 'Collection link copied to clipboard');
          },
        }
      : {
          icon: <ShareIcon />,
          text: <span>Share</span>,
          onClick: shortlistShareDialogHandler.open,
        },

    !!navigator.share && shortlist.isPublic
      ? {
          icon: <ShareIcon />,
          text: <span>More share options</span>,
          onClick: () => {
            navigator.share({
              title: shortlist.name,
              text: `Check out ${shortlist.name} on CrowdWorks!`,
              url: `${location.origin}${userUrls.collections.view(shortlist.id)}?utm_source=direct&utm_medium=native-share&utm_campaign=collection`,
            });
          },
        }
      : undefined,
    {
      icon: <SettingsIcon />,
      text: <span>Settings</span>,
      onClick: () => shortlistSettingsDialogHandler.open(),
    },
  ].filter(notEmpty);

  return (
    <MaxWidth width="md">
      <AccordionWithButtons
        title={
          <>
            <span className="u-ellipsis">{shortlist.name}</span>
            <Indicator value={shortlist.companies.length} color="grey" shape="square" className="u-half-spacing-left" />
          </>
        }
        buttons={
          isMobile
            ? [
                shortlist.isPublic
                  ? { element: <Chip color="blue-light" label="Public" />, onClick: () => null }
                  : undefined,
                {
                  element: (
                    <div onClick={e => e.stopPropagation()}>
                      <PopMenu items={mobileDropdownItems} />
                    </div>
                  ),
                  onClick: () => null,
                },
              ].filter(notEmpty)
            : [
                shortlist.isPublic
                  ? { element: <Chip color="blue-light" label="Public" />, onClick: () => null }
                  : undefined,
                {
                  element: (
                    <LinkAsButton color="grey" kind="tertiary" url={`${userUrls.collections.view(shortlist.id)}`}>
                      <GridIcon className="u-quarter-spacing-right" />
                      <span className="u-ellipsis">Grid view</span>
                    </LinkAsButton>
                  ),
                  onClick: () => null,
                },
                {
                  element: !shortlist.isPublic ? (
                    <Button kind="tertiary" color="grey">
                      <ShareIcon color="grey" className="u-quarter-spacing-right" />
                      <span>Share</span>
                    </Button>
                  ) : (
                    <div onClick={e => e.stopPropagation()}>
                      <PopMenu
                        items={shareDropdownItems}
                        renderAnchor={onClick => (
                          <Button onClick={onClick} kind="tertiary" color="grey">
                            <ShareIcon className="u-quarter-spacing-right" />
                            Share
                          </Button>
                        )}
                      />
                    </div>
                  ),
                  onClick: shortlist.isPublic ? () => null : () => shortlistShareDialogHandler.open(),
                },
                {
                  element: (
                    <Button kind="tertiary" color="grey">
                      <SettingsIcon color="grey" className="u-quarter-spacing-right" />
                      <span>Settings</span>
                    </Button>
                  ),
                  onClick: () => shortlistSettingsDialogHandler.open(),
                },
              ].filter(notEmpty)
        }
        paddingHeader="double"
        paddingContent="none"
        className={styles.accordion}
        isExpanded={isExpanded}
      >
        {shortlist.companies.length ? (
          <CardStack className="u-flex u-flex--column u-flex-grow">
            {shortlist.companies.map(company => (
              <ClickableCard
                key={`${shortlist.id} - ${company.id}`}
                isRounded
                avatar={<CompanyLogo size={40} company={company} />}
                title={
                  <span>
                    {company.name}
                    <LastUpdated companyId={company.id} lastUpdated={company.lastUpdated} />
                  </span>
                }
                padding={isMobile ? 'half' : 'default'}
                actions={
                  <div className="u-flex-align-center">
                    {!isSmallScreen && (
                      <FollowButton
                        kind="tertiary"
                        company={company}
                        size="medium"
                        trackingKey="shortlisted-company-follow"
                      />
                    )}
                    {!isMobile && (
                      <Button
                        className="u-content-spacing-left"
                        kind="tertiary"
                        color="grey"
                        onClick={() => history.push(companyUrls.overview(company.slug, 'updates'))}
                      >
                        <EyeIcon className="u-quarter-spacing-right" /> View profile
                      </Button>
                    )}
                    <div className="u-content-spacing-left">
                      <IconButton
                        color="grey"
                        hoverColor="red"
                        onClick={() => {
                          setDeleteId(company.id);
                          removeCompanyDialogHandler.open();
                        }}
                      >
                        <RemoveIcon strokeWidth={2} fontSize="small" />
                      </IconButton>
                    </div>
                  </div>
                }
                href={companyUrls.overview(company.slug, 'updates')}
              />
            ))}
          </CardStack>
        ) : (
          <CardStack>
            <Card className="text-metadata">
              This list is empty -{' '}
              <Link className="text-link-small" to={userUrls.discover}>
                go explore
              </Link>{' '}
              and find some interesting companies to follow!
            </Card>
          </CardStack>
        )}
      </AccordionWithButtons>
      {shortlistSettingsDialogHandler.isOpen && (
        <ShortlistSettingsDialog
          mode="edit"
          shortlist={shortlist}
          onClose={shortlistSettingsDialogHandler.close}
          onSubmit={shortlist => updateList({ id: shortlist.id, payload: shortlist })}
          onDelete={() => deleteShortlistDialogHandler.open()}
          isSaving={isUpdatingList}
        />
      )}
      {shortlistShareDialogHandler.isOpen && (
        <ShortlistShareDialog
          dialogHandler={shortlistShareDialogHandler}
          isPublic={shortlist.isPublic}
          onSubmit={() => setShortlistPublic({ id: shortlist.id, payload: { isPublic: !shortlist.isPublic } })}
          isSaving={isSettingShortlistPublic}
        />
      )}
      {deleteShortlistDialogHandler.isOpen && (
        <ShortlistDeleteDialog
          dialogHandler={deleteShortlistDialogHandler}
          content={
            <>
              Are you sure you would like to delete the list <strong>{shortlist.name}</strong>?
            </>
          }
          onConfirm={() => deleteList(shortlist.id)}
          title="Confirm delete"
          isSaving={isDeletingList}
        />
      )}
      {removeCompanyDialogHandler.isOpen && deleteId && (
        <ShortlistDeleteDialog
          dialogHandler={removeCompanyDialogHandler}
          content={
            <>
              Are you sure you would like to remove{' '}
              <strong>{shortlist.companies.find(c => c.id === deleteId)?.name}</strong> from the list{' '}
              <strong>{shortlist.name}</strong>?
            </>
          }
          title="Confirm delete"
          onConfirm={() =>
            updateListCompanies({
              listId: shortlist.id,
              companyIds: shortlist.companies.map(c => c.id).filter(id => id !== deleteId),
            })
          }
          isSaving={isUpdatingListCompanies}
        />
      )}
      {shortlistChatDialogHandler.isOpen && (
        <UserConversationDialog
          conversation={{ type: 'new' }}
          initialMessage={`${location.origin}${userUrls.collections.view(shortlist.id)}`}
          closeConversation={shortlistChatDialogHandler.close}
        />
      )}
    </MaxWidth>
  );
}
