import React from 'react';
import { getOrUndefined } from 'util/resource';
import Avatar from 'ui/elements/avatars/Avatar';
import { useSelfUserProfile } from 'apis/CompanyAPI/users/useSelfUserProfile';
import { CommunityLisItemDetailed } from 'types/company/community';
import AvatarGroup from 'ui/elements/avatars/AvatarGroup';
import { getDefaultProfileImage } from 'ui/domain/Logo';

export function MyCommunityMembershipStatus({
  community,
  style,
}: {
  community: CommunityLisItemDetailed;
  style?: React.CSSProperties;
}) {
  const founderOf = community.myCompanyMemberships.find(m => m.visibility === 'Visible')?.company;
  const pendingAsCompany = community.myCompanyMemberships.find(m => m.visibility === 'Pending')?.company;

  const roles = community.roles.filter(r => r !== 'company_admin_in_community');

  const { resource: userProfileResource } = useSelfUserProfile();
  const memberTexts = [
    founderOf && `${founderOf.name} is a member`,
    pendingAsCompany && `${pendingAsCompany.name} is pending approval`,
    roles.length > 0 && `You are ${roles.includes('company_master') ? 'an admin' : 'a member'}`,
    community.isPendingAsUser && 'You are pending approval',
  ]
    .filter(Boolean)
    .join(', ');

  return (
    <div style={style} className="u-flex u-flex-align-center u-flex--gap-half text-grey text-micro">
      <AvatarGroup max={3} size={24}>
        {founderOf && <Avatar borderColor="grey" size={24} imageUrl={founderOf.logoURL} userName={founderOf.name} />}
        {pendingAsCompany && (
          <Avatar borderColor="grey" size={24} imageUrl={founderOf?.logoURL || getDefaultProfileImage('company')} />
        )}
        {roles.length > 0 && (
          <Avatar
            borderColor="grey"
            size={24}
            imageUrl={getOrUndefined(userProfileResource)?.imageUrl}
            userName={getOrUndefined(userProfileResource)?.name}
          ></Avatar>
        )}

        {community.isPendingAsUser && (
          <Avatar
            size={24}
            borderColor="grey"
            imageUrl={getOrUndefined(userProfileResource)?.imageUrl}
            userName={getOrUndefined(userProfileResource)?.name}
          ></Avatar>
        )}
      </AvatarGroup>
      {memberTexts}
    </div>
  );
}
