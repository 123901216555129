import { useAuth0 } from '@auth0/auth0-react';
import { currentRelativePath } from './util';
import { emailIsValid } from 'util/stringUtils';
import { useRouteMatch } from 'react-router';
import useLocalStorage from 'hooks/useLocalStorage';
import featureToggle from 'featureToggle';
// This supports signup with redirect back to this page for new users
// We want to use this hook both for redirects to login and signup as the user can change their mind
// after they are redirected to auth0 signup/login screen
// If a user follows a normal login flow, 'appState' will handle the redirect.
// If a user signs up and have to verify their email, they will loose the appState as they will probably verify the email in a new tab, and continue browsing from it,
//so we need to fall back on localStorage to ensure they are redirect back to this page.

export interface LoginState {
  originUrl?: string;
  personalInviteToken?: string;
  joinCommunity?: {
    slug?: string;
    code?: string;
  };
  joinOrganization?: {
    slug?: string;
    code?: string;
  };
  returnTo?: string;
  organizationId?: string; // Auth0 organization id
}

export function useLoginState() {
  const match = useRouteMatch<{ communitySlug: string; organizationSlug: string }>();

  const params = new URLSearchParams(window.location.search);
  const personalInviteToken = params.get('invitation') ?? undefined;
  const communityInviteToken = params.get('communityCode') ?? undefined;
  const organizationInviteToken = params.get('organizationInviteCode') ?? undefined;

  const [loginState, setLoginState] = useLocalStorage<LoginState>('login-state', {
    originUrl: window.Location.toString(),
    personalInviteToken,
    joinCommunity: communityInviteToken
      ? {
          slug: match.params.communitySlug,
          code: communityInviteToken,
        }
      : undefined,
    joinOrganization: organizationInviteToken
      ? {
          slug: match.params.organizationSlug,
          code: organizationInviteToken,
        }
      : undefined,
  });

  const reset = () => setLoginState({});

  return {
    loginState,
    setLoginState,
    reset,
    resetReturnTo: () => setLoginState({ ...loginState, returnTo: undefined }),
  };
}

export function useLoginWithRedirect() {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const { setLoginState } = useLoginState();

  const login = (
    screenHint: 'signup' | 'login',
    opts?: { emailHint?: string; returnTo?: string; organization?: string; joinCommunity?: { slug: string } },
  ) => {
    const params = new URLSearchParams(window.location.search);
    const personalInviteToken = params.get('invitation') ?? undefined;
    const communityInviteToken = params.get('communityCode') ?? undefined;
    const organizationInviteToken = params.get('organizationInviteCode') ?? undefined;

    const legacyCompanyInviteToken = params.get('companyInviteToken') ?? undefined;
    const communityCode = communityInviteToken ?? legacyCompanyInviteToken;

    const emailParam = params.get('email') ?? '';
    const email = emailIsValid(emailParam) ? emailParam : undefined;

    const communitySlug = window.location.pathname.startsWith('/communities')
      ? location.pathname.split('/')[2]
      : undefined;
    const organizationId =
      communitySlug && featureToggle.communityIsConnectedToSeb(communitySlug)
        ? featureToggle.organizationMapping().SEB
        : undefined;

    setLoginState({
      returnTo: opts?.returnTo ?? currentRelativePath(),
      originUrl: window.location.toString(),
      personalInviteToken,
      joinCommunity:
        communityCode || opts?.joinCommunity ? { code: communityCode, slug: opts?.joinCommunity?.slug } : undefined,
      joinOrganization: organizationInviteToken
        ? { slug: opts?.organization, code: organizationInviteToken }
        : undefined,
    });

    loginWithRedirect({
      authorizationParams: {
        screen_hint: screenHint,
        email_hint: opts?.emailHint,
        returnTo: opts?.returnTo ?? currentRelativePath(),
        login_hint: email,
        organization: organizationId,
      },
      appState: {
        returnTo: opts?.returnTo ?? currentRelativePath(),
      },
    });
  };

  return { loginWithRedirect: login, isAuthenticated, isLoading };
}
