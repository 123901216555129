import useSWR from 'hooks/useSWR';
import { useSWRResource } from 'util/resource/useSWRResource';
import { UpdateContext } from 'types/content';
import singleUpdateAPI from './UpdateAPI';

export function useUpdateContext(updateId: UUID) {
  const api = singleUpdateAPI;
  const swrResult = useSWR<UpdateContext>(api.getContext(updateId));

  return useSWRResource(swrResult);
}
