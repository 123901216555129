import { useMediaQuery } from '@mui/material';
import { bluePlanetTheme } from 'ui/theme';

export function useBreakpoints() {
  const isMobile = useMediaQuery(bluePlanetTheme.breakpoints.down('sm'));
  const isTabletDown = useMediaQuery(bluePlanetTheme.breakpoints.down('md'));
  const isLgDown = useMediaQuery(bluePlanetTheme.breakpoints.down('lg'));

  return { isMobile, isTabletDown, isLgDown };
}
