import React, { useRef } from 'react';
import useClickableMentions from '../../ui/elements/form/RichTextEditor/useClickableMentions';
import UserProfileDialog from 'domain/users/UserProfile/UserProfileDialog';
import PublicProfileDialog from 'domain/companies/ProfileDialog/ProfileDialog';
import HtmlContent from '../../ui/elements/HtmlContent';
import { useIframeHeights } from 'hooks/useIframeHeights';

interface Props {
  className?: string;
  style?: object;
  children: string;
  onClick?: () => void;
}

export default function HtmlContentWithMentions({ className, style, children, onClick }: Props) {
  const contentRef = useRef<HTMLParagraphElement>(null);

  const { userProfileIdToShow, setUserProfileIdToShow, companyProfileIdToShow, setCompanyProfileIdToShow } =
    useClickableMentions(contentRef);
  useIframeHeights(contentRef);

  return (
    <>
      <HtmlContent ref={contentRef} embeddingIsAllowed onClick={onClick} style={style} className={className}>
        {children}
      </HtmlContent>

      {userProfileIdToShow && (
        <UserProfileDialog cwUserId={userProfileIdToShow} onClose={() => setUserProfileIdToShow(undefined)} />
      )}
      {companyProfileIdToShow && (
        <PublicProfileDialog companyId={companyProfileIdToShow} onClose={() => setCompanyProfileIdToShow(undefined)} />
      )}
    </>
  );
}
