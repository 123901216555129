import React, { useState } from 'react';

import useNotify from 'hooks/useNotify';
import Button from 'ui/elements/buttons/Button';
import TrashIcon from 'ui/elements/icons/TrashIcon';
import i18n from 'util/i18n';
import DeleteDocumentDialog from '../../Documents/Delete/DeleteDocumentDialog';
import DocumentUploader from '../../Documents/Upload/DocumentUploader';
import { DialogActions, Content } from 'ui/views/dialogs/Dialog';
import { Link } from 'react-router-dom';
import { companyUrls } from 'urls';
import { ICompany, VideoListDTO } from 'types/company';
import useLazyResource from 'util/resource/useLazyResource';
import { companiesApi, companiesAPIUrls } from 'apis/CompanyAPI/companies/companiesApi';
import { useCompanyLastUpdated } from '../LastUpdated';
import { ArrayHelpers, FieldArray, FormikProvider, useFormik } from 'formik';
import FormRow from 'ui/elements/form/FormRow';
import FormikTextField from 'ui/elements/form/formik/FormikTextField';
import AddIcon from 'ui/elements/icons/AddIcon';
import * as yup from 'yup';
import { MATCH_URL_VIMEO, MATCH_URL_YOUTUBE } from 'util/validation';
import IconButton from 'ui/elements/icons/IconButton';
import styled from '@emotion/styled';
import { contentSpacing, screenSizeSm } from 'ui/theme/themeConstants';
import { splitFilenameExtension } from 'util/stringUtils';
import { uploadPitchDocument } from 'domain/companies/Documents/Document/PitchDeck/actions';
import DocumentListItem from 'domain/documents/DocumentListItem';
import EditIcon from 'ui/elements/icons/EditIcon';
import Tooltip from 'ui/elements/Tooltip';
import { PitchFile, PitchFiles } from 'types/company/pitchdeck';
import { post } from 'apis/ApiBase';

interface VideoForm {
  videos: string[];
}

interface Props {
  company: ICompany;
  documents: PitchFiles;
  videos?: VideoListDTO;
  onSaveVideos?: (videos: VideoListDTO) => void;
  onClose: () => void;
  onDocumentUploaded: () => void;
  onDocumentDeleted: (documentId: string) => void;
  shouldAutofocusVideos?: boolean;
}

const UploadSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 50%;
  align-items: baseline;
  margin: ${contentSpacing} 0;
  gap: ${contentSpacing};

  @media (max-width: ${screenSizeSm}) {
    grid-template-columns: 1fr;
  }
`;

const FileList = styled.div`
  margin-top: ${contentSpacing};
  padding: ${contentSpacing} 0;
`;

export default function ManagePitchDocuments({
  company,
  documents,
  videos,
  onSaveVideos,
  onClose,
  onDocumentDeleted,
  onDocumentUploaded,
  shouldAutofocusVideos,
}: Props) {
  const notify = useNotify();
  const [documentToBeDeleted, setDocumentToBeDeleted] = useState<PitchFile | undefined>(undefined);
  const { setLastUpdated } = useCompanyLastUpdated(company.id);

  const pitchDeck = documents.pitchDeck;
  const attachments = documents.attachments;

  const [onDelete, isDeleting] = useLazyResource(
    (documentId: string) => companiesApi.profile.pitchDocuments.delete(company.id, documentId),
    {
      onSuccess: (_, documentId) => {
        onDocumentDeleted(documentId);
        notify('success', 'Document deleted');
      },
      onFailure: () => notify('error', 'Could not delete document'),
    },
  );

  const [saveVideos, isSaving] = useLazyResource(
    (videos: string[]) =>
      companiesApi.videos.post(
        company.id,
        videos.map((value, idx) => ({
          sortOrder: idx,
          url: value,
        })),
      ),
    {
      onSuccess: result => {
        onSaveVideos && onSaveVideos(result);
        setLastUpdated(new Date());
        onClose();
      },
      onFailure: error => notify('error', error || i18n('en').errors.save('video list')),
    },
  );

  const form = useFormik<VideoForm>({
    initialValues: {
      videos:
        videos && videos.values.length > 0 ? videos.values.map(video => video.url) : shouldAutofocusVideos ? [''] : [],
    },
    validateOnChange: false,
    validationSchema: yup.object().shape({
      videos: yup.array().of(
        yup.string().test('valid-video-url', 'Enter a valid youtube/vimeo url', value => {
          const validYoutube = MATCH_URL_YOUTUBE.test(value ?? '');
          const validVimeo = MATCH_URL_VIMEO.test(value ?? '');
          return validYoutube || validVimeo;
        }),
      ),
    }),
    onSubmit: values => {
      const nonEmpty = values.videos.filter(v => {
        return v.length > 0;
      });
      saveVideos(nonEmpty).then(() => notify('success', 'Pitch and presentation saved'));
    },
  });

  const [editPitchDocumentIsAllowed, setEditPitchDocumentIsAllowed] = useState(false);

  return (
    // This is a little awkward as we use the old HOC's instead of hooks, but the outer one is the pitch deck and inner is attachments
    <DocumentUploader
      uploadDocument={uploadPitchDocument(company.id, 'pitch_deck')}
      onSuccess={() => {
        onDocumentUploaded();
        // Once the file is uploaded, we notify the backend that the upload is finished in order to generate a preview
        // When the preview is ready we refresh again to show the preview
        post(companiesAPIUrls.pitchDocuments.uploadedFinished(company.id), {}).then(() => {
          onDocumentUploaded();
        });
      }}
      fileLimit="single"
      hideUploadedDocuments
      align="left"
    >
      {pitchDeckUploader => (
        <DocumentUploader
          uploadDocument={uploadPitchDocument(company.id, 'document')}
          onSuccess={onDocumentUploaded}
          hideUploadedDocuments
          align="left"
        >
          {({ dropzone, uploadedDocuments, documentsBeingUploaded, reset }) => {
            const handleClose = () => {
              onClose && onClose();
              reset();
              pitchDeckUploader.reset();
            };
            return (
              <>
                <Content>
                  Add your complete pitch, company presentation or any other documentation you want to share with
                  visitors to your company profile. Keep in mind that these documents will also be visible in the
                  communities you are a member of.
                  <UploadSection>
                    <div className="text-metadata-medium">Pitch Deck</div>
                    <div>{(!pitchDeck || editPitchDocumentIsAllowed) && pitchDeckUploader.dropzone}</div>
                  </UploadSection>
                  {(pitchDeck || pitchDeckUploader.uploadedDocuments.length > 0) && (
                    <FileList>
                      {pitchDeck && (
                        <DocumentListItem
                          key={pitchDeck.name}
                          document={pitchDeck}
                          onDownload={docId =>
                            companiesApi.profile.pitchDocuments.download(company.id, docId, 'download')
                          }
                        >
                          <>
                            <Tooltip title="Replace pitch deck" disableFocusListener>
                              {/* span is needed for tooltip to work smh */}
                              <span>
                                <IconButton
                                  className="u-quarter-spacing-left"
                                  onClick={() => setEditPitchDocumentIsAllowed(!editPitchDocumentIsAllowed)}
                                  color="dark-grey"
                                >
                                  <EditIcon />
                                </IconButton>
                              </span>
                            </Tooltip>
                            <Tooltip title="Delete pitch deck" disableFocusListener>
                              {/* span is needed for tooltip to work smh */}
                              <span>
                                <IconButton
                                  onClick={() => setDocumentToBeDeleted(pitchDeck)}
                                  color="red"
                                  hoverColor="red"
                                >
                                  <TrashIcon color="red" />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </>
                        </DocumentListItem>
                      )}
                      <div>{pitchDeckUploader.uploadedDocuments}</div>
                    </FileList>
                  )}
                  {pitchDeck && splitFilenameExtension(pitchDeck?.name).extension !== 'pdf' && (
                    <p className="text-weight-medium u-half-spacing-y">
                      Please note: Only PDF files can be viewed in the in-app pitch deck viewer. Other file types, such
                      as {splitFilenameExtension(pitchDeck?.name).extension}, are available for download only.
                    </p>
                  )}
                  <hr className="u-content-spacing-y"></hr>
                  <UploadSection>
                    <div className="text-metadata-medium">Attachments</div>
                    <div>{dropzone}</div>
                  </UploadSection>
                  {(attachments.length > 0 || uploadedDocuments.length > 0) && (
                    <FileList>
                      {attachments.map(doc => (
                        <DocumentListItem
                          key={doc.name}
                          document={doc}
                          onDownload={docId =>
                            companiesApi.profile.pitchDocuments.download(company.id, docId, 'download')
                          }
                        >
                          <Tooltip title="Delete file" disableFocusListener>
                            {/* span is needed for tooltip to work smh */}
                            <span>
                              <IconButton
                                className="u-quarter-spacing-left"
                                onClick={() => setDocumentToBeDeleted(doc)}
                                color="red"
                                hoverColor="red"
                              >
                                <TrashIcon color="red" />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </DocumentListItem>
                      ))}
                      <div>{uploadedDocuments}</div>
                    </FileList>
                  )}
                  {documentToBeDeleted && (
                    <DeleteDocumentDialog
                      isOpen={documentToBeDeleted !== undefined}
                      onClose={() => setDocumentToBeDeleted(undefined)}
                      document={documentToBeDeleted}
                      onDelete={onDelete}
                      isDeleting={isDeleting}
                    />
                  )}
                  <hr className="u-content-spacing-y"></hr>
                  {videos && (
                    <>
                      <UploadSection>
                        <div>
                          <div className="text-metadata-medium u-half-spacing-bottom">
                            Video pitch or presentation video
                          </div>
                          <div>
                            <p className="text-body">
                              Add videos to introduce your company or showcase your product. Upload videos to{' '}
                              <a href="https://vimeo.com" target="_blank" rel="noreferrer" className="text-link-small">
                                Vimeo
                              </a>{' '}
                              or{' '}
                              <a
                                href="https://youtube.com"
                                target="_blank"
                                rel="noreferrer"
                                className="text-link-small"
                              >
                                Youtube
                              </a>{' '}
                              and link to them here. Videos will appear in the order they are listed.
                            </p>
                          </div>
                        </div>
                        <FormikProvider value={form}>
                          <FieldArray
                            name="videos"
                            render={(arrayHelpers: ArrayHelpers) => (
                              <div>
                                {form.values.videos.map((video, index) => (
                                  <FormRow key={`row-${index}`} className="u-flex u-flex-align-center">
                                    <FormikTextField
                                      name={`videos.${index}`}
                                      formikProps={form}
                                      label="Video url"
                                      value={video}
                                      autoFocus={shouldAutofocusVideos}
                                    />
                                    <span>
                                      <IconButton
                                        className="u-quarter-spacing-left"
                                        onClick={() => arrayHelpers.remove(index)}
                                        color="red"
                                        hoverColor="red"
                                      >
                                        <TrashIcon />
                                      </IconButton>
                                    </span>
                                  </FormRow>
                                ))}
                                <Button kind="tertiary" onClick={() => arrayHelpers.push('')}>
                                  <AddIcon fontSize="small" className="u-quarter-spacing-right" /> Add video
                                </Button>
                              </div>
                            )}
                          />
                        </FormikProvider>
                      </UploadSection>
                    </>
                  )}
                  <div className="u-section-spacing-top">
                    Need to share confidential documents with your shareholders & board members?{' '}
                    <Link to={companyUrls.settings.tools(company.slug, 'documents')} className="text-link">
                      Activate the document center.
                    </Link>
                  </div>
                </Content>
                <DialogActions>
                  <Button
                    kind="primary"
                    onClick={videos ? () => form.handleSubmit() : handleClose}
                    disabled={documentsBeingUploaded.length > 0}
                    isLoading={isSaving}
                  >
                    Done
                  </Button>
                  <Button kind="tertiary" onClick={onClose}>
                    Cancel
                  </Button>
                </DialogActions>
              </>
            );
          }}
        </DocumentUploader>
      )}
    </DocumentUploader>
  );
}
