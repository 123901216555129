import React, { CSSProperties } from 'react';
import styled from '@emotion/styled';
import { halfSpacing } from 'ui/theme/themeConstants';

const Frow = styled.div`
  &:not(:first-of-type) {
    margin-top: ${halfSpacing};
  }
`;

export default function FormRow({
  className,
  children,
  ...rest
}: {
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  ref?: React.Ref<HTMLDivElement>;
}) {
  return (
    <Frow className={className} {...rest}>
      {children}
    </Frow>
  );
}
