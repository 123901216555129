import ApiBase from 'apis';
import { companiesAPIUrls } from 'apis/CompanyAPI/companies/companiesApi';
import { MarketPotential, PatchMarketPotential } from 'apis/CompanyAPI/companies/useMarketPotential';
import { useFormik } from 'formik';
import useNotify from 'hooks/useNotify';
import { CompanyId } from 'urls';
import { DEFAULT_CURRENCY } from 'util/currency';
import useLazyResource from 'util/resource/useLazyResource';
import * as Yup from 'yup';

export interface MarketPotentialFormInput {
  geographicalMarketPotential: string[];
  currentMarketFocus: string[];
  estimatedAvailableMarketValue?: number;
  estimatedServiceableMarketValue?: number;
  estimatedMarketCurrency: string;
  description?: string;
}

export const useSaveMarketPotential = (
  companyId: CompanyId,
  { onSuccess }: { onSuccess?: () => void },
  notifyOnSuccess: boolean = true,
) => {
  const notify = useNotify();
  return useLazyResource(
    (payload: MarketPotentialFormInput) => {
      const isAvailableMarketSet =
        !!payload.estimatedAvailableMarketValue && !isNaN(payload.estimatedAvailableMarketValue);
      const isServiceableMarketSet =
        !!payload.estimatedServiceableMarketValue && !isNaN(payload.estimatedServiceableMarketValue);

      return ApiBase().patch<PatchMarketPotential>(companiesAPIUrls.marketPotential.update(companyId), {
        estimatedAvailableMarketValue: isAvailableMarketSet ? payload.estimatedAvailableMarketValue : null,
        estimatedServiceableMarketValue: isServiceableMarketSet ? payload.estimatedServiceableMarketValue : null,
        estimatedAvailableMarketCurrency: isAvailableMarketSet ? payload.estimatedMarketCurrency : null,
        estimatedServiceableMarketCurrency: isServiceableMarketSet ? payload.estimatedMarketCurrency : null,
        currentMarketFocus: payload?.currentMarketFocus?.length === 0 ? null : payload?.currentMarketFocus,
        geographicalMarketPotential:
          payload?.geographicalMarketPotential?.length === 0 ? null : payload?.geographicalMarketPotential,
        description: payload?.description || null,
      });
    },
    {
      onSuccess: () => {
        if (onSuccess) onSuccess();
        if (notifyOnSuccess) notify('success', `Market potential saved`);
      },
      onFailure: message => notify('error', message || `Unable to save, please try again later.`),
    },
  );
};

const marketPotentialValidationSchema = Yup.object().shape(
  {
    description: Yup.string().nullable().notRequired(),
    estimatedAvailableMarketValue: Yup.number()
      .nullable()
      .when('estimatedServiceableMarketValue', ([estimatedServiceableMarketValue], schema) =>
        estimatedServiceableMarketValue != null
          ? schema.moreThan(
              estimatedServiceableMarketValue - 1,
              'Available market must be a higher value than serviceable market',
            )
          : schema,
      ),
    estimatedServiceableMarketValue: Yup.number()
      .nullable()
      .when('estimatedAvailableMarketValue', ([estimatedAvailableMarketValue], schema) =>
        estimatedAvailableMarketValue
          ? schema.lessThan(
              estimatedAvailableMarketValue + 1,
              'Serviceable market must be a lower value than available market',
            )
          : schema,
      ),
    estimatedMarketCurrency: Yup.string().nullable(),
    geographicalMarketPotential: Yup.array().nullable().notRequired(),
    currentMarketFocus: Yup.array().nullable().notRequired(),
  },
  [
    // Need to specify cyclic validation dependencies
    ['estimatedAvailableMarketValue', 'estimatedServiceableMarketValue'],
  ],
);

export const useMarketPotentialForm = (
  onSaveMarketPotential: (formInput: MarketPotentialFormInput) => void,
  marketPotential?: MarketPotential,
) => {
  return useFormik({
    initialValues: {
      description: marketPotential?.description,
      estimatedAvailableMarketValue: marketPotential?.estimatedAvailableMarket?.value,
      estimatedServiceableMarketValue: marketPotential?.estimatedServiceableMarket?.value,
      estimatedMarketCurrency:
        marketPotential?.estimatedAvailableMarket?.currency ??
        marketPotential?.estimatedServiceableMarket?.currency ??
        DEFAULT_CURRENCY,
      geographicalMarketPotential: marketPotential?.geographicalMarketPotential ?? [],
      currentMarketFocus: marketPotential?.currentMarketFocus ?? [],
    },
    onSubmit: values => {
      onSaveMarketPotential(values);
    },
    validationSchema: marketPotentialValidationSchema,
  });
};
