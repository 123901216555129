import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link } from 'react-router-dom';
import { FolderDTO } from 'types/documents';
import { MobileMargin } from 'ui/views/layouts/BaseLayout';

const buildBreadcrumbs = (folders: FolderDTO[], startFolder?: FolderDTO) => {
  const crumbs = [] as FolderDTO[];
  let parent = startFolder;
  let count = 0;
  while (parent) {
    crumbs.push(parent);
    parent = parent.parentId ? folders.find(folder => folder.id === parent?.parentId) : undefined;

    count = count + 1;
    if (count > folders.length) return crumbs;
  }
  return crumbs.reverse();
};

interface Props {
  folders: FolderDTO[];
  currentFolder?: FolderDTO;
  folderNavigation: string | ((folderId: string | undefined) => void);
}

export default function FolderBreadcrumbs(props: Props) {
  const { folders, currentFolder, folderNavigation: documentsPath } = props;
  const breadcrumbs = buildBreadcrumbs(folders, currentFolder);

  return (
    <Breadcrumbs aria-label="Breadcrumb">
      <MobileMargin>
        {typeof documentsPath === 'string' ? (
          <Link key="crumb-root" className="text-link-small" to={documentsPath}>
            Home
          </Link>
        ) : (
          <button className="text-link-small" onClick={() => documentsPath(undefined)}>
            Home
          </button>
        )}
      </MobileMargin>

      {breadcrumbs.map(({ id, name }) =>
        currentFolder && currentFolder.id === id ? (
          <Typography key={`crumb-${id}`} color="textPrimary">
            {name}
          </Typography>
        ) : typeof documentsPath === 'string' ? (
          <Link key={`crumb-${id}`} className="text-link-small" to={`${props.folderNavigation}/folders/${id}`}>
            {name}
          </Link>
        ) : (
          <button key={`crumb-${id}`} className="text-link-small" onClick={() => documentsPath(id)}>
            {name}
          </button>
        ),
      )}
    </Breadcrumbs>
  );
}
