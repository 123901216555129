import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

export default function useScrollToHashElement() {
  const location = useLocation();
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const scrollToElement = () => {
        const element = document.getElementById(location.hash.replace('#', ''));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

      if (firstLoad) {
        // Delay scroll only on initial load to ensure that the page is fully rendered
        // this only happens if page is loaded with a hash
        setTimeout(() => {
          scrollToElement();
          setFirstLoad(false);
        }, 300);
      } else {
        // Instantly scroll on route changes
        scrollToElement();
      }
    }
  }, [location]);

  useEffect(() => {
    setTimeout(() => {
      // remove the delay after a bit so that the scroll is not delayed on first scroll
      setFirstLoad(false);
    }, 500);
  }, []);

  return null;
}
