import SvgIcon, { IconProps } from './SvgIcon';
import React from 'react';

export default function CopyLinkIcon(props: IconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M14.6 13.2a2.7 2.7 0 0 1-3.8-3.8L13 7.1c1-1 2.6-1 3.7-.2m-.4-3.1a2.7 2.7 0 1 1 3.8 3.8L18 9.9c-1 1-2.6 1-3.7.2" />
      <path d="M21 13c0 3.8 0 5.7-1.2 6.8-1.1 1.2-3 1.2-6.8 1.2h-2c-3.8 0-5.7 0-6.8-1.2C3 18.7 3 16.8 3 13v-2c0-3.8 0-5.7 1.2-6.8C5.3 3 7.2 3 11 3" />
    </SvgIcon>
  );
}
