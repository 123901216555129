import { TrustLevel } from 'types/company/access';

export const getDescription = (trustLevel: TrustLevel | TrustLevel[], context: 'company' | 'community') => {
  const trustLevels = Array.isArray(trustLevel) ? trustLevel : [trustLevel];
  if (trustLevels.includes('public')) return 'anyone';
  if (trustLevels.includes('semi_trusted'))
    return context === 'company' ? 'shareholders, board members and followers' : 'all members';
  if (trustLevels.includes('trusted')) return context === 'company' ? 'shareholders and board members' : 'investors';
  if (trustLevels.includes('board')) return 'board members';
  if (trustLevels.includes('company_admin_in_community')) return 'founders';
  else return 'Nobody';
};

export function getDerivedTrustLevels(trustLevels: TrustLevel[]): TrustLevel[] {
  if (trustLevels.includes('semi_trusted')) return ['trusted', 'board'];
  else if (trustLevels.includes('trusted')) return ['board'];
  return [];
}
