import React, { useEffect } from 'react';
import Card from 'ui/views/cards/Card';
import styles from './styles.scss';
import cx from 'classnames';
import CompanyLogo from 'ui/elements/CompanyLogo';
import ChevronRightIcon from 'ui/elements/icons/ChevronRightIcon';
import { Link } from 'react-router-dom';
import { communityUrls, companyUrls } from 'urls';
import { useUpdateContext } from 'apis/ContentAPI/useUpdateContext';
import Resource from 'util/resource/Resource';
import { UpdateContext } from 'types/content';

interface Props {
  updateId: UUID;
  className?: string;
  onContentLoaded?: () => void;
}

export default function ShareUpdateCard({ updateId, className, onContentLoaded }: Props) {
  const { resource: updateContextResource } = useUpdateContext(updateId);

  const getUrl = (context: UpdateContext) =>
    context.context.type === 'company'
      ? companyUrls.update.view(context.context.slug, updateId)
      : communityUrls.update.view(context.context.slug, updateId);

  useEffect(() => {
    if (updateContextResource.state === 'fetched' && onContentLoaded) onContentLoaded();
  }, [updateContextResource.state]);

  return (
    <Resource resource={updateContextResource}>
      {updateContext => (
        <Card className={cx(styles.shareCard, styles.updateCard, className)} elevation={2} padding="none">
          <Link to={getUrl(updateContext)} target="_blank" rel="noopener noreferrer">
            <div className="u-flex u-flex-space-between u-flex-align-center u-half-padding-x u-half-padding-y">
              <div className="u-flex u-flex-align-center">
                <CompanyLogo company={updateContext.context} size={40} />
                <div className="u-flex u-flex--column u-half-spacing-left">
                  <span className="text-weight-bold text-small">{updateContext.context.name}</span>
                  <span className="text-metadata">By {updateContext.creatorName}</span>
                </div>
              </div>
              <ChevronRightIcon color="dark-grey" fontSize="small" strokeWidth={2} />
            </div>
          </Link>
        </Card>
      )}
    </Resource>
  );
}
